import { Box, Flex, List, ListItem } from "@chakra-ui/react";

interface AccordionItemProps {
	title: string;
	value: string | number | boolean | React.ReactElement;
}

const CustomAccordionItem = ({ title, value }: AccordionItemProps) => {
	return (
		<Flex mb="0.5rem">
			<Box w="40%">
				<List>
					<ListItem
						color="brand.gray100"
						fontSize="1rem"
						fontWeight="400"
						mb="1rem"
					>
						{title}
					</ListItem>
				</List>
			</Box>

			<Box>
				<List>
					<ListItem
						color="brand.blue200"
						fontSize="1rem"
						fontWeight="400"
						mb="1rem"
					>
						{value}
					</ListItem>
				</List>
			</Box>
		</Flex>
	);
};

export default CustomAccordionItem;
