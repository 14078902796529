import { useEffect } from "react";
import { Box, Stack, Text, useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import { AuthHeaderLayout } from "@/layouts/auth/AuthHeaderLayout";
import { IFormInput } from "@/types/interfaces";

import { AuthCard } from "@/components/card/AuthCard";
import { AuthButton } from "@/components/button/AuthButton";
import { useLoginMutation } from "@/services/requests/auth";
import { PasswordInput } from "@/components/input/PasswordInput";

import { LoginFormInputs } from "@/constant/formInputs";
import InputGroup from "@/components/input/InputGroup";
import { LoginValues } from "@/types/formInputTypes";

const SignIn = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginValues>();

	const [loginUser, { isSuccess, isLoading, data, error }] = useLoginMutation();

	const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
		await loginUser(data);
	};

	// check if user as previously logged in
	useEffect(() => {
		const token = localStorage.getItem("guliva-token");
		if (token) navigate("/dashboard");
	}, [isSuccess]);

	// check if user is logged in
	useEffect(() => {
		if (isSuccess) {
			localStorage.setItem("guliva-token", data.data.token);
			navigate("/dashboard");
		}
	}, [isSuccess]);

	// check if there is an error
	useEffect(() => {
		if (error) {
			toast({
				title: "Invalid Username or Password",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [error]);

	return (
		<Box>
			<Stack spacing="0" bg="brand.white300">
				<AuthHeaderLayout />

				<AuthCard title="Sign in to Guliva Dashboard">
					<Box as="form" onSubmit={handleSubmit(onSubmit)}>
						{LoginFormInputs.map(
							({ label, type, placeholder, name, rules }, idx) => {
								if (type === "password") {
									return (
										<PasswordInput
											key={idx}
											type={type}
											label={label}
											placeholder={placeholder}
											name={name}
											id={name}
											register={register}
											rules={rules}
											errors={errors}
										/>
									);
								} else {
									return (
										<InputGroup
											key={idx}
											type={type}
											label={label}
											placeholder={placeholder}
											name={name}
											id={name}
											register={register}
											rules={rules}
											errors={errors}
										/>
									);
								}
							}
						)}

						<Text
							mt="0.7rem"
							color="brand.red100"
							fontWeight="400"
							fontSize=".85rem"
							float="right"
						>
							<Link to="/auth/forgot-password">Forgot password?</Link>
						</Text>

						<AuthButton text="LOG IN" loading={isLoading} />
					</Box>
				</AuthCard>
			</Stack>
		</Box>
	);
};

export default SignIn;
