import {
	Box,
	Center,
	Icon,
	Menu,
	MenuButton,
	MenuList,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";

export interface IMenuDropdownProps {
	children: React.ReactNode;
}

const MenuDropdown = ({ children }: IMenuDropdownProps) => {
	return (
		<Box>
			<Menu>
				<MenuButton as={Center} cursor="pointer">
					<Icon mt=".3rem" as={FiChevronDown} />
				</MenuButton>
				<MenuList mt="1rem">{children}</MenuList>
			</Menu>
		</Box>
	);
};

export { MenuDropdown };
