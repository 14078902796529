import { SearchInput } from "@/components/input/SearchInput";
import { useAppSelector } from "@/hooks/reduxHook";
import { Box } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import ChatList from "../chatbox/ChatList";

const UnAssigned = () => {
	const { unassignedChats } = useAppSelector((state) => state.chat);

	const [filteredInput, setFilteredInput] = useState("");

	const filteredData = useMemo(
		() =>
			unassignedChats.filter(
				(chat) =>
					chat?.user?.firstName?.includes(filteredInput) ||
					chat?.user?.lastName?.includes(filteredInput) ||
					chat?.user?.email?.includes(filteredInput) ||
					chat?.adminMessage?.includes(filteredInput) ||
					chat?.message?.includes(filteredInput)
			),
		[filteredInput, unassignedChats]
	);
	return (
		<Box h="100%">
			<SearchInput
				value={filteredInput}
				onChange={(e) => setFilteredInput(e.currentTarget.value)}
				noPad
				w="100%"
				maxW="360px"
				placeholder="Search..."
			/>
			<Box mt="1rem" h="calc(100% - 4rem)" overflowY="auto">
				<ChatList data={filteredData} />
			</Box>
		</Box>
	);
};

export default UnAssigned;
