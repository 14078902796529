import { GridLayout } from "@/layouts/home/GridLayout";
import { Box, Flex, Text } from "@chakra-ui/react";
import ControlImg from "@/assets/images/control-img.webp";
import OdometerImg from "@/assets/images/odometer.webp";
import VehicleImg from "@/assets/images/vehicle-img.webp";
import InsureImg from "@/assets/images/insure-img.webp";

const Payless = () => {
	return (
		<Box
			mt="5rem"
			maxW="1200px"
			px={["1rem", "2rem", "3rem"]}
			mx="auto"
			id="features"
		>
			<Flex
				flexDir={["column", "column", "column", "row", "row"]}
				justify="space-between"
			>
				<Box>
					<Box>
						<Text color="brand.dark200" fontSize="2.13rem" fontWeight="700">
							Pay less when you ride less.
						</Text>
						<Text
							color="brand.gray100"
							fontWeight="400"
							fontSize=".95rem"
							maxW="420px"
							p=".5rem 0 2rem"
						>
							We ensure you buy insurance only when you need it. Snap a picture
							of your odometer every month to pay accordingly.
						</Text>
					</Box>

					<Box>
						<GridLayout
							title="You’re always in control"
							image={ControlImg}
							bg="brand.blue500"
							ml={["3rem", "3.9rem"]}
						/>
					</Box>
					<Box mt="3.5rem">
						<GridLayout
							title="Odometer insurance"
							image={OdometerImg}
							bg="brand.red400"
							ml="2.5rem"
						/>
					</Box>
				</Box>

				<Box mt={["4rem", "0"]}>
					<Box>
						<GridLayout
							title="Manage your vehicles"
							image={VehicleImg}
							bg="brand.red400"
							// ml="2.5rem"
						/>
					</Box>

					<Box mt="3.5rem">
						<GridLayout
							title="Insure yourself as you move"
							image={InsureImg}
							bg="brand.blue500"
							// ml="3.9rem"
						/>
					</Box>
				</Box>
			</Flex>
		</Box>
	);
};

export { Payless };
