/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	HStack,
	MenuItem,
	SimpleGrid,
	Text,
	useToast,
} from "@chakra-ui/react";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PermissionButton } from "@/components/button/PermissionButton";
import { DashboardButton } from "@/components/button/DashboardButton";
import { MenuDropdown } from "@/components/dropdown/MenuDropdown";
import { ActionModal } from "@/components/modal/Modal";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import {
	useDeactivateMemberMutation,
	useDeleteMemberMutation,
	useGetMemberQuery,
} from "@/services/requests/dashboard";
import CustomAccordionItem from "@/components/profile/AccordionItem";
import PageLoader from "@/components/loader/PageLoader";
import useUserPermission from "@/hooks/useUserPermission";

const MemberProfile = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const { profile } = useParams();
	const [modal, setModal] = useState(false);
	const [checkText, setCheckText] = useState(false);

	const permissionsAccess = useUserPermission();
	const { isLoading, data } = useGetMemberQuery(profile || "0");

	const [
		deactivateUser,
		{
			isSuccess,
			isLoading: deactivateLoading,
			data: deactivateData,
			isError,
			error: deactivateError,
		},
	] = useDeactivateMemberMutation();

	const [
		deleteUser,
		{
			isSuccess: deleteSuccess,
			isLoading: deleteLoading,
			data: deleteData,
			isError: deleteError,
			error: deleteErrorMessage,
		},
	] = useDeleteMemberMutation();

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setHeaderText(data ? data?.firstName + " " + data?.lastName : ""));
	}, [data]);

	const handleModal = () => {
		setModal(true);
	};

	async function deleteMember() {
		await deleteUser({ id: profile || "" });
	}

	async function deactivateMember() {
		await deactivateUser({ id: profile || "", active: false });
	}

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: deactivateData.message || "Member Deactivated Successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
		}

		if (deleteSuccess) {
			toast({
				title: deleteData.message || "Member Deleted Successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			navigate("/dashboard/team/members");
		}

		setModal(false);
	}, [isSuccess, deleteSuccess]);

	useEffect(() => {
		if (isError) {
			toast({
				// @ts-ignore
				title: deactivateError?.data?.message || "Member not Found.",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}

		if (deleteError) {
			toast({
				//@ts-ignore
				title: deleteErrorMessage?.data?.message || "Member not Found.",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	}, [isError, deleteError]);

	return (
		<Box>
			{isLoading ? (
				<PageLoader py="12rem" />
			) : (
				<Box>
					<Flex align="center" justify="space-between">
						<Box>
							<BreadcrumbLayout
								BreadcrumbLinkOne="Home"
								BreadcrumbLinkTwo="Members"
								BreadcrumbLinkThree={data?.firstName + " " + data?.lastName}
							/>
						</Box>
						<Flex>
							<Flex>
								<HStack spacing=".3rem" color="brand.red100" mr="2rem">
									<Text>More Actions</Text>

									<MenuDropdown>
										<Link
											to={`/dashboard/team/members/${profile}/reset-password`}
										>
											<MenuItem color="brand.blue200">Reset Password</MenuItem>
										</Link>
										{permissionsAccess.deactivateMember && (
											<MenuItem
												onClick={() => {
													setCheckText(false), handleModal();
												}}
												color="brand.blue200"
											>
												Deactivate Member
											</MenuItem>
										)}

										{permissionsAccess.deleteMember && (
											<MenuItem
												onClick={() => {
													setCheckText(true), handleModal();
												}}
												color="brand.blue200"
											>
												Delete Member
											</MenuItem>
										)}
									</MenuDropdown>
								</HStack>

								<DashboardButton
									onClick={() =>
										navigate(`/dashboard/team/members/${profile}/edit`)
									}
									text="EDIT MEMBER"
								/>
							</Flex>
						</Flex>
					</Flex>

					<Flex
						justify="space-between"
						border="1px solid"
						borderColor="brand.gray500"
						bg="brand.white100"
						borderRadius="5px"
						mt="1rem"
						px="1rem"
					>
						<Box w="55%">
							<Accordion defaultIndex={[0]} allowToggle allowMultiple>
								<AccordionItem border="none">
									<h2>
										<AccordionButton
											borderBottom="1px solid"
											borderColor="brand.gray500"
											m="1.5rem 0 0.5rem"
											px="0"
											_hover={{
												bg: "none",
											}}
											_focus={{
												boxShadow: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												color="brand.blue200"
												fontSize="1.2rem"
												fontWeight="400"
											>
												Personal Information
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4} px="0">
										<CustomAccordionItem
											title="Title"
											value={
												data?.gender === "Male"
													? "Mr"
													: data?.maritalStatus
													? "Mrs"
													: "Miss"
											}
										/>
										<CustomAccordionItem
											title="First Name"
											value={data?.firstName || ""}
										/>
										<CustomAccordionItem
											title="Last Name"
											value={data?.lastName || ""}
										/>
										<CustomAccordionItem
											title="Date of Birth"
											value={moment(data?.dob).format("Do MMM YYYY") || ""}
										/>
										<CustomAccordionItem
											title="Gender"
											value={data?.gender || ""}
										/>
										<CustomAccordionItem
											title="Marital Status"
											value={data?.maritalStatus || ""}
										/>
										<CustomAccordionItem
											title="Email Address"
											value={data?.email || ""}
										/>
										<CustomAccordionItem
											title="Country"
											value={data?.country || ""}
										/>
										<CustomAccordionItem
											title="City"
											value={data?.city || ""}
										/>
										<CustomAccordionItem
											title="Post Code"
											value={data?.postCode || ""}
										/>
										<CustomAccordionItem
											title="Address"
											value={data?.address || ""}
										/>
										<CustomAccordionItem
											title="Phone Number"
											value={data?.phoneNo || ""}
										/>
										<CustomAccordionItem
											title="Date Added"
											value={
												moment(data?.createdAt).format("Do MMM YYYY") || ""
											}
										/>
									</AccordionPanel>
								</AccordionItem>

								<AccordionItem border="none">
									<h2>
										<AccordionButton
											borderBottom="1px solid"
											borderColor="brand.gray500"
											p="1.5rem 0 .5rem"
											_hover={{
												bg: "none",
											}}
											_focus={{
												boxShadow: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												color="brand.blue200"
												fontSize="1.2rem"
												fontWeight="400"
											>
												Job Information
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4}>
										<CustomAccordionItem
											title="Job Title"
											value={data?.jobTitle || ""}
										/>
										<CustomAccordionItem
											title="Job Description"
											value={data?.jobDescription || ""}
										/>
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</Box>

						<Box
							w="40%"
							bg="brand.white300"
							mt="1.5rem"
							p="1rem"
							border="1px solid"
							borderColor="brand.gray500"
							borderRadius="5px"
							h="100%"
						>
							<CustomAccordionItem
								title="Role"
								value={data?.role?.role || ""}
							/>
							<CustomAccordionItem
								title="Member Status"
								value={data?.active ? "Active" : "Not Active"}
							/>

							<Text color="brand.gray100" fontSize="1rem" fontWeight="400">
								Permissions
							</Text>
							<SimpleGrid columns={3} spacing=".9rem" mt="1rem">
								{data?.permissions?.map((permission) => (
									<PermissionButton
										key={permission.id}
										text={permission.name}
									/>
								))}
							</SimpleGrid>
						</Box>
					</Flex>

					{modal ? (
						<ActionModal
							title={checkText ? "Delete Member" : "Deactivate Member"}
							confirmText={
								checkText
									? "Are you sure you want to delete member"
									: "Are you sure you want to deactivate member"
							}
							user={`${data?.firstName} ${data?.lastName}`}
							setModal={setModal}
							onClick={() => (checkText ? deleteMember() : deactivateMember())}
							loading={deactivateLoading || deleteLoading}
						/>
					) : null}
				</Box>
			)}
		</Box>
	);
};

export default MemberProfile;
