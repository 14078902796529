import { Center, Flex, Spinner } from "@chakra-ui/react";
import React from "react";

interface PageLoaderProps {
	py?: string;
}

const PageLoader = ({ py }: PageLoaderProps) => {
	return (
		<Flex bg="brand.white100">
			<Center py={py ? py : "4rem"} w="100%">
				<Spinner size="xl" />
			</Center>
		</Flex>
	);
};

export default PageLoader;
