import { Fragment, useEffect, useMemo, useState } from "react";
import { Box, SimpleGrid, Skeleton, Text } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { DashboardCard } from "@/components/card/DashboardCard";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { TableComponent } from "@/layouts/table/TableLayout";
import { SearchInput } from "@/components/input/SearchInput";
import { Link, Outlet } from "react-router-dom";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useGetAllClaimsQuery } from "@/services/requests/dashboard";
import PageLoader from "@/components/loader/PageLoader";
import Status from "@/components/tag/Status";
import ClaimIcon from "@/assets/icons/guliva_claim.webp";
import CheckmarkIcon from "@/assets/icons/guliva_checkmark.webp";
import TimeIcon from "@/assets/icons/guliva_time.webp";
import fileDownload from "@/utils/downloadFile";
import useUserPermission from "@/hooks/useUserPermission";

const ClaimsPage = () => {
	const { data, isLoading } = useGetAllClaimsQuery();

	const dispatch = useDispatch();
	const permissionsAccess = useUserPermission();

	const [searchTerm, setSearchTerm] = useState<string>("");

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const downloadReport = () => {
		fileDownload("/claim/download-report", "claims.csv");
	};

	const filteredData = useMemo(() => {
		if (data) {
			const sortedData = data?.data?.filter(
				(item: any) =>
					item.user.firstName
						.toLowerCase()
						.includes(searchTerm.toLowerCase()) ||
					item.user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.typeOfIncident.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, data]);

	useEffect(() => {
		dispatch(setHeaderText("Claims"));
	}, []);

	const columns = [
		{
			Header: "Unique ID",
			accessor: "uniqueId",
		},
		{
			Header: "Customer",
			accessor: "customer",
		},
		{
			Header: "Type",
			accessor: "typeOfIncident",
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: ({ value }: { value: string }) => {
				return (
					<Box>
						<Status
							text={
								value === "PENDING"
									? "Pending"
									: value === "APPROVED"
									? "Approved"
									: "Declined"
							}
						/>
					</Box>
				);
			},
		},
		{
			Header: "",
			accessor: "action",
			Cell: ({ value }: { value: string }) => {
				return (
					<Fragment>
						{permissionsAccess?.markClaim && (
							<Box>
								<Link to={`/dashboard/claims/${value}`}>
									<Text color="brand.red100" fontWeight="500">
										View details
									</Text>
								</Link>
							</Box>
						)}
					</Fragment>
				);
			},
		},
	];

	const tableData = useMemo(
		() =>
			filteredData?.map((item) => ({
				uniqueId: item.id,
				customer: item.user.firstName + " " + item.user.lastName,
				typeOfIncident: item.typeOfIncident,
				status: item.status,
				action: item.id,
			})),
		[filteredData]
	);

	return (
		<Box>
			<BreadcrumbLayout BreadcrumbLinkOne="Home" BreadcrumbLinkTwo="Claims" />

			{isLoading ? (
				<SimpleGrid
					margin="1rem 0 0 0"
					columns={3}
					spacingX="2rem"
					spacingY="1rem"
				>
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
				</SimpleGrid>
			) : (
				<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
					<DashboardCard
						title="TOTAL NO. OF CLAIMS"
						icon={ClaimIcon}
						value={data?.totalClaims?.toString() || "0"}
					/>
					<DashboardCard
						title="APPROVED"
						icon={CheckmarkIcon}
						value={data?.approvedClaims?.toString() || "0"}
					/>
					<DashboardCard
						title="PENDING"
						icon={TimeIcon}
						value={data?.pendingClaims?.toString() || "0"}
					/>
				</SimpleGrid>
			)}

			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onClick={downloadReport}
					onChange={handleChange}
					value={searchTerm}
					text="DOWNLOAD REPORT"
					placeholder="Search..."
					w="48%"
				/>

				{isLoading ? (
					<PageLoader />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>

			<Outlet />
		</Box>
	);
};

export default ClaimsPage;
