import { Box, Stack } from "@chakra-ui/react";

import { AuthHeaderLayout } from "@/layouts/auth/AuthHeaderLayout";
import { AuthCard } from "@/components/card/AuthCard";
import { AuthButton } from "@/components/button/AuthButton";
import { useNavigate } from "react-router-dom";

const ResetPasswordEmail = () => {
	const navigate = useNavigate();

	// redirect to login page
	function handleRedirect() {
		navigate("/auth/sign-in");
	}
	return (
		<Box>
			<Stack spacing="0" bg="brand.white300">
				<AuthHeaderLayout />

				<AuthCard
					title="Your password has been reset"
					description="Good job, you have a new password. Click on the button below to log
					in to your account."
					btnText="LOG IN"
				>
					<Box as="form">
						<AuthButton
							onClick={handleRedirect}
							text="LOG IN"
							loading={false}
						/>
					</Box>
				</AuthCard>
			</Stack>
		</Box>
	);
};

export default ResetPasswordEmail;
