import { Button } from "@chakra-ui/react";
import { IAuthButtonProps } from "@/types/interfaces";

const ComingSoonButton = ({
	text,
	loading = false,
	onClick,
}: IAuthButtonProps) => {
	return (
		<Button
			p="1.5rem 1.5rem"
			w={["100%", "100%", "100%", "auto", "auto"]}
			color="#fff"
			bg="brand.blue100"
			borderRadius="5px"
			type="submit"
			cursor="pointer"
			fontSize="0.6rem"
			fontWeight="500"
			isLoading={loading}
			onClick={onClick}
			_hover={{
				bg: "brand.blue200",
			}}
			_focus={{
				borderColor: "none",
				boxShadow: "none",
			}}
		>
			{text}
		</Button>
	);
};

export { ComingSoonButton };
