export const FaqDetails = [
	{
		title: "How does billing work?",
		description:
			"We charge a flat rate for each project. You can find more details in our pricing page.",
	},
	{
		title: "What is pay-per-trip car insurance?",
		description:
			"We charge a flat rate for each project. You can find more details in our pricing page.",
	},
	{
		title: "Can I insure myself during my journeys?",
		description:
			"We charge a flat rate for each project. You can find more details in our pricing page.",
	},
	{
		title: "Does Guliva offer monthly car insurance?",
		description:
			"We charge a flat rate for each project. You can find more details in our pricing page.",
	},
	{
		title: "How can Guliva offer cheaper auto insurance?",
		description:
			"We charge a flat rate for each project. You can find more details in our pricing page.",
	},
	{
		title: "Do you offer full coverage?",
		description:
			"We charge a flat rate for each project. You can find more details in our pricing page.",
	},
];
