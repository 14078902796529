import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
	fonts: {
		body: "'DM Sans', sans-serif",
		love: "'Love Ya Like A Sister', cursive",
	},
	colors: {
		brand: {
			white100: "#ffffff",
			white200: "#F8F9FC",
			white300: "#fafafa",
			gray100: "#6A727F",
			gray200: "#FCFCFC",
			gray300: "#393A43",
			gray400: "#797979",
			gray500: "#E4E4E4",
			gray600: "#D3D3D3",
			green100: "#F6FFED",
			green500: "#389E0D",
			blue100: "#053972;",
			blue200: "#0D2343",
			blue300: "#A1ADBA",
			blue400: "#E9F4FF",
			blue500: "#E6F2FF",
			blue600: "#A1A6AD",
			dark100: "#030335",
			dark200: "#0F173E",
			dark300: "#2D2D2D",
			dark400: "#263238",
			red100: "#E96C34",
			red200: "#E22D14",
			red300: "#EC0008",
			red400: "#FFF5F1",
			red500: "#FFF3ED",
			yellow100: "#FFF6E9",
			yellow500: "#F79E1B",
		},
	},
});

export default theme;
