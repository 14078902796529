import { Box, Flex, Text } from "@chakra-ui/react";

interface StatusProps {
	message: string;
	date: string;
	side: "left" | "right";
}

const Bubble = ({ side = "left", message, date }: StatusProps) => {
	return (
		<Flex mb="1rem" justify={side === "left" ? "flex-start" : "flex-end"}>
			<Box
				bg={side === "left" ? "brand.white100" : "brand.blue100"}
				w="100%"
				maxW="271px"
				px="1rem"
				pt="0.75rem"
				pb="0.5rem"
				boxShadow="0px 2px 12px rgba(5, 57, 114, 0.03)"
				borderRadius={side === "left" ? "10px 10px 10px 0" : "10px 10px 0 10px"}
			>
				<Text
					fontSize="sm"
					color={side === "left" ? "brand.dark200" : "brand.white100"}
				>
					{message}
				</Text>
				<Flex justify="flex-end">
					<Text color="brand.gray500" fontSize="xs">
						{date}
					</Text>
				</Flex>
			</Box>
		</Flex>
	);
};

export default Bubble;
