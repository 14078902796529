import { Box, Flex, HStack, LinkBox, Text } from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import { IMenuLinkProps } from "@/types/interfaces";
import { Dropdown } from "@/layouts/dashboard/Dropdown";

const MenuLink = ({
	text,
	iconImg,
	iconImgTwo,
	path = "#",
	accessible,
	subRoutes,
	isDropdown,
}: // openTab = "#",
// handleTabChange,
IMenuLinkProps) => {
	const location = useLocation();
	const activeTab = location.pathname === path;
	return (
		<LinkBox cursor={accessible ? "pointer" : "not-allowed"}>
			{!isDropdown ? (
				<NavLink to={accessible ? path : "#"}>
					<HStack
						// as={Link}
						p={iconImg ? "1rem 2rem" : "1rem 4.7rem"}
						borderLeft={activeTab ? "4px solid #fff" : "none"}
						// borderLeftColor={"brand.white100"}
						bgColor={activeTab ? "brand.blue100" : "none"}
						color={activeTab ? "brand.white100" : "brand.blue300"}
						opacity={accessible ? 1 : 0.4}
						pointerEvents={accessible ? "auto" : "none"}
						spacing={5}
						fontWeight="400"
						fontSize="1rem"
					>
						{iconImg ? (
							<Box
								sx={{
									"#svgIcon": {
										fill: activeTab ? "brand.white100" : "brand.blue300",
									},
								}}
								dangerouslySetInnerHTML={{ __html: iconImg }}
							/>
						) : null}
						<Flex justify="space-between" align="center" w="100%">
							<Text>{text}</Text>

							{iconImg ? <Box as={iconImgTwo} /> : null}
						</Flex>
					</HStack>
				</NavLink>
			) : (
				<Dropdown
					accessible={accessible}
					path={path}
					subRoutes={subRoutes}
					text={text || ""}
					iconImg={iconImg}
					iconImgTwo={iconImgTwo}
				/>
			)}
		</LinkBox>
	);
};

export { MenuLink };
