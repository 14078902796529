import ForgotPassword from "@/pages/auth/ForgotPassword";
import ForgotPasswordLink from "@/pages/auth/ForgotPasswordLink";
import ResetPassword from "@/pages/auth/ResetPassword";
import ResetPasswordEmail from "@/pages/auth/ResetPasswordEmail";
import SignIn from "@/pages/auth/SignIn";
import ComingSoon from "@/pages/ComingSoon";
import Dashboard from "@/pages/dashboard";
import HomePage from "@/pages/Home";
import PrivacyPolicy from "@/pages/PrivacyPolicy";
import NotFound from "@/pages/NotFound";
import TermsAndConditions from "@/pages/TermsAndConditions";

export const AuthNavigation = [
	{
		path: "/dashboard/*",
		component: Dashboard,
		protected: true,
	},
	{
		path: "/auth/reset-password-email",
		component: ResetPasswordEmail,
		protected: false,
	},
	{
		path: "/auth/reset-password",
		component: ResetPassword,
		protected: false,
	},
	{
		path: "/auth/forget-password-link",
		component: ForgotPasswordLink,
		protected: false,
	},
	{
		path: "/auth/forgot-password",
		component: ForgotPassword,
		protected: false,
	},
	{
		path: "/auth/sign-in",
		component: SignIn,
		protected: false,
	},
	{
		path: "/",
		component: HomePage,
		protected: false,
	},
	{
		path: "/coming-soon",
		component: ComingSoon,
		protected: false,
	},
	{
		path: "/PrivacyPolicy",
		component: PrivacyPolicy,
		protected: false,
	},
	{
		path: "/TermsAndConditions",
		component: TermsAndConditions,
		protected: false,
	},
	{
		path: "*",
		component: NotFound,
		protected: false,
	},
];
