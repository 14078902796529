import {
	Avatar,
	Button,
	Flex,
	HStack,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Select,
	Text,
	VStack,
} from "@chakra-ui/react";
import { IChats } from "@/types/interfaces";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setSelectedChat } from "@/redux/features/chat/chatSlice";
import { useAppSelector } from "@/hooks/reduxHook";

import {
	useAssignToMutation,
	useGetAllMembersQuery,
} from "@/services/requests/dashboard";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface ChatItemProps {
	data: IChats;
}

const ChatItem = ({ data }: ChatItemProps) => {
	const dispatch = useDispatch();
	const { selectedChat } = useAppSelector((state) => state.chat);
	const { data: members } = useGetAllMembersQuery();

	const [asignToChat] = useAssignToMutation();

	function handleSelectChat(selectedChat: IChats) {
		dispatch(setSelectedChat(selectedChat));
	}

	async function handleAsignUser(e: any) {
		await asignToChat({
			userId: selectedChat?.userId || "",
			assignTo: e.target.value,
		});
	}

	function handleUnassign(id: number) {
		asignToChat({
			userId: selectedChat?.userId || "",
			assignTo: id.toString(),
		});
	}

	return (
		<HStack
			align="flex-start"
			gap={2}
			borderBottom="1px solid #E4E4E4"
			bg={selectedChat?.id === data?.id ? "brand.white300" : "brand.white100"}
			_hover={{ bg: "brand.white300" }}
			p="1rem 0.75rem"
			w="100%"
			cursor="pointer"
			onClick={() => handleSelectChat(data)}
		>
			<Avatar
				src={data?.image || ""}
				name={data?.user?.firstName + " " + data?.user?.lastName}
				size="sm"
			/>
			<VStack w="100%" spacing="0" align="self-start">
				<Text fontWeight={500} color="brand.blue100">
					{data?.user?.firstName + " " + data?.user?.lastName}
				</Text>
				<Text fontSize="xs" color="brand.gray100">
					{data?.message}
				</Text>

				<HStack w="100%" pt={3} justify="space-between" align="center">
					<Flex gap={1}>
						<Text fontSize="xs" color="brand.gray100">
							Assigned to:
						</Text>
						{data?.assignedTo ? (
							<Flex alignItems="center" gap="0.5rem">
								<Link to="/">
									<Text fontSize="xs" color="brand.red100">
										{data?.assignedTo?.firstName} {data?.assignedTo?.lastName}
									</Text>
								</Link>
								<Menu>
									<MenuButton
										size="xs"
										as={Button}
										rightIcon={<ChevronDownIcon />}
									>
										reasign
									</MenuButton>
									<MenuList>
										{members?.map((member) => (
											<MenuItem
												key={member.id}
												onClick={() => handleUnassign(member?.id)}
											>
												{`${member.firstName} ${member.lastName}`}
											</MenuItem>
										))}
									</MenuList>
								</Menu>
							</Flex>
						) : (
							<Select
								onChange={(e) => handleAsignUser(e)}
								placeholder="Select an admin"
								size="xs"
							>
								{members?.map((member) => (
									<option
										key={member.id}
										value={member.id}
									>{`${member.firstName} ${member.lastName}`}</option>
								))}
							</Select>
						)}
					</Flex>
					<Text fontSize="xs" color="brand.gray100">
						{moment(data?.createdAt).format("DD/MM/YY h:mm a")}
					</Text>
				</HStack>
			</VStack>
		</HStack>
	);
};

export default ChatItem;
