import { useEffect } from "react";
import ChatItem from "./ChatItem";
import { IChats } from "@/types/interfaces";
import { useDispatch } from "react-redux";
import { setSelectedChat } from "@/redux/features/chat/chatSlice";

const ChatList = ({ data }: { data: IChats[] }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (data) {
			dispatch(setSelectedChat(data[0]));
		}
	}, [data]);
	return (
		<>
			{data.map((chat) => (
				<ChatItem key={chat.id} data={chat} />
			))}
		</>
	);
};

export default ChatList;
