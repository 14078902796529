import {
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	FormErrorMessage,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsEyeSlash, BsEye } from "react-icons/bs";

import {
	DeepMap,
	FieldError,
	FieldValues,
	Path,
	RegisterOptions,
	UseFormRegister,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

type FormInputProps<TFormValues extends FieldValues> = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	name: Path<TFormValues>;
	rules?: RegisterOptions;
	register?: UseFormRegister<TFormValues>;
	label: string;
	placeholder?: string;
	type: string;
	id?: string;
	errors?: Partial<DeepMap<TFormValues, FieldError>>;
};

export const PasswordInput = <TFormValues extends Record<string, unknown>>(
	props: FormInputProps<TFormValues>
) => {
	const { label, name, register, rules, errors, placeholder, ...otherProps } =
		props;
	const [showPassword, setShowPassword] = useState(false);

	const handleClick = () => setShowPassword(!showPassword);

	return (
		<Box>
			<FormControl mt="1.6rem">
				<FormLabel fontWeight="400" color="brand.dark100" htmlFor="password">
					{label}
				</FormLabel>
				<InputGroup>
					<Input
						id="password"
						border="1px solid #EAEAEA"
						borderRadius="5px"
						placeholder={placeholder}
						cursor="pointer"
						{...otherProps}
						{...(register && register(name, rules))}
						type={showPassword ? "text" : "password"}
						py="1.2rem"
						size="lg"
						_placeholder={{
							fontWeight: 400,
							fontSize: ".9rem",
							color: "brand.dark100",
						}}
						_focus={{
							borderColor: "brand.blue100",
							boxShadow: "none",
						}}
						_hover={{
							borderColor: "none",
						}}
					/>
					<InputRightElement
						fontSize="1.2rem"
						onClick={handleClick}
						cursor="pointer"
					>
						{showPassword ? <BsEye /> : <BsEyeSlash />}
					</InputRightElement>
				</InputGroup>

				<ErrorMessage
					errors={errors}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					name={name as any}
					render={({ message }) => (
						<FormErrorMessage>{message}</FormErrorMessage>
					)}
				/>
			</FormControl>
		</Box>
	);
};
