import { Box } from "@chakra-ui/react";
import { forwardRef, useEffect, useRef } from "react";

export interface TableCheckboxProps {
	indeterminate: boolean;
	rest: object;
}

const checkboxConfig = (
	{ indeterminate, ...rest }: TableCheckboxProps,
	ref: React.Ref<HTMLInputElement>
) => {
	const defaultRef = useRef<HTMLInputElement>();
	const resolvedRef: any = ref || defaultRef;

	useEffect(() => {
		resolvedRef.current.indeterminate = indeterminate;
	}, [resolvedRef, indeterminate]);
	return (
		<Box textAlign="center" className="inputCheckbox" transform="scale(1.5)">
			<input type="checkbox" value="red" ref={resolvedRef} {...rest} />
			{/* <Checkbox ref={resolvedRef} {...rest} /> */}
		</Box>
	);
};

const TableCheckbox = forwardRef(checkboxConfig);

export { TableCheckbox };
