import { Box } from "@chakra-ui/react";
import { Routes, Route } from "react-router-dom";
import { AuthNavigation } from "@/utils/AuthRoutes";
import ProtectedRoute from "@/utils/ProtectedRoute";

const ConfigureRoutes = () => {
	return (
		<Box>
			<Routes>
				{AuthNavigation.map((navigation) => {
					if (navigation.protected) {
						return (
							<Route key={navigation.path} element={<ProtectedRoute />}>
								<Route
									path={navigation.path}
									element={<navigation.component />}
								/>
							</Route>
						);
					}
					return (
						<Route
							key={navigation.path}
							path={navigation.path}
							element={<navigation.component />}
						/>
					);
				})}
			</Routes>
		</Box>
	);
};

export default ConfigureRoutes;
