import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

// slices
import dashboardReducer from "../features/dashboard/dashboardSlice";
import chatReducer from "@/redux/features/chat/chatSlice";

// serivices
import { authApi } from "@/services/requests/auth";
import { dashboardApi } from "@/services/requests/dashboard";

export const store = configureStore({
	reducer: {
		dashboard: dashboardReducer,
		chat: chatReducer,
		[authApi.reducerPath]: authApi.reducer,
		[dashboardApi.reducerPath]: dashboardApi.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(authApi.middleware, dashboardApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
setupListeners(store.dispatch);
