import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { useAppSelector } from "@/hooks/reduxHook";
import {
	setAllChats,
	setChatsAssignedToMe,
	setUnassignedChats,
} from "@/redux/features/chat/chatSlice";
import {
	useGetAllChatsQuery,
	useGetUserQuery,
} from "@/services/requests/dashboard";
import {
	Box,
	Flex,
	Grid,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Chatbox from "./chatbox/Chatbox";
import AllMessages from "./tabContent/AllMessages";
import AssignedToMe from "./tabContent/AssignedToMe";
import UnAssigned from "./tabContent/UnAssigned";

const Help = () => {
	const dispatch = useDispatch();
	const { data } = useGetAllChatsQuery();
	const { data: user } = useGetUserQuery();

	const { allChats, chatsAssignedToMe, unassignedChats } = useAppSelector(
		(state) => state.chat
	);

	useEffect(() => {
		if (data) {
			const nonAssigned = data.filter((chat) => !chat.assignedTo);
			const assignedToMe = data.filter(
				(chat) => chat?.assignedTo?.id?.toString() === user?.id?.toString()
			);
			dispatch(setAllChats(data));
			dispatch(setUnassignedChats(nonAssigned));
			dispatch(setChatsAssignedToMe(assignedToMe));
		}
	}, [data]);

	return (
		<Box>
			<Flex align="center" justify="space-between">
				<BreadcrumbLayout
					BreadcrumbLinkOne="Home"
					BreadcrumbLinkTwo="Help Center"
				/>
			</Flex>

			<Grid templateColumns="repeat(2, 1fr)" mt={4}>
				<Box>
					<Tabs>
						<TabList>
							<Tab fontSize="sm">All messages ({allChats.length})</Tab>
							<Tab fontSize="sm">
								Assigned to me ({chatsAssignedToMe.length})
							</Tab>
							<Tab fontSize="sm">Unassigned ({unassignedChats.length})</Tab>
						</TabList>
						<Box
							bg="brand.white100"
							border="0.5px solid #ebebeb"
							boxShadow="0px 2px 12px rgba(5, 57, 114, 0.06)"
							h="calc(100vh - 230px)"
						>
							<TabPanels h="100%">
								<TabPanel h="100%">
									<AllMessages />
								</TabPanel>
								<TabPanel h="100%">
									<AssignedToMe />
								</TabPanel>
								<TabPanel h="100%">
									<UnAssigned />
								</TabPanel>
							</TabPanels>
						</Box>
					</Tabs>
				</Box>
				<Box bg="brand.white100" h="calc(100vh - 230px)" mt={10}>
					<Chatbox />
				</Box>
			</Grid>
		</Box>
	);
};

export default Help;
