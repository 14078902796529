import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { DashboardButton } from "@/components/button/DashboardButton";
import PageLoader from "@/components/loader/PageLoader";
import Permissions from "@/features/Permissions";
import {
	useGetAllPermissionsQuery,
	useGetAllRolesQuery,
	useGetMemberQuery,
	useUpdateMemberMutation,
} from "@/services/requests/dashboard";
import { editMemberInputTypes } from "@/types/formInputTypes";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	Radio,
	RadioGroup,
	Select,
	SimpleGrid,
	Text,
	useToast,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import React, { useEffect, useMemo, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

const EditMember = () => {
	const { profile } = useParams();
	const router = useNavigate();

	const {
		reset,
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<editMemberInputTypes>();

	const { isLoading, data } = useGetMemberQuery(profile || "0");
	const { data: roles } = useGetAllRolesQuery();

	const [dob, setDob] = useState<Date>(new Date(data?.dob || 0));

	const toast = useToast();

	const [updateMember, { isLoading: isUpdateLoading, isError, isSuccess }] =
		useUpdateMemberMutation();

	const result = useMemo(() => data?.permissions?.map(({ id }) => id), [data]);

	useEffect(() => {
		if (data) {
			reset({
				firstName: data.firstName || "",
				lastName: data.lastName || "",
				dob: new Date(data.dob) || "",
				gender: data.gender || "",
				maritalStatus: data.maritalStatus || "",
				email: data.email || "",
				country: data.country || "",
				city: data.city || "",
				postCode: data.postCode || "",
				phoneNo: data.phoneNo || "",
				address: data.address || "",
				jobTitle: data.jobTitle || "",
				jobDescription: data.jobDescription || "",
				title: data.gender === "Male" ? "Mr" : "Mrs",
				role: data?.role?.role,
				permissions: result,
			});
		}
	}, [data]);

	const onSubmit: SubmitHandler<editMemberInputTypes> = async (data) => {
		const role = roles?.data?.find((role) => role.role === data.role);

		const permission = role?.permissions?.map(({ id }) => id);

		const updatedData = data;
		updatedData.role = role?.id;
		updatedData.dob = dob;
		updatedData.permissions = permission;
		await updateMember({ ...updatedData, id: profile });
	};

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: "Member updated successfully",
				status: "success",
				duration: 9000,
				isClosable: true,
			});

			router("/dashboard/team/members");
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			toast({
				title: "An error occurred",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [isError]);

	return (
		<Box>
			{isLoading ? (
				<PageLoader py="12rem" />
			) : (
				<Box as="form" onSubmit={handleSubmit(onSubmit)}>
					<Flex justify="space-between">
						<Box>
							<BreadcrumbLayout
								BreadcrumbLinkOne="Home"
								BreadcrumbLinkTwo="Members"
								BreadcrumbLinkThree={data?.firstName + " " + data?.lastName}
							/>
						</Box>
						<Flex>
							<Flex>
								<HStack
									cursor="pointer"
									onClick={() => router("/dashboard/team/members")}
									spacing=".3rem"
									color="brand.red100"
									mr="2rem"
								>
									<Text>Cancel</Text>
								</HStack>

								<DashboardButton
									type="submit"
									text={isUpdateLoading ? "Loading..." : "SAVE CHANGES"}
								/>
							</Flex>
						</Flex>
					</Flex>
					<Flex
						justify="space-between"
						border="1px solid"
						borderColor="brand.gray500"
						bg="brand.white100"
						borderRadius="5px"
						mt="1rem"
						px="1rem"
					>
						<Box w="55%">
							<Accordion defaultIndex={[0]} allowToggle allowMultiple>
								<AccordionItem border="none">
									<h2>
										<AccordionButton
											borderBottom="1px solid"
											borderColor="brand.gray500"
											m="1.5rem 0 0.5rem"
											px="0"
											_hover={{
												bg: "none",
											}}
											_focus={{
												boxShadow: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												color="brand.blue200"
												fontSize="1.2rem"
												fontWeight="400"
											>
												Personal Information
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4} px="0">
										<FormControl>
											<FormLabel htmlFor="name">Title</FormLabel>
											<Select
												id="title"
												placeholder=""
												{...register("title", {
													required: "Please select a title",
												})}
											>
												<option value="Mr">Mr</option>
												<option value="Mrs">Mrs</option>
												<option value="Miss">Miss</option>
											</Select>
											<FormErrorMessage>
												{errors?.title && errors?.title?.message}
											</FormErrorMessage>
										</FormControl>

										{/* full name */}
										<SimpleGrid mt="1.5rem" columns={2} spacing="2rem">
											<FormControl>
												<FormLabel htmlFor="name">First name</FormLabel>
												<Input
													id="firstName"
													placeholder=""
													{...register("firstName", {
														required: "Please enter first name",
														minLength: {
															value: 3,
															message: "Minimum length should be 3",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.firstName && errors?.firstName?.message}
												</FormErrorMessage>
											</FormControl>
											<FormControl>
												<FormLabel htmlFor="name">Last name</FormLabel>
												<Input
													id="lastName"
													placeholder=""
													{...register("lastName", {
														required: "Please enter last name",
														minLength: {
															value: 3,
															message: "Minimum length should be 3",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.lastName && errors?.lastName?.message}
												</FormErrorMessage>
											</FormControl>
										</SimpleGrid>

										{/* full name */}
										<SimpleGrid mt="1.5rem" columns={2} spacing="2rem">
											<FormControl>
												<FormLabel htmlFor="name">Date of Birth</FormLabel>
												<SingleDatepicker
													name="date-input"
													date={dob}
													onDateChange={setDob}
												/>
											</FormControl>
											<FormControl>
												<FormLabel htmlFor="name">Gender</FormLabel>
												<Select
													id="gender"
													placeholder=""
													{...register("gender", {
														required: "Please select a gender",
													})}
												>
													<option value="Male">Male</option>
													<option value="Female">Female</option>
													<option value="Others">Others</option>
												</Select>
												<FormErrorMessage>
													{errors?.gender && errors?.gender?.message}
												</FormErrorMessage>
											</FormControl>
										</SimpleGrid>

										{/* marital status and email */}
										<SimpleGrid mt="1.5rem" columns={2} spacing="2rem">
											<FormControl>
												<FormLabel htmlFor="maritalStatus">
													Marital Status
												</FormLabel>
												<Select
													id="maritalStatus"
													placeholder=""
													{...register("maritalStatus", {
														required: "Please select a marital status",
													})}
												>
													<option value="Single">Single</option>
													<option value="Maried">Maried</option>
												</Select>
												<FormErrorMessage>
													{errors?.maritalStatus &&
														errors?.maritalStatus?.message}
												</FormErrorMessage>
											</FormControl>
											<FormControl>
												<FormLabel htmlFor="email">Email Address</FormLabel>
												<Input
													id="email"
													placeholder=""
													type="email"
													{...register("email", {
														required: "Please enter your Email address",
														minLength: {
															value: 4,
															message: "Email is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.email && errors?.email?.message}
												</FormErrorMessage>
											</FormControl>
										</SimpleGrid>

										{/* city and country */}
										<SimpleGrid mt="1.5rem" columns={2} spacing="2rem">
											<FormControl>
												<FormLabel htmlFor="country">Country</FormLabel>
												<Input
													id="country"
													placeholder=""
													{...register("country", {
														required: "Please enter a country",
														minLength: {
															value: 2,
															message: "Country is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.country && errors?.country?.message}
												</FormErrorMessage>
											</FormControl>
											<FormControl>
												<FormLabel htmlFor="city">City</FormLabel>
												<Input
													id="city"
													placeholder=""
													{...register("city", {
														required: "Please enter a city",
														minLength: {
															value: 2,
															message: "City is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.country && errors?.country?.message}
												</FormErrorMessage>
											</FormControl>
										</SimpleGrid>

										{/* postal code and Phone Number */}
										<SimpleGrid mt="1.5rem" columns={2} spacing="2rem">
											<FormControl>
												<FormLabel htmlFor="postCode">Postcode</FormLabel>
												<Input
													id="postCode"
													placeholder=""
													{...register("postCode", {
														required: "Please enter Postal Code",
														minLength: {
															value: 2,
															message: "Postal Code is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.postCode && errors?.postCode?.message}
												</FormErrorMessage>
											</FormControl>
											<FormControl>
												<FormLabel htmlFor="phoneNo">Phone Number</FormLabel>
												<Input
													id="phoneNo"
													placeholder=""
													{...register("phoneNo", {
														required: "Please enter phone number",
														minLength: {
															value: 8,
															message: "Phone Number is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.phoneNo && errors?.phoneNo?.message}
												</FormErrorMessage>
											</FormControl>
										</SimpleGrid>

										{/* address */}
										<Box mt="1.5rem" mb="1rem">
											<FormControl>
												<FormLabel htmlFor="address">Address</FormLabel>
												<Input
													id="address"
													placeholder=""
													{...register("address", {
														required: "Please enter address",
														minLength: {
															value: 5,
															message: "Address is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.address && errors?.address?.message}
												</FormErrorMessage>
											</FormControl>
										</Box>
									</AccordionPanel>
								</AccordionItem>
								<AccordionItem>
									<h2>
										<AccordionButton
											borderBottom="1px solid"
											borderColor="brand.gray500"
											m="1.5rem 0 0.5rem"
											px="0"
											_hover={{
												bg: "none",
											}}
											_focus={{
												boxShadow: "none",
											}}
										>
											<Box
												flex="1"
												textAlign="left"
												color="brand.blue200"
												fontSize="1.2rem"
												fontWeight="400"
											>
												Job Information
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>

									<AccordionPanel pb="1rem">
										{/* job title */}
										<Box mt="0.5rem">
											<FormControl>
												<FormLabel htmlFor="jobTitle">Job Title</FormLabel>
												<Input
													id="jobTitle"
													placeholder=""
													{...register("jobTitle", {
														minLength: {
															value: 3,
															message: "Job Title is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.jobTitle && errors?.jobTitle?.message}
												</FormErrorMessage>
											</FormControl>
										</Box>

										{/* job description */}
										<Box mt="1.5rem" mb="2.5rem">
											<FormControl>
												<FormLabel htmlFor="jobTitle">
													Job Description
												</FormLabel>
												<Input
													id="jobDescription"
													placeholder=""
													{...register("jobDescription", {
														minLength: {
															value: 3,
															message: "Job jobDescription is too short",
														},
													})}
												/>
												<FormErrorMessage>
													{errors?.jobDescription &&
														errors?.jobDescription?.message}
												</FormErrorMessage>
											</FormControl>
										</Box>
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</Box>

						<Box w="40%">
							<Permissions>
								<Accordion allowToggle>
									<AccordionItem>
										<h2>
											<AccordionButton>
												<Box flex="1" textAlign="left">
													Permissions
												</Box>
												<AccordionIcon />
											</AccordionButton>
										</h2>
										<AccordionPanel pb={4}>
											<Controller
												name="role"
												control={control}
												render={({ field: { onChange, value } }) => (
													<RadioGroup onChange={onChange} value={value}>
														<SimpleGrid mt="2rem" columns={2} gap="1rem">
															{roles?.data?.map((item) => (
																<Radio
																	key={item.id}
																	value={item?.role}
																	name="role"
																>
																	{item.role}
																</Radio>
															))}
														</SimpleGrid>
													</RadioGroup>
												)}
											/>
										</AccordionPanel>
									</AccordionItem>
								</Accordion>
							</Permissions>
						</Box>
					</Flex>
				</Box>
			)}
		</Box>
	);
};

export default EditMember;
