import { GridLayout } from "@/layouts/home/GridLayout";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import TripImg from "@/assets/images/trip-img.webp";
import OdometerImg from "@/assets/images/odometer-img.webp";
import ClaimImg from "@/assets/images/claim-img.webp";
import CircleCheckmark from "@/assets/images/circle-checkmark.webp";

const Payless = () => {
	return (
		<Box bg="brand.white100" pos="relative" overflow="hidden">
			<Box
				mt="5rem"
				maxW="1200px"
				p={["2rem 1rem", "2rem", "3rem", "3rem", "3rem"]}
				m="5rem auto"
			>
				<Flex
					flexDir={["column", "column", "column", "row", "row"]}
					justify="space-between"
					align="center"
				>
					<Box
						w={["100%", "100%", "100%", "50%", "50%"]}
						mt="2rem"
						order={["1", "1", "1", "0", "0"]}
					>
						<Text
							color="brand.dark200"
							fontWeight="400"
							fontFamily="love"
							fontSize={["1.75rem", "2rem", "2rem", "2rem", "2.2rem"]}
							mr={["1rem", "0", " 0", "0", "0"]}
						>
							Our pay-per-trip insurance is straightforward, easy to use, and
							can save you 30-40% off your annual insurance cost.
						</Text>
						<Flex
							flexDir={["column", "column", "column", "column", "row"]}
							justify="space-between"
							mt="1rem"
							w="83%"
						>
							<Flex
								justify={[
									"flex-start",
									"flex-start",
									"flex-start",
									"flex-start",
									"space-between",
								]}
								align="center"
							>
								<Box
									bg="brand.red500"
									color="brand.red100"
									borderRadius="50%"
									fontWeight="500"
									fontSize=".9rem"
									p=".1rem .6rem"
									mr=".7rem"
								>
									1
								</Box>
								<Box fontWeight="400" color="brand.dark200">
									Start a trip
								</Box>
							</Flex>

							<Flex
								justify={[
									"flex-start",
									"flex-start",
									"flex-start",
									"flex-start",
									"space-between",
								]}
								align="center"
								my={["1rem", "1rem", "1rem", "1rem", "0"]}
							>
								<Box
									bg="brand.red500"
									color="brand.red100"
									borderRadius="50%"
									fontWeight="500"
									fontSize=".9rem"
									p=".1rem .6rem"
									mr=".7rem"
								>
									2
								</Box>
								<Box fontWeight="400" color="brand.dark200">
									Insure your vehicle
								</Box>
							</Flex>

							<Flex
								justify={[
									"flex-start",
									"flex-start",
									"flex-start",
									"flex-start",
									"space-between",
								]}
								align="center"
							>
								<Box
									bg="brand.red500"
									color="brand.red100"
									borderRadius="50%"
									fontWeight="500"
									fontSize=".9rem"
									p=".1rem .6rem"
									mr=".7rem"
								>
									3
								</Box>
								<Box fontWeight="400" color="brand.dark200">
									End trip
								</Box>
							</Flex>
						</Flex>
					</Box>

					<Box>
						<GridLayout
							title="You’re always in control"
							image={TripImg}
							isNotDetails
						/>
					</Box>
				</Flex>

				<Flex
					flexDir={["column", "column", "column", "row", "row"]}
					justify="space-between"
					align="center"
					py="6rem"
				>
					<Box>
						<GridLayout
							title="You’re always in control"
							image={OdometerImg}
							isNotDetails
						/>
					</Box>

					<Box
						w={["100%", "100%", "100%", "50%", "50%"]}
						mt="2rem"
						order={["1", "1", "1", "0", "0"]}
					>
						<Text
							color="brand.dark200"
							fontWeight="400"
							fontFamily="love"
							fontSize={["1.75rem", "2rem", "2rem", "2rem", "2.2rem"]}
						>
							You are always in control of your insurance charges.
						</Text>
						<Text
							fontSize=".9rem"
							color="brand.gray100"
							my="1rem"
							mr={["0", "0", "3rem"]}
						>
							With our pay-per-trip insurance, you only pay for what you use.
							The less you drive, the more you save. So where you go, how you
							drive, and where you and your car sleep at night is your business,
							not your insurance company’s.
						</Text>
					</Box>
				</Flex>

				<Flex
					flexDir={["column", "column", "column", "row", "row"]}
					justify="space-between"
					align="center"
				>
					<Box
						w={["100%", "100%", "100%", "50%", "50%"]}
						mt="2rem"
						order={["1", "1", "1", "0", "0"]}
					>
						<Text
							color="brand.dark200"
							fontWeight="400"
							fontFamily="love"
							fontSize={["1.75rem", "2rem", "2rem", "2rem", "2.2rem"]}
						>
							Filing your claims is easy.
						</Text>
						<Box
							w={["100%", "100%", "100%", "80%", "80%"]}
							mt=".5rem"
							color="brand.gray100"
						>
							<Text fontSize=".9rem">
								You can file a claim in the app or online for a quick and easy
								experience. We&apos;ll walk you through every step of the way.
							</Text>
							<Flex fontWeight="300" mt="1rem">
								<Image mr="1rem" src={CircleCheckmark} alt="Checkmark" />
								<Text fontSize=".9rem">
									Select the odometer mode of insurance for your vehicle.
								</Text>
							</Flex>
							<Flex fontWeight="300" py="1rem">
								<Image mr="1rem" src={CircleCheckmark} alt="Checkmark" />
								<Text fontSize=".9rem">
									Take a picture of your odometer every month.
								</Text>
							</Flex>
							<Flex fontWeight="300">
								<Image mr="1rem" src={CircleCheckmark} alt="Checkmark" />
								<Text fontSize=".9rem">
									Your insurance will be calculated based on distance covered.
								</Text>
							</Flex>
						</Box>
					</Box>

					<Box>
						<GridLayout
							title="You’re always in control"
							image={ClaimImg}
							isNotDetails
						/>
					</Box>
				</Flex>
			</Box>
		</Box>
	);
};

export { Payless };
