import React from "react";

import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
} from "@chakra-ui/react";

import {
	DeepMap,
	FieldError,
	FieldValues,
	Path,
	RegisterOptions,
	UseFormRegister,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

type FormInputProps<TFormValues extends FieldValues> = React.DetailedHTMLProps<
	React.InputHTMLAttributes<HTMLInputElement>,
	HTMLInputElement
> & {
	name: Path<TFormValues>;
	rules?: RegisterOptions;
	register?: UseFormRegister<TFormValues>;
	label: string;
	placeholder?: string;
	type: string;
	id?: string;
	errors?: Partial<DeepMap<TFormValues, FieldError>>;
	mb?: string;
};

const InputGroup = <TFormValues extends Record<string, unknown>>(
	props: FormInputProps<TFormValues>
) => {
	const {
		label,
		id,
		name,
		register,
		rules,
		placeholder,
		type,
		errors,
		mb,
		...otherProps
	} = props;
	return (
		<FormControl my={mb ? "0.25rem" : "1.6rem"}>
			<FormLabel fontWeight="400" color="brand.dark100" htmlFor="email">
				{label}
			</FormLabel>
			<Input
				id={id}
				border="1px solid #D9D9D9"
				borderRadius="5px"
				placeholder={placeholder}
				cursor="pointer"
				{...otherProps}
				{...(register && register(name, rules))}
				type={type}
				py={mb ? "0" : "1.2rem"}
				size="md"
				_placeholder={{
					fontWeight: 400,
					fontSize: ".9rem",
					color: "brand.dark100",
				}}
				_focus={{
					borderColor: "brand.blue100",
					boxShadow: "none",
				}}
				_hover={{
					borderColor: "none",
				}}
			/>

			<ErrorMessage
				errors={errors}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				name={name as any}
				render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
			/>
		</FormControl>
	);
};

export default InputGroup;
