import { ITag } from "@/types/interfaces";
import { Tag, TagLabel } from "@chakra-ui/react";
import { useEffect, useState } from "react";

// constant for status
const status = {
	active: "Active",
	approved: "Approved",
	declined: "Declined",
	inactive: "Inactive",
	pending: "Pending",
	paid: "Paid",
};

const Status = ({ text }: ITag) => {
	const [bg, setBg] = useState("brand.green100");
	const [color, setColor] = useState("brand.green500");

	// set background and color based on status
	const setStatus = () => {
		switch (text) {
			case status.active:
			case status.approved:
			case status.paid:
				setBg("brand.green100");
				setColor("brand.green500");
				break;
			case status.inactive:
			case status.declined:
				setBg("brand.red500");
				setColor("brand.red200");
				break;
			case status.pending:
				setBg("brand.yellow100");
				setColor("brand.yellow500");
				break;
			default:
				setBg("brand.green100");
				setColor("brand.green500");
		}
	};

	useEffect(() => {
		if (text) {
			setStatus();
		}
	}, [text]);

	return (
		<Tag
			size="lg"
			borderRadius="full"
			variant="solid"
			background={bg}
			color={color}
		>
			<TagLabel>{text}</TagLabel>
		</Tag>
	);
};

export default Status;
