import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
	reducerPath: "authApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("guliva-token");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (body: { email: string; password: string }) => {
				return {
					url: "/member/login",
					method: "post",
					body,
				};
			},
		}),
		forgetPassword: builder.mutation({
			query: (body: { email: string }) => {
				return {
					url: "/member/send-link",
					method: "post",
					body,
				};
			},
		}),
		resendLink: builder.mutation({
			query: (body: { email: string }) => {
				return {
					url: "/member/resend-link",
					method: "post",
					body,
				};
			},
		}),
		resetPassword: builder.mutation({
			query: (body: { email: string | null; newPassword: string }) => {
				return {
					url: "/member/reset-password",
					method: "post",
					body,
				};
			},
		}),
		resetDashboardPassword: builder.mutation({
			query: (body: {
				oldPassword: string | undefined;
				newPassword: string;
			}) => {
				return {
					url: "/member/update-password",
					method: "post",
					body,
				};
			},
		}),
		subscribeUser: builder.mutation({
			query: (body: { email: string; phoneNo: string | number }) => {
				return {
					url: "/member/subcriber/create",
					method: "post",
					body,
				};
			},
		}),
	}),
});

export const {
	useLoginMutation,
	useForgetPasswordMutation,
	useResendLinkMutation,
	useResetPasswordMutation,
	useResetDashboardPasswordMutation,
	useSubscribeUserMutation,
} = authApi;
