import { Box, SimpleGrid, Skeleton } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DashboardCard } from "@/components/card/DashboardCard";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { TableComponent } from "@/layouts/table/TableLayout";
import { SearchInput } from "@/components/input/SearchInput";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useGetAllPermissionsQuery } from "@/services/requests/dashboard";

import PersonIcon from "@/assets/icons/guliva_person.webp";
import SecurityIcon from "@/assets/icons/guliva_security.webp";
import RolesIcon from "@/assets/icons/guliva_roles.webp";
import moment from "moment";
import PageLoader from "@/components/loader/PageLoader";

const PermissionPage = () => {
	const dispatch = useDispatch();

	const [searchTerm, setSearchTerm] = useState<string>("");
	const { data: permissionData, isLoading } = useGetAllPermissionsQuery();

	useEffect(() => {
		dispatch(setHeaderText("Permissions"));
	}, []);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const filteredData = useMemo(() => {
		if (permissionData) {
			const sortedData = permissionData?.data?.filter((item: any) =>
				item.name.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, permissionData]);

	const columns = [
		{
			Header: "Permissions",
			accessor: "permissions",
		},
		{
			Header: "No. of Members",
			accessor: "membersNumber",
			// Cell: ({ row }: any) => (
			// 	<Text textAlign="center">{row.original.membersNumber}</Text>
			// ),
		},
		{
			Header: "No. of Roles",
			accessor: "roles",
			// Cell: ({ row }: any) => (
			// 	<Text textAlign="center">{row.original.roles}</Text>
			// ),
		},
		{
			Header: "Date Added",
			accessor: "date",
		},
	];

	const tableData = useMemo(
		() =>
			filteredData?.map((item) => ({
				permissions: item?.name || "",
				membersNumber: item?.member?.length || 0,
				roles: item?.roles?.length || 0,
				date: moment(item.createdAt).format("Do MMM YYYY"),
			})),
		[filteredData]
	);
	return (
		<Box>
			<BreadcrumbLayout
				BreadcrumbLinkOne="Home"
				BreadcrumbLinkTwo="Permissions"
			/>

			<Box mt="2rem">
				{isLoading ? (
					<SimpleGrid
						margin="1rem 0 0 0"
						columns={3}
						spacingX="2rem"
						spacingY="1rem"
					>
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={3} spacingX="1.5rem" spacingY=".4rem">
						<DashboardCard
							title="TOTAL NO. OF MEMBERS"
							icon={PersonIcon}
							value={Number(permissionData?.totalMembers)}
						/>
						<DashboardCard
							title="NO. OF ROLES"
							icon={RolesIcon}
							value={Number(permissionData?.totalRoles)}
						/>
						<DashboardCard
							title="NO. OF PERMISSIONS"
							icon={SecurityIcon}
							value={Number(permissionData?.totalPermissions)}
						/>
					</SimpleGrid>
				)}
			</Box>

			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onChange={handleChange}
					value={searchTerm}
					placeholder="Search..."
					w="48%"
				/>

				{isLoading ? (
					<PageLoader py="6rem" />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>
		</Box>
	);
};

export default PermissionPage;
