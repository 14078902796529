import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { DashboardButton } from "@/components/button/DashboardButton";
import PageLoader from "@/components/loader/PageLoader";
import CustomAccordionItem from "@/components/profile/AccordionItem";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useGetClaimQuery } from "@/services/requests/dashboard";
import { Box, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";

const ClaimProfile = () => {
	const { profile } = useParams();
	const { isLoading, data } = useGetClaimQuery(profile || "0");

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(setHeaderText(`Claim ID: ${profile}`));
	}, [data]);

	return (
		<Box>
			{isLoading ? (
				<PageLoader py="12rem" />
			) : (
				<>
					<Flex align="center" justify="space-between">
						<BreadcrumbLayout
							BreadcrumbLinkOne="Home"
							BreadcrumbLinkTwo="Claims"
							BreadcrumbLinkThree={profile}
							isThird
						/>

						<Flex align="center">
							<Box>
								<Link to={`/dashboard/claims/${profile}/decline`}>
									<Text
										cursor="pointer"
										align="center"
										mr="1rem"
										color="brand.red100"
									>
										Decline
									</Text>
								</Link>
							</Box>
							<DashboardButton
								onClick={() => navigate(`/dashboard/claims/${profile}/approve`)}
								text="APPROVE"
							/>
						</Flex>
					</Flex>

					<Flex
						justify="space-between"
						border="1px solid"
						borderColor="brand.gray500"
						bg="brand.white100"
						borderRadius="5px"
						mt="1.8rem"
						px="1.5rem"
						py="2rem"
					>
						<Box w="57%">
							<SimpleGrid column={2} spacingX="4rem">
								<CustomAccordionItem title="Claim ID" value={data?.id || ""} />
								<CustomAccordionItem
									title="Date"
									value={moment(data?.claimDate).format("Do MMM YYYY") || ""}
								/>
								<CustomAccordionItem
									title="Reason for claim"
									value={data?.typeOfIncident || ""}
								/>
								<CustomAccordionItem
									title="Details"
									value={data?.description || ""}
								/>
								<CustomAccordionItem
									title="Were you a driver or passenger?"
									value={data?.driverName || ""}
								/>
								<Flex>
									<Text fontSize="sm" color="brand.gray100" w="40%">
										Photos attached
									</Text>
									<SimpleGrid columns={2} spacingX={4} mt={2}>
										<>
											<Image
												w="100%"
												h="auto"
												src={data?.user?.image}
												alt="vehicle"
											/>
											{/* <Image
												w="100%"
												h="auto"
												src={data?.sideView}
												alt="vehicle"
											/> */}
										</>
									</SimpleGrid>
								</Flex>
							</SimpleGrid>
						</Box>
						<Box
							w="40%"
							border="1px solid"
							borderColor="brand.gray500"
							p="1.5rem"
						>
							<Text color="brand.blue200" fontSize="xl" fontWeight="500">
								Vehicle Details
							</Text>
							<Box mt="1rem" key={data?.user?.id}>
								<Text color="brand.gray100">Name of vehicle</Text>
								<Text color="brand.dark100" fontSize="lg">
									{data?.user?.firstName}
								</Text>

								<SimpleGrid my={8} columns={3} spacingX={2} spacingY={4}>
									<Box>
										<Text color="brand.gray100">Model</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.vehicle?.model}
										</Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Color</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.vehicle?.color}
										</Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Year</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.user?.year}
										</Text>
									</Box>
									<Box>
										<Text color="brand.gray100">License plate</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.vehicle?.registrationNumber}
										</Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Engine Number</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.vehicle?.engineNumber}
										</Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Chasis Number</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.vehicle?.chassisNumber}
										</Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Primary Driver</Text>
										<Text color="brand.dark100" fontSize="md">
											{data?.vehicle?.model}
										</Text>
									</Box>
								</SimpleGrid>

								<Box>
									<Text fontSize="sm" color="brand.gray100">
										Photos attached
									</Text>
									<SimpleGrid columns={4} spacingX={4} mt={2}>
										<>
											<Image
												w="100%"
												h="auto"
												src={data?.vehicle?.frontView}
												alt="vehicle"
											/>
											<Image
												w="100%"
												h="auto"
												src={data?.vehicle?.sideView}
												alt="vehicle"
											/>
											<Image
												w="100%"
												h="auto"
												src={data?.vehicle?.backView}
												alt="vehicle"
											/>
											<Image
												w="100%"
												h="auto"
												src={data?.vehicle?.topView}
												alt="vehicle"
											/>
										</>
									</SimpleGrid>
								</Box>

								<SimpleGrid my={8} columns={2} spacingX={4} spacingY={4}>
									<>
										<Box>
											<Text color="brand.gray100">
												Total Distance Covered (km)
											</Text>
											<Text color="brand.dark100" fontSize="md">
												{data?.vehicle?.distanceCovered}
											</Text>
										</Box>
										<Box>
											<Text color="brand.gray100">Amount Billed</Text>
											<Text color="brand.dark100" fontSize="md">
												{data?.vehicle?.value}
											</Text>
										</Box>
									</>
								</SimpleGrid>
							</Box>
						</Box>
					</Flex>
				</>
			)}
		</Box>
	);
};

export default ClaimProfile;
