import {
	ForgetFormInputDetails,
	LoginFormInputDetails,
	passwordDashboardResetInputDetails,
	passwordResetInputDetails,
} from "../types/formInputTypes";
export const LoginFormInputs: LoginFormInputDetails[] = [
	{
		label: "Email",
		type: "email",
		name: "email",
		placeholder: "Email Address",
		rules: {
			required: "Please enter your email address",
			minLength: {
				value: 4,
				message: "Please enter a valid email",
			},
		},
	},
	{
		label: "Password",
		type: "password",
		name: "password",
		placeholder: "Password",
		rules: {
			required: "Please enter password",
			minLength: { value: 6, message: "Password is too short" },
		},
	},
];

export const forgetPasswordInputs: ForgetFormInputDetails[] = [
	{
		label: "Email",
		type: "email",
		name: "email",
		placeholder: "Email Address",
		rules: {
			required: "Please enter your email address",
			minLength: {
				value: 4,
				message: "Please enter a valid email",
			},
		},
	},
];

export const resetPasswordFormInputs: passwordResetInputDetails[] = [
	{
		label: "New Password",
		type: "password",
		name: "newPassword",
		placeholder: "Password",
		rules: {
			required: "Please enter password",
			minLength: { value: 6, message: "Password is too short" },
		},
	},
	{
		label: "Password",
		type: "password",
		name: "confirmNewPassword",
		placeholder: "Confirm Password",
		rules: {
			required: "Please enter password",
			minLength: { value: 6, message: "Password is too short" },
		},
	},
];

export const resetDashboardPasswordFormInputs: passwordDashboardResetInputDetails[] =
	[
		{
			label: "Old Password",
			type: "password",
			name: "oldPassword",
			placeholder: "Old Password",
			rules: {
				required: "Please enter old password",
			},
		},
		{
			label: "New Password",
			type: "password",
			name: "newPassword",
			placeholder: "New Password",
			rules: {
				required: "Please enter new password",
				minLength: { value: 6, message: "Password is too short" },
			},
		},
	];
