import {
	CustomerApiProps,
	DashboardApiProps,
	IAllClaimsProps,
	IAllCustomerProps,
	IAllPremiumDataProps,
	IChats,
	IClaimDataProps,
	IPermissionsProps,
	MemberApiProps,
	UserApiProps,
} from "@/types/interfaces";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const dashboardApi = createApi({
	reducerPath: "dashboardApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers) => {
			const token = localStorage.getItem("guliva-token");
			if (token) {
				headers.set("Authorization", `Bearer ${token}`);
			}

			return headers;
		},
	}),
	tagTypes: ["member", "chat", "role", "claim"],
	endpoints: (builder) => ({
		getDashboard: builder.query<DashboardApiProps, void>({
			query: () => "/member/dashboard",
			transformResponse: (response: { data: DashboardApiProps }) =>
				response.data,
		}),
		getAllCustomers: builder.query<IAllCustomerProps, void>({
			query: () => "/member/customer",
			transformResponse: (response: IAllCustomerProps) => response,
			providesTags: ["member"],
		}),
		getCustomer: builder.query<CustomerApiProps, string>({
			query: (id) => `/member/customer/id/${id}`,
			transformResponse: (response: CustomerApiProps) => response,
			providesTags: ["member"],
		}),
		getAllMembers: builder.query<MemberApiProps[], void>({
			query: () => "/member",
			transformResponse: (response: { data: MemberApiProps[] }) =>
				response.data,
			providesTags: ["member"],
		}),
		getMember: builder.query<UserApiProps, string>({
			query: (id) => `/member/id/${id}`,
			transformResponse: (response: { data: UserApiProps }) => response.data,
			providesTags: ["member"],
		}),
		getUser: builder.query<UserApiProps, void>({
			query: () => "/member/profile",
			transformResponse: (response: { data: UserApiProps }) => response.data,
			providesTags: ["member"],
		}),
		deactivateMember: builder.mutation({
			query: (body: { id: string | null; active: boolean }) => {
				return {
					url: "/member/deactivate",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["member"],
		}),
		createMember: builder.mutation({
			query: (body: any) => {
				return {
					url: "/member/register",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["member"],
		}),
		updateMember: builder.mutation({
			query: (body: any) => {
				return {
					url: "/member/update-profile",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["member"],
		}),
		deleteMember: builder.mutation({
			query: ({ id }: { id: string }) => ({
				url: `/member/delete/id/${id}`,
				method: "delete",
			}),
			invalidatesTags: ["member"],
		}),

		// assign user to chat
		assignTo: builder.mutation({
			query: (body: { userId: string | null; assignTo: string | null }) => {
				return {
					url: "/chat/assign-to",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["chat", "member"],
		}),

		getAllPremiums: builder.query<IAllPremiumDataProps, void>({
			query: () => "/premium",
			transformResponse: (response: IAllPremiumDataProps) => response,
		}),

		getAllActivity: builder.query<IAllPremiumDataProps, void>({
			query: () => "/activity",
			transformResponse: (response: IAllPremiumDataProps) => response,
		}),
		getAllPermissions: builder.query<IPermissionsProps, void>({
			query: () => "/permission",
			transformResponse: (response: IPermissionsProps) => response,
		}),
		getAllRoles: builder.query<IPermissionsProps, void>({
			query: () => "/role",
			transformResponse: (response: IPermissionsProps) => response,
			providesTags: ["role"],
		}),
		getRole: builder.query<IPermissionsProps, string>({
			query: (id) => `/role/id/${id}`,
			transformResponse: (response: IPermissionsProps) => response,
			providesTags: ["role"],
		}),
		deleteRole: builder.mutation({
			query: ({ id }: { id: string }) => ({
				url: `/role/delete/id/${id}`,
				method: "delete",
			}),
			invalidatesTags: ["role"],
		}),
		// get all chats
		getAllChats: builder.query<IChats[], void>({
			query: () => "/chat",
			providesTags: ["chat"],
			transformResponse: (response: { data: IChats[] }) => response.data,
		}),

		// get a chat message
		getChat: builder.query<IChats[], string>({
			query: (id) => `/chat/inline/${id}`,
			providesTags: ["chat"],
			transformResponse: (response: { data: IChats[] }) => response.data,
		}),

		// unassign user
		unassign: builder.mutation({
			query: (body: { userId: string | number | null }) => {
				return {
					url: "/chat/unassign",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["chat", "member"],
		}),

		// claims
		getAllClaims: builder.query<IAllClaimsProps, void>({
			query: () => "/claim",
			transformResponse: (response: IAllClaimsProps) => response,
			providesTags: ["claim"],
		}),
		getClaim: builder.query<IClaimDataProps, string>({
			query: (id) => `/claim/id/${id}`,
			transformResponse: (response: { data: IClaimDataProps }) => response.data,
		}),

		approveClaim: builder.mutation({
			query: (body: {
				id: string | null;
				amountPayable?: number;
				adminMessage: string;
				status: string;
			}) => {
				return {
					url: "/claim/attended",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["claim"],
		}),

		downloadClaim: builder.query<any, void>({
			query: () => "/claim/download-report",
			transformResponse: (response: any) => response,
		}),

		// update role
		updateRole: builder.mutation({
			query: (body: any) => {
				return {
					url: "/role/update",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["role"],
		}),
		// create role
		createRole: builder.mutation({
			query: (body: any) => {
				return {
					url: "/role/create",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["role"],
		}),
	}),
});

export const {
	useGetDashboardQuery,
	useGetAllCustomersQuery,
	useGetAllMembersQuery,
	useGetMemberQuery,
	useGetCustomerQuery,
	useDeactivateMemberMutation,
	useDeleteMemberMutation,
	useGetAllPremiumsQuery,
	useGetAllActivityQuery,
	useGetAllPermissionsQuery,
	useGetAllRolesQuery,
	useGetRoleQuery,
	useDeleteRoleMutation,
	useGetAllChatsQuery,
	useGetUserQuery,
	useGetChatQuery,
	useAssignToMutation,
	useGetAllClaimsQuery,
	useGetClaimQuery,
	useApproveClaimMutation,
	useUpdateMemberMutation,
	useUpdateRoleMutation,
	useDownloadClaimQuery,
	useCreateRoleMutation,
	useCreateMemberMutation,
	useUnassignMutation,
} = dashboardApi;
