import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { DashboardCard } from "@/components/card/DashboardCard";
import { SearchInput } from "@/components/input/SearchInput";
import Status from "@/components/tag/Status";
import { TableComponent } from "@/layouts/table/TableLayout";
import { useGetAllCustomersQuery } from "@/services/requests/dashboard";
import { Box, SimpleGrid, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import PersonIcon from "@/assets/icons/guliva_person.webp";
import CarIcon from "@/assets/icons/guliva_car.webp";
import LocationIcon from "@/assets/icons/guliva_location.webp";
import PageLoader from "@/components/loader/PageLoader";
import { useDispatch } from "react-redux";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import fileDownload from "@/utils/downloadFile";

const CustomersPage = () => {
	const { data, isLoading } = useGetAllCustomersQuery();

	const [searchTerm, setSearchTerm] = useState<string>("");

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeaderText("Customers"));
	}, []);

	const columns = [
		{
			Header: "Unique ID",
			accessor: "uniqueId",
		},
		{
			Header: "First Name",
			accessor: "firstName",
		},
		{
			Header: "Last Name",
			accessor: "lastName",
		},
		{
			Header: "Sex",
			accessor: "sex",
		},
		{
			Header: "Status",
			accessor: "status",
			Cell: ({ value }: { value: boolean }) => {
				return (
					<Box>
						<Status text={value === true ? "Active" : "Inactive"} />
					</Box>
				);
			},
		},
		{
			Header: "",
			accessor: "action",
			Cell: ({ value }: { value: string }) => {
				return (
					<Box>
						<Link to={`/dashboard/customers/${value}`}>
							<Text color="brand.red100" fontWeight="500">
								View details
							</Text>
						</Link>
					</Box>
				);
			},
		},
	];

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const downloadReport = () => {
		fileDownload("/member/report-customer", "customers.csv");
	};

	const filteredData = useMemo(() => {
		if (data) {
			const sortedData = data.data.filter(
				(item: any) =>
					item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.lastName.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, data]);

	const tableData = useMemo(
		() =>
			filteredData.map((item) => {
				return {
					uniqueId: item.id,
					firstName: item.firstName,
					lastName: item.lastName,
					sex: item.sex || "Male",
					status: item.isActive,
					action: item.id,
				};
			}),
		[filteredData]
	);

	return (
		<Box>
			<BreadcrumbLayout
				BreadcrumbLinkOne="Home"
				BreadcrumbLinkTwo="Customers"
			/>

			{isLoading ? (
				<SimpleGrid
					margin="1rem 0 0 0"
					columns={3}
					spacingX="2rem"
					spacingY="1rem"
				>
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
				</SimpleGrid>
			) : (
				<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
					<DashboardCard
						title="TOTAL NO. OF CUSTOMERS"
						icon={PersonIcon}
						value={data?.totalCustomers?.toString() || "0"}
					/>
					<DashboardCard
						title="TOTAL NO. OF TRIPS"
						icon={LocationIcon}
						value={data?.totalTrips?.toString() || "0"}
					/>
					<DashboardCard
						title="NO. OF VEHICLES REGISTERED"
						icon={CarIcon}
						value={data?.totalVehicle?.toString() || "0"}
					/>
				</SimpleGrid>
			)}

			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onClick={downloadReport}
					value={searchTerm}
					onChange={handleChange}
					text="DOWNLOAD REPORT"
					placeholder="Search..."
					w="48%"
				/>

				{isLoading ? (
					<PageLoader py="6rem" />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>
			<Outlet />
		</Box>
	);
};

export default CustomersPage;
