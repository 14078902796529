import { Box, Flex } from "@chakra-ui/react";
import { IProps } from "@/types/interfaces";
import { DashboardHeader } from "@/layouts/dashboard/DashboardHeader";
import { Sidebar } from "@/layouts/dashboard/Sidebar";

const DashboardLayout = ({ children }: IProps) => {
	return (
		<Box>
			<Flex w="100%" overflow="hidden">
				<Box>
					<Sidebar />
				</Box>
				<Box h="calc(100vh - 5rem)" w="100%">
					<DashboardHeader />
					<Box
						overflowY="scroll"
						py="2rem "
						px="2.5rem"
						bg="brand.white300"
						h="100%"
					>
						{children}
					</Box>
				</Box>
			</Flex>
		</Box>
	);
};

export { DashboardLayout };
