import { SearchInput } from "@/components/input/SearchInput";
import { useAppSelector } from "@/hooks/reduxHook";
import { Box } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import ChatList from "../chatbox/ChatList";

const AllMessages = () => {
	const { allChats } = useAppSelector((state) => state.chat);

	const [filteredInput, setFilteredInput] = useState("");

	const filteredData = useMemo(
		() =>
			allChats.filter(
				(chat) =>
					chat?.user?.firstName?.includes(filteredInput) ||
					chat?.user?.lastName?.includes(filteredInput) ||
					chat?.user?.email?.includes(filteredInput) ||
					chat?.adminMessage?.includes(filteredInput) ||
					chat?.message?.includes(filteredInput)
			),
		[filteredInput, allChats]
	);

	return (
		<Box h="100%">
			<SearchInput
				value={filteredInput}
				onChange={(e) => setFilteredInput(e.currentTarget.value)}
				noPad
				w="100%"
				maxW="360px"
				placeholder="Search..."
			/>
			<Box mt="1rem" h="calc(100% - 4rem)" overflowY="auto">
				<ChatList key={2} data={filteredData} />
			</Box>
		</Box>
	);
};

export default AllMessages;
