import {
	Box,
	Flex,
	FormErrorMessage,
	Heading,
	Image,
	Input,
	Text,
	useToast,
} from "@chakra-ui/react";
import Logo from "@/assets/images/guliva-logo.webp";
import HeroImg from "@/assets/images/hero-img.webp";
import { ComingSoonButton } from "@/components/button/ComingSoonButton";
import { ISubscribeUser } from "@/types/interfaces";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSubscribeUserMutation } from "@/services/requests/auth";
import { useEffect } from "react";

const Hero = () => {
	const toast = useToast();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<ISubscribeUser>();
	const [subscribeUser, { isLoading, data, isSuccess, isError }] =
		useSubscribeUserMutation();

	const onSubmit: SubmitHandler<ISubscribeUser> = async (
		data: ISubscribeUser
	) => {
		await subscribeUser(data);
		reset();
	};

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: data.message,
				status: "success",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			toast({
				title: "User already subscribed",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isError]);

	return (
		<Box
			w="100%"
			backgroundImage="linear-gradient(117deg, rgb(224, 251, 243) 0%, rgb(254, 247, 237) 84%, rgb(255, 249, 241) 100%)"
			// backgroundImage={`url(${gradient})`}
			backgroundRepeat="no-repeat"
		>
			<Box
				maxW="1200px"
				p={[
					"1rem 1rem 0",
					"2rem 2rem 0",
					"3rem 3rem 0",
					"3rem 3rem 0",
					"3rem 3rem 0",
				]}
				mx="auto"
				pos="relative"
			>
				<Box py="2rem">
					<Image
						w={["30%", "20%", "20%", "15%", "10%"]}
						mx="auto"
						src={Logo}
						alt="Guliva Logo"
					/>
				</Box>
				<Box>
					<Box textAlign="center" mt="1.5rem">
						<Heading
							fontFamily="love"
							fontWeight="400"
							fontSize={["2.9rem", "3.47rem", "3rem", "3.47rem"]}
							color="brand.dark200"
						>
							Driving Less? Then pay less
						</Heading>
						<Text
							w={["100%", "100%", "80%", "55%", "45%"]}
							mx="auto"
							color="brand.gray100"
							my="1rem"
							px={["2rem", "4.5rem", "0"]}
						>
							Join Nigeria’s first mobile insurance platform where you can buy
							insurance for both yourself and your vehicle only when you need
							it.
						</Text>
					</Box>

					<Flex
						onSubmit={handleSubmit(onSubmit)}
						as="form"
						flexDir={["column", "column", "column", "row", "row"]}
						w={["100%", "100%", "64%", "64%", "64%"]}
						align="center"
						mx="auto"
						my="1.6rem"
					>
						<Input
							id="email"
							border="1px solid #D9D9D9"
							borderRadius="5px"
							bg="brand.white100"
							placeholder="Enter your email address here"
							cursor="pointer"
							type="email"
							py="1.48rem"
							w={["100%", "100%", "100%", "40%", "40%"]}
							{...register("email", {
								required: "Please enter your email address",
								minLength: {
									value: 4,
									message: "Please enter a valid email",
								},
							})}
							_placeholder={{
								fontWeight: 400,
								fontSize: ".9rem",
								color: "brand.blue600",
							}}
							_focus={{
								borderColor: "brand.blue100",
								boxShadow: "none",
							}}
							_hover={{
								borderColor: "none",
							}}
						/>
						{errors.email && (
							<FormErrorMessage>{errors.email.message}</FormErrorMessage>
						)}
						<Input
							id="phoneNo"
							border="1px solid #D9D9D9"
							borderRadius="5px"
							bg="brand.white100"
							placeholder="Enter your phone number here"
							cursor="pointer"
							type="tel"
							py="1.48rem"
							mx=".6rem"
							my={["1rem", "1rem", "1rem", "0rem", "0rem"]}
							w={["100%", "100%", "100%", "38%", "38%"]}
							{...register("phoneNo", {
								required: "Please enter phone number",
								minLength: {
									value: 6,
									message: "Please enter a valid phone number",
								},
							})}
							_placeholder={{
								fontWeight: 400,
								fontSize: ".9rem",
								color: "brand.blue600",
							}}
							_focus={{
								borderColor: "brand.blue100",
								boxShadow: "none",
							}}
							_hover={{
								borderColor: "none",
							}}
						/>
						{errors.phoneNo && (
							<FormErrorMessage>{errors.phoneNo.message}</FormErrorMessage>
						)}
						<ComingSoonButton text="I’M INTERESTED" loading={isLoading} />
					</Flex>

					<Box
						w={["100%", "100%", "65%", "50%", "50%"]}
						mx="auto"
						pos="relative"
						bottom={["-2.5rem", "-2.5rem", "-3rem", "-3.5rem", "-3.5rem"]}
					>
						<Image src={HeroImg} alt="Hero Image" />
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export { Hero };
