import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const Team = () => {
	return (
		<Box>
			<Outlet />
		</Box>
	);
};

export default Team;
