import {
	Avatar,
	Box,
	Button,
	Flex,
	HStack,
	Image,
	Input,
	Text,
	useToast,
	VStack,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";

import { io } from "socket.io-client";
import { useAppSelector } from "@/hooks/reduxHook";

import Send from "@/assets/icons/guliva_send.webp";
import Bubble from "./Bubble";
import {
	useGetChatQuery,
	useGetUserQuery,
	useUnassignMutation,
} from "@/services/requests/dashboard";
import moment from "moment";
import PageLoader from "@/components/loader/PageLoader";
import { setMessages, updateMessages } from "@/redux/features/chat/chatSlice";
import { Messages } from "@/types/interfaces";
import { useDispatch } from "react-redux";
import useChatScroll from "@/hooks/useChatScroll";

const Chatbox = () => {
	const dispatch = useDispatch();

	const toast = useToast();

	const { selectedChat, messages } = useAppSelector((state) => state.chat);

	const { isLoading, data } = useGetChatQuery(
		selectedChat?.userId ? selectedChat?.userId?.toString() : ""
	);

	const [unassign, { isLoading: unassignLoading, isError, isSuccess }] =
		useUnassignMutation();

	const { data: user } = useGetUserQuery();

	const [message, setMessage] = React.useState("");

	const ref = useChatScroll(messages);

	const Socket = useMemo(() => io(process.env.REACT_APP_BASE_URL || ""), []);

	useEffect(() => {
		const messageList: Messages[] = [];
		data &&
			data?.length > 0 &&
			data?.forEach((chat) => {
				messageList.push({
					id: chat.id,
					message: chat.message || chat.adminMessage,
					userId: chat.userId,
					createdAt: chat.createdAt,
					type: chat.message ? "user" : "admin",
				});
			});
		dispatch(setMessages(messageList));
	}, [data]);

	function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		Socket.emit("message", {
			adminMessage: message,
			user: selectedChat?.userId,
			adminId: user?.id,
		});

		dispatch(
			updateMessages({
				id: 43443554545,
				message: message,
				userId: selectedChat?.userId || "",
				createdAt: new Date(),
				type: "admin",
			})
		);

		setMessage("");
	}

	useEffect(() => {
		Socket.on("event", (...args) => {
			dispatch(
				updateMessages({
					id: args[0].id,
					message: args[0].message,
					userId: args[0].userId,
					createdAt: args[0].createdAt,
					type: "user",
				})
			);
		});

		return () => {
			Socket.off("event");
		};
	}, []);

	async function handleUnassign() {
		await unassign({
			userId: selectedChat?.userId || "",
		});
	}

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: "Chat Unassigned",
				status: "success",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			toast({
				title: "An error occurred",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [isError]);

	return (
		<Box h="100%" w="100%">
			<Flex
				p="1rem 1.25rem"
				bg="brand.blue400"
				align="center"
				justify="space-between"
			>
				<HStack spacing={3}>
					<Avatar
						src={selectedChat?.image || ""}
						name={
							selectedChat?.user
								? `${selectedChat?.user?.firstName} ${selectedChat?.user?.lastName}`
								: ""
						}
						size="sm"
					/>
					<VStack spacing="0" align="self-start">
						<Text color="brand.blue200">
							{selectedChat?.user
								? `${selectedChat?.user?.firstName} ${selectedChat?.user?.lastName}`
								: ""}
						</Text>
					</VStack>
				</HStack>

				<HStack spacing="1rem">
					<Link to={`/dashboard/customers/${selectedChat?.userId}`}>
						<Text
							color="brand.red100"
							fontSize="sm"
							_hover={{ color: "brand.yellow500" }}
						>
							View Customer details
						</Text>
					</Link>

					{messages && messages.length > 0 && (
						<Button
							size="xs"
							bg="brand.blue100"
							color="brand.white100"
							_hover={{ bg: "brand.blue700" }}
							onClick={handleUnassign}
						>
							{unassignLoading ? "Unassigning..." : "Unassign"}
						</Button>
					)}
				</HStack>
			</Flex>
			<Box
				h="calc(100% - 8.5rem)"
				overflowY="auto"
				bg="brand.gray200"
				p="1.25rem 1rem"
				ref={ref}
			>
				{isLoading && <PageLoader />}
				{messages && messages.length > 0 ? (
					<>
						{messages?.map((chat) => (
							<Bubble
								key={chat?.id}
								side={chat?.type === "user" ? "left" : "right"}
								message={chat?.message || ""}
								date={moment(chat.createdAt).format("h:mm a")}
							/>
						))}
					</>
				) : (
					<Flex height="100%" width="100%" justify="center" align="center">
						<Text size="sm" textAlign="center">
							No Message to display.
						</Text>
					</Flex>
				)}
			</Box>
			{user?.id === selectedChat?.assignedTo?.id && (
				<form onSubmit={handleSubmit}>
					<Flex h="4.5rem" bg="brand.blue400" p="1rem 1.25rem">
						<Input
							value={message}
							onChange={(e) => setMessage(e.target.value)}
							size="md"
							bg="brand.white100"
							outline="none"
						/>
						<Button
							bg="brand.blue100"
							_hover={{ bg: "brand.blue200" }}
							color="brand.white100"
							type="submit"
						>
							<Image src={Send} alt="send" />
						</Button>
					</Flex>
				</form>
			)}
		</Box>
	);
};

export default Chatbox;
