import { TableComponent } from "@/layouts/table/TableLayout";
import { IUserDataProps } from "@/types/interfaces";
import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const Trips = ({ data }: IUserDataProps) => {
	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "date",
			},
			{
				Header: "Start Location",
				accessor: "startLocation",
			},
			{
				Header: "End Location",
				accessor: "endLocation",
			},
			{
				Header: "Type",
				accessor: "type",
			},
			{
				Header: "Distance Covered (Km)",
				accessor: "distanceCovered",
			},
			{
				Header: "Amount Billed",
				accessor: "amountBilled",
			},
			{
				Header: "",
				accessor: "action",
				Cell: () => {
					return (
						<Box>
							<Link to="##">
								<Text color="brand.red100" fontWeight="500">
									Download receipt
								</Text>
							</Link>
						</Box>
					);
				},
			},
		],
		[]
	);

	const tableData = useMemo(
		() =>
			data &&
			data?.data?.trips?.map((item: IUserDataProps) => ({
				date: moment(item.createdAt).format("Do MMM YYYY"),
				startLocation: item.startAddress,
				endLocation: item.destinaationAddress,
				type: item.insuranceType,
				distanceCovered: item.distanceCovered,
				amountBilled: item.insuranceCost,
				action: item.id,
			})),
		[data]
	);

	return (
		<Box>
			<TableComponent columns={columns} data={tableData} />
		</Box>
	);
};

export default Trips;
