import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { DashboardButton } from "@/components/button/DashboardButton";
import { DashboardCard } from "@/components/card/DashboardCard";
import PageLoader from "@/components/loader/PageLoader";
import CustomAccordionItem from "@/components/profile/AccordionItem";
import Status from "@/components/tag/Status";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useGetCustomerQuery } from "@/services/requests/dashboard";
import {
	Box,
	Flex,
	SimpleGrid,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Claims from "../tabContents/Claims";
import Odometer from "../tabContents/Odometer";
import Transactions from "../tabContents/Transactions";
import Trips from "../tabContents/Trips";
import Vehicles from "../tabContents/Vehicles";
import CarIcon from "@/assets/icons/guliva_car.webp";
import LocationIcon from "@/assets/icons/guliva_location.webp";
import WalletIcon from "@/assets/icons/guliva_wallet.webp";
import PointerIcon from "@/assets/icons/guliva_pointer.webp";
import { TabLists } from "@/utils/Customer";

const CustomerProfile = () => {
	const { profile } = useParams();
	const { isLoading, data } = useGetCustomerQuery(profile || "0");

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(
			setHeaderText(
				data ? data?.data?.firstName + " " + data?.data?.lastName : ""
			)
		);
	}, [data]);

	return (
		<Box>
			{isLoading ? (
				<PageLoader py="12rem" />
			) : (
				<>
					<Flex align="center" justify="space-between">
						<BreadcrumbLayout
							BreadcrumbLinkOne="Home"
							BreadcrumbLinkTwo="Customers"
							BreadcrumbLinkThree={
								data?.data?.firstName + " " + data?.data?.lastName
							}
						/>

						<DashboardButton text="CONTACT CUSTOMER" />
					</Flex>

					<Flex
						justify="space-between"
						border="1px solid"
						borderColor="brand.gray500"
						bg="brand.white100"
						borderRadius="5px"
						mt="1.8rem"
						px="1.5rem"
						py="2rem"
					>
						<Box w="40%">
							<SimpleGrid column={2} spacingX="4rem">
								<CustomAccordionItem
									title="Unique ID"
									value={data?.data?.id || ""}
								/>
								<CustomAccordionItem
									title="First Name"
									value={data?.data?.firstName || ""}
								/>
								<CustomAccordionItem
									title="Last Name"
									value={data?.data?.lastName || ""}
								/>
								<CustomAccordionItem
									title="Email Address"
									value={data?.data?.email || ""}
								/>
								<CustomAccordionItem
									title="Phone Number"
									value={data?.data?.phoneNo || ""}
								/>
								<CustomAccordionItem
									title="Sex"
									value={data?.data?.sex || ""}
								/>
								<CustomAccordionItem
									title="Date of Birth"
									value={moment(data?.data?.dob).format("Do MMM YYYY") || ""}
								/>
								<CustomAccordionItem
									title="Date registered"
									value={
										moment(data?.data?.createdAt).format("Do MMM YYYY") || ""
									}
								/>
								<CustomAccordionItem
									title="Status"
									value={
										data?.data?.isActive === true ? (
											<Status text="Active" />
										) : (
											<Status text="Inactive" /> || ""
										)
									}
								/>
							</SimpleGrid>
						</Box>
						<Box w="55%">
							<SimpleGrid columns={2} spacing="1rem">
								<DashboardCard
									title="NO. OF TRIPS"
									icon={LocationIcon}
									value={data?.totalTrips?.toString() || "0"}
								/>
								<DashboardCard
									title="DISTANCE COVERED"
									icon={CarIcon}
									value={data?.data?.totalDistance?.toString() || "0"}
								/>
								<DashboardCard
									title="COST OF TOTAL TRIPS"
									icon={CarIcon}
									value={data?.data?.totalCostTrips?.toString() || "0"}
								/>
								<DashboardCard
									title="WALLET BALANCE"
									icon={WalletIcon}
									value={data?.data?.walletBalance?.toString() || "0"}
								/>
								<DashboardCard
									title="PER TRIP INSURANCE PAID"
									icon={PointerIcon}
									value={data?.data?.perTRipInsurancePayed?.toString() || "0"}
								/>
								<DashboardCard
									title="THIRD PARTY INSURANCE PAID"
									icon={PointerIcon}
									value={data?.data?.thirdPartyInsurance?.toString() || "0"}
								/>
							</SimpleGrid>
						</Box>
					</Flex>
					<Flex
						justify="space-between"
						border="1px solid"
						borderColor="brand.gray500"
						bg="brand.white100"
						borderRadius="5px"
						px="1.5rem"
						py="1rem"
					>
						<Tabs w="100%">
							<TabList>
								{TabLists.map((tab) => (
									<Tab key={tab}>{tab}</Tab>
								))}
							</TabList>
							<TabPanels w="100%">
								<TabPanel>
									<Trips data={data} />
								</TabPanel>
								<TabPanel>
									<Claims data={data} />
								</TabPanel>
								<TabPanel>
									<Transactions data={data} />
								</TabPanel>
								<TabPanel>
									<Vehicles data={data} />
								</TabPanel>
								<TabPanel>
									<Odometer data={data} />
								</TabPanel>
							</TabPanels>
						</Tabs>
					</Flex>
				</>
			)}
		</Box>
	);
};

export default CustomerProfile;
