import { Box, Stack, useToast } from "@chakra-ui/react";

import { AuthHeaderLayout } from "@/layouts/auth/AuthHeaderLayout";
import { AuthCard } from "@/components/card/AuthCard";
import { AuthButton } from "@/components/button/AuthButton";
import { useEffect, useState } from "react";
import { useResendLinkMutation } from "@/services/requests/auth";

const ForgotPasswordLink = () => {
	const toast = useToast();
	const [email, setEmail] = useState<string>("");

	const [resendLink, { data, isLoading, isSuccess, isError }] =
		useResendLinkMutation();

	async function handleClick() {
		await resendLink({ email });
	}

	// assign email to state
	useEffect(() => {
		const email = localStorage.getItem("guliva-email");
		if (email) {
			setEmail(email);
		}
	}, []);

	// check if request is successful
	useEffect(() => {
		if (isSuccess) {
			toast({
				title: data.message,
				status: "success",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isSuccess]);

	// check if request fails
	useEffect(() => {
		if (isError) {
			toast({
				title: "Member not found",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isError]);
	return (
		<Box>
			<Stack spacing="0" bg="brand.white300">
				<AuthHeaderLayout />

				<AuthCard
					title="Reset Password Link Sent"
					description={`A link has been sent to ${email} to reset your
					password. Click the link to reset your password.`}
				>
					<AuthButton onClick={handleClick} text="RESEND" loading={isLoading} />
				</AuthCard>
			</Stack>
		</Box>
	);
};

export default ForgotPasswordLink;
