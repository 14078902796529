import { useEffect, useMemo, useState } from "react";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { DashboardCard } from "@/components/card/DashboardCard";
import { SearchInput } from "@/components/input/SearchInput";
import PageLoader from "@/components/loader/PageLoader";
import { TableComponent } from "@/layouts/table/TableLayout";
import { useGetAllPremiumsQuery } from "@/services/requests/dashboard";
import { Box, Button, SimpleGrid, Skeleton } from "@chakra-ui/react";
import CarIcon from "@/assets/icons/guliva_car.webp";
import PaymentIcon from "@/assets/icons/guliva_payment.webp";
import PersonIcon from "@/assets/icons/guliva_person.webp";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useDispatch } from "react-redux";
import { IAllPremiumDataProps } from "@/types/interfaces";
import fileDownload from "@/utils/downloadFile";

const PremiumsPage = () => {
	const { data, isLoading } = useGetAllPremiumsQuery();

	const [searchTerm, setSearchTerm] = useState<string>("");

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setHeaderText("Premiums"));
	}, []);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const filteredData = useMemo(() => {
		if (data) {
			const sortedData = data?.data?.filter(
				(item: any) =>
					item.user.firstName
						.toLowerCase()
						.includes(searchTerm.toLowerCase()) ||
					item.user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.PolicyId.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, data]);

	const downloadReport = () => {
		fileDownload("/member/report-premium", "premiums.csv");
	};

	const columns = useMemo(
		() => [
			{
				Header: "Unique ID",
				accessor: "uniqueId",
			},
			{
				Header: "Customer",
				accessor: "customer",
			},
			{
				Header: "Customer Type",
				accessor: "customerType",
			},
			{
				Header: "Policy Number",
				accessor: "policyNumber",
			},
			{
				Header: "Premium Paid",
				accessor: "premiumPayed",
			},
			{
				Header: "",
				accessor: "action",
				Cell: ({ value }: { value: string }) => {
					return (
						<Box>
							<Button bg="none" color="brand.red100" fontWeight="500">
								Download certificate
							</Button>
						</Box>
					);
				},
			},
		],
		[]
	);

	const tableData = useMemo(
		() =>
			filteredData.map((item: IAllPremiumDataProps) => {
				return {
					uniqueId: item.id,
					customer: item?.user?.firstName + " " + item?.user?.lastName,
					customerType: item.customerType || "N/A",
					policyNumber: item.PolicyId,
					premiumPayed: item.amount,
					action: item.id,
				};
			}),
		[filteredData]
	);

	return (
		<Box>
			<BreadcrumbLayout BreadcrumbLinkOne="Home" BreadcrumbLinkTwo="Premiums" />

			{isLoading ? (
				<SimpleGrid
					margin="1rem 0 0 0"
					columns={3}
					spacingX="2rem"
					spacingY="1rem"
				>
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
				</SimpleGrid>
			) : (
				<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
					<DashboardCard
						title="NO. OF PREMIUMS"
						icon={CarIcon}
						value={data?.totalPremiums?.toString() || "0"}
					/>
					<DashboardCard
						title="TOTAL PREMIUM PAYED"
						icon={PaymentIcon}
						value={data?.totalPremiumPayed?.toString() || "0"}
					/>
					<DashboardCard
						title="NO. OF CUSTOMERS"
						icon={PersonIcon}
						value={data?.totalCustomers?.toString() || "0"}
					/>
				</SimpleGrid>
			)}
			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onClick={downloadReport}
					onChange={handleChange}
					value={searchTerm}
					text="DOWNLOAD REPORT"
					placeholder="Search..."
					w="48%"
				/>

				{isLoading ? (
					<PageLoader py="6rem" />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>
		</Box>
	);
};

export default PremiumsPage;
