import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	Box,
	Text,
	Flex,
	Image,
	Stack,
} from "@chakra-ui/react";
import { FaqDetails } from "@/utils/Faq";
import Call from "@/assets/icons/telephone.webp";
import Email from "@/assets/icons/email.webp";

const Faq = () => {
	return (
		<Box bg="brand.white100" id="faq">
			<Stack
				direction={["column", "column", "column", "row", "row"]}
				align="flex-start"
				spacing="5rem"
				maxW="1200px"
				p={["4rem 1rem 6rem", "4rem 2rem 6rem", "4rem 3rem 6rem"]}
				mx="auto"
			>
				<Box maxW="400px">
					<Text fontWeight="700" fontSize="2.1rem" color="brand.dark200">
						Learn more about pay per trip insurance
					</Text>
					<Text
						color="brand.gray100"
						fontWeight="400"
						fontSize=".95rem"
						my="1rem"
					>
						For more information, please contact us:
					</Text>

					<Box>
						<Flex align="center">
							<Image h="15px" mr="1.5rem" src={Call} alt="Call Icon" />
							<a href="tel:+234 817 627 9182">
								<Text color="brand.gray100" fontWeight="400" fontSize=".95rem">
									+234 817 627 9182
								</Text>
							</a>
						</Flex>

						<Flex align="center" mt="1rem">
							<Image h="15px" mr="1.5rem" src={Email} alt="Call Icon" />
							<a href="tel:+234 817 627 9182">
								<Text color="brand.gray100" fontWeight="400" fontSize=".95rem">
									hello@guliva.com
								</Text>
							</a>
						</Flex>
					</Box>
				</Box>

				<Box w="100%">
					<Accordion allowToggle>
						{FaqDetails.map((item, idx) => {
							return (
								<AccordionItem
									key={idx}
									border="0.5px solid #A1ADBA"
									borderRadius="10px"
									bg="transparent"
									p=".4rem"
									mb=".7rem"
								>
									{({ isExpanded }) => (
										<>
											<h2>
												<AccordionButton
													_hover={{ bg: "none" }}
													_focus={{ boxShadow: "none" }}
												>
													<Box flex="1" textAlign="left">
														{item.title}
													</Box>
													{isExpanded ? (
														<MinusIcon color="brand.blue100" fontSize="12px" />
													) : (
														<AddIcon color="brand.blue100" fontSize="12px" />
													)}
												</AccordionButton>
											</h2>
											<AccordionPanel pb={4}>{item.description}</AccordionPanel>
										</>
									)}
								</AccordionItem>
							);
						})}
					</Accordion>
				</Box>
			</Stack>
		</Box>
	);
};

export { Faq };
