import { Button } from "@chakra-ui/react";
import { IAuthButtonProps } from "@/types/interfaces";

const AuthButton = ({
	text,
	loading = false,
	onClick,
	w = "100%",
	bg = "brand.blue100",
	bgHover = "brand.blue200",
}: IAuthButtonProps) => {
	return (
		<Button
			w={w}
			mt="1.6rem"
			py="1.45rem"
			color="#fff"
			bg={bg}
			borderRadius="5px"
			type="submit"
			cursor="pointer"
			fontSize="0.8rem"
			fontWeight="400"
			isLoading={loading}
			onClick={onClick}
			_hover={{
				bg: { bgHover },
			}}
			_focus={{
				borderColor: "none",
				boxShadow: "none",
			}}
		>
			{text}
		</Button>
	);
};

export { AuthButton };
