import { IModal, IUserDataProps } from "@/types/interfaces";
import {
	Box,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
} from "@chakra-ui/react";

const VehicleModal = ({ data, isOpen, onClose }: IModal) => {
	return (
		<Modal size="xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader color="brand.blue200">Vehicle details</ModalHeader>
				<ModalCloseButton />

				{data?.data?.Vehicles.map((item: IUserDataProps) => {
					return (
						<ModalBody key={item.id}>
							<Text color="brand.gray100">Name of vehicle</Text>
							<Text color="brand.dark100" fontSize="lg">
								{item.name}
							</Text>

							<SimpleGrid my={8} columns={3} spacingX={4} spacingY={4}>
								<Box>
									<Text color="brand.gray100">Model</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.model}
									</Text>
								</Box>
								<Box>
									<Text color="brand.gray100">Color</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.color}
									</Text>
								</Box>
								<Box>
									<Text color="brand.gray100">Year</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.year}
									</Text>
								</Box>
								<Box>
									<Text color="brand.gray100">License plate</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.model}
									</Text>
								</Box>
								<Box>
									<Text color="brand.gray100">Engine Number</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.engineNumber}
									</Text>
								</Box>
								<Box>
									<Text color="brand.gray100">Chasis Number</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.chassisNumber}
									</Text>
								</Box>
								<Box>
									<Text color="brand.gray100">Primary Driver</Text>
									<Text color="brand.dark100" fontSize="md">
										{item.model}
									</Text>
								</Box>
							</SimpleGrid>

							<Box>
								<Text fontSize="sm" color="brand.gray100">
									Photos attached
								</Text>
								<SimpleGrid columns={4} spacingX={4} mt={2}>
									<>
										<Image
											w="100%"
											h="auto"
											src={item.frontView}
											alt="vehicle"
										/>
										<Image
											w="100%"
											h="auto"
											src={item.sideView}
											alt="vehicle"
										/>
										<Image
											w="100%"
											h="auto"
											src={item.backView}
											alt="vehicle"
										/>
										<Image w="100%" h="auto" src={item.topView} alt="vehicle" />
									</>
								</SimpleGrid>
							</Box>

							<SimpleGrid my={8} columns={2} spacingX={4} spacingY={4}>
								<>
									<Box>
										<Text color="brand.gray100">
											Total Distance Covered (km)
										</Text>
										<Text color="brand.dark100" fontSize="md"></Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Amount Billed</Text>
										<Text color="brand.dark100" fontSize="md">
											{item.value}
										</Text>
									</Box>
								</>
							</SimpleGrid>
						</ModalBody>
					);
				})}
			</ModalContent>
		</Modal>
	);
};

export default VehicleModal;
