import { Box, VStack, Text, Flex, Image } from "@chakra-ui/react";
import Appstore from "@/assets/images/guliva_appstore.webp";
import Playstore from "@/assets/images/guliva_playstore.webp";
const DownloadBanner = () => {
	return (
		<Box bg="brand.blue500">
			<VStack
				justifyContent="center"
				spacing="5rem"
				maxW="1200px"
				px={["1rem", "2rem", "3rem"]}
				mx="auto"
				py="3rem"
			>
				<Box>
					<Text fontWeight="700" fontSize="2.1rem" color="brand.dark200">
						Insurance only when you need it.
					</Text>
					<Text
						color="brand.gray100"
						fontWeight="400"
						fontSize=".95rem"
						textAlign="center"
						m=" 0.4rem 0 1.2rem"
					>
						Download Guliva on your mobile phone and get started in 2 minutes!
					</Text>
					<Flex justify="center">
						<a href="##">
							<Image h="58px" mr=".5rem" src={Appstore} alt="App Store" />
						</a>
						<a href="##">
							<Image h="58px" ml=".5rem" src={Playstore} alt="Google Play" />
						</a>
					</Flex>
				</Box>
			</VStack>
		</Box>
	);
};

export { DownloadBanner };
