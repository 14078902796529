import { useSelector } from "react-redux";
import {
	Avatar,
	Box,
	Flex,
	HStack,
	MenuItem,
	Text,
	VStack,
} from "@chakra-ui/react";
import { MenuDropdown } from "@/components/dropdown/MenuDropdown";
import { RootState } from "@/redux/store/configureStore";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "@/services/requests/dashboard";
import { useMemo } from "react";

const DashboardHeader = () => {
	const navigate = useNavigate();
	const name = useSelector((state: RootState) => state?.dashboard?.headerText);
	const { data: user } = useGetUserQuery();

	const fullname = useMemo(
		() => (user?.firstName ? `${user?.firstName} ${user?.lastName}` : ""),
		[user]
	);

	function handleLogout() {
		localStorage.removeItem("guliva-token");
		localStorage.removeItem("guliva-email");
		navigate("/auth/sign-in");
	}

	return (
		<HStack
			w="100%"
			h="5rem"
			align="center"
			bg="brand.white"
			px="2.5rem"
			boxShadow="0px 2px 12px rgba(5, 57, 114, 0.06)"
		>
			<Flex w="50%" h="100%" align="center">
				<Text fontSize="1rem" fontWeight="600" color="brand.blue200">
					{name}
				</Text>
			</Flex>
			<Flex w="50%" h="100%" align="center" justify="flex-end">
				<HStack spacing={6} align="center">
					<HStack spacing={2}>
						<Avatar src={user?.image || ""} name={fullname} size="sm" />
						<VStack spacing="0" align="self-start">
							<Text color="brand.blue200">{fullname}</Text>
							<Text color="brand.gray100" fontSize=".9rem">
								{user?.role?.role}
							</Text>
						</VStack>
					</HStack>
					<Box>
						<MenuDropdown>
							<MenuItem color="brand.blue200">Profile</MenuItem>
							<MenuItem onClick={handleLogout} color="brand.red100">
								Logout
							</MenuItem>
						</MenuDropdown>
					</Box>
				</HStack>
			</Flex>
		</HStack>
	);
};

export { DashboardHeader };
