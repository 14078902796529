import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { AuthButton } from "@/components/button/AuthButton";
import PageLoader from "@/components/loader/PageLoader";
import CustomAccordionItem from "@/components/profile/AccordionItem";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import {
	useApproveClaimMutation,
	useGetClaimQuery,
} from "@/services/requests/dashboard";
import {
	Box,
	Flex,
	FormControl,
	FormLabel,
	Image,
	Input,
	SimpleGrid,
	Text,
	Textarea,
	useToast,
} from "@chakra-ui/react";
import moment from "moment";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

interface ClaimInputProps {
	id: string;
	adminMessage: string;
	amountPayable: number;
	status: string;
}

const ApproveClaim = () => {
	const { profile } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const { isLoading, data } = useGetClaimQuery(profile || "0");
	const [
		approveUser,
		{ isLoading: approveLoading, data: approveData, isSuccess, isError },
	] = useApproveClaimMutation();

	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<ClaimInputProps>();

	const onSubmit: SubmitHandler<ClaimInputProps> = async (
		data: ClaimInputProps
	) => {
		await approveUser({
			id: profile || "",
			amountPayable: data.amountPayable,
			adminMessage: data.adminMessage,
			status: "Approved",
		});
		reset();
	};

	useEffect(() => {
		dispatch(setHeaderText("Approve Claim"));
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: approveData?.message || "Claim Approved",
				status: "success",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
			navigate("/dashboard/claims");
		}

		if (isError) {
			toast({
				title: approveData?.message || "Not a new Claim",
				status: "error",
				duration: 3000,
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isSuccess, isError]);

	return (
		<Box>
			{isLoading ? (
				<PageLoader py="12rem" />
			) : (
				<>
					<BreadcrumbLayout
						BreadcrumbLinkOne="Home"
						BreadcrumbLinkTwo="Claims"
						BreadcrumbLinkThree={profile}
						BreadcrumbLinkFour="Approve Claim"
						isFourth
					/>
					<Flex
						justify="space-between"
						border="1px solid"
						borderColor="brand.gray500"
						bg="brand.white100"
						borderRadius="5px"
						mt="1.8rem"
					>
						<Box
							as="form"
							w="70%"
							onSubmit={handleSubmit(onSubmit)}
							borderRight="1px solid"
							borderColor="brand.gray500"
							py="2rem"
							px="1.5rem"
						>
							<FormControl my="1.6rem">
								<FormLabel
									fontWeight="400"
									color="brand.dark100"
									htmlFor="text"
								>
									Amount to be paid to Customer
								</FormLabel>
								<Input
									id="amountPayable"
									border="1px solid #D9D9D9"
									borderRadius="5px"
									cursor="pointer"
									placeholder="N"
									type="number"
									py="1.2rem"
									{...register("amountPayable", {
										required: "Please enter an amount",
										minLength: {
											value: 2,
											message: "Please enter a valid amount",
										},
									})}
									_placeholder={{
										fontWeight: 400,
										fontSize: ".9rem",
										color: "brand.dark100",
									}}
									_focus={{
										borderColor: "brand.blue100",
										boxShadow: "none",
									}}
									_hover={{
										borderColor: "none",
									}}
								/>
								{errors.amountPayable && (
									<Text color="brand.red100">
										{errors.amountPayable.message}
									</Text>
								)}
							</FormControl>

							<FormControl mt="1.6rem">
								<FormLabel
									fontWeight="400"
									color="brand.dark100"
									htmlFor="text"
								>
									Additonal notes (if any)
								</FormLabel>

								<Textarea
									border="1px solid #EAEAEA"
									borderRadius="5px"
									{...register("adminMessage", {
										// 	required: "Please enter text",
										// 	minLength: { value: 6, message: "Text is too short" },
									})}
									_placeholder={{
										fontWeight: 400,
										fontSize: ".9rem",
										color: "brand.dark100",
									}}
									_focus={{
										borderColor: "brand.blue100",
										boxShadow: "none",
									}}
									_hover={{
										borderColor: "none",
									}}
								/>
								{errors.adminMessage && (
									<Text color="brand.red100">
										{errors.adminMessage.message}
									</Text>
								)}
							</FormControl>

							<AuthButton text="APPROVE" w="45%" loading={approveLoading} />
						</Box>

						<Box py="2rem" px="1.5rem">
							<SimpleGrid column={2} spacingX="4rem">
								<CustomAccordionItem title="Claim ID" value={data?.id || ""} />
								<CustomAccordionItem
									title="Date"
									value={moment(data?.claimDate).format("Do MMM YYYY") || ""}
								/>
								<CustomAccordionItem
									title="Reason for claim"
									value={data?.typeOfIncident || ""}
								/>
								<CustomAccordionItem
									title="Details"
									value={data?.description || ""}
								/>
								<CustomAccordionItem
									title="Were you a driver or passenger?"
									value={data?.driverName || ""}
								/>
								<Flex>
									<Text fontSize="sm" color="brand.gray100" w="40%">
										Photos attached
									</Text>
									<Box w="100%">
										<>
											<Image
												// w="100%"
												h="auto"
												src={data?.user?.image}
												alt="vehicle"
											/>
											{/* <Image
												w="100%"
												h="auto"
												src={data?.sideView}
												alt="vehicle"
											/> */}
										</>
									</Box>
								</Flex>
							</SimpleGrid>
						</Box>
					</Flex>
				</>
			)}
		</Box>
	);
};

export default ApproveClaim;
