import { IPermissionSettings } from "@/types/interfaces";
import { Box } from "@chakra-ui/react";
import React from "react";

const Permissions = ({ children }: IPermissionSettings) => {
	return (
		<Box
			bg="brand.white300"
			mt="1.5rem"
			p="1rem"
			border="1px solid"
			borderColor="brand.gray500"
			borderRadius="5px"
			minH="300px"
			height="auto"
		>
			{children}
		</Box>
	);
};

export default Permissions;
