import { IMenuLinkProps } from "@/types/interfaces";
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { Fragment, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Dropdown = ({
	accessible,
	subRoutes,
	text,
	iconImg,
	iconImgTwo,
}: IMenuLinkProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const location = useLocation();

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};
	return (
		<Box>
			<Box fontSize="1rem" fontWeight="400">
				<HStack
					onClick={handleToggle}
					p={iconImg ? "1rem 2rem" : "1rem 4.7rem"}
					color="brand.blue300"
					opacity={accessible ? 1 : 0.4}
					pointerEvents={accessible ? "auto" : "none"}
					spacing={5}
					fontWeight="400"
					fontSize="1rem"
				>
					{iconImg ? (
						<Box
							// sx={{
							// 	"#svgIcon": {
							// 		fill: activeTab ? "brand.white100" : "brand.blue300",
							// 	},
							// }}
							dangerouslySetInnerHTML={{ __html: iconImg }}
						/>
					) : null}
					<Flex justify="space-between" align="center" w="100%">
						<Text>{text}</Text>
						{iconImg ? <Box as={iconImgTwo} /> : null}
					</Flex>
				</HStack>

				{subRoutes?.map((item, idx) => {
					const activeTab = location.pathname === item.path;
					return (
						<Fragment key={idx}>
							{isOpen ? (
								<Box color="brand.blue300">
									<NavLink to={item.accessible ? item.path : "#"}>
										<HStack
											p="1rem 4.5rem"
											borderLeft={activeTab ? "4px solid #fff" : "none"}
											bgColor={activeTab ? "brand.blue100" : "none"}
											color={activeTab ? "brand.white100" : "brand.blue300"}
											spacing={1}
											fontWeight="400"
											fontSize="1rem"
										>
											<Text>{item.text}</Text>
										</HStack>
									</NavLink>
								</Box>
							) : null}
						</Fragment>
					);
				})}
			</Box>
		</Box>
	);
};

export { Dropdown };
