import { IProps } from "@/types/interfaces";
import { Box } from "@chakra-ui/react";
import { MainFooter } from "./MainFooter";
import { MainHeader } from "./MainHeader";

const MainLayout = ({ children }: IProps) => {
	return (
		<Box>
			<MainHeader />
			<Box>{children}</Box>
			<MainFooter />
		</Box>
	);
};

export { MainLayout };
