import { Button } from "@chakra-ui/react";
import { IPermissionButtonProps } from "@/types/interfaces";

const PermissionButton = ({ text }: IPermissionButtonProps) => {
	return (
		<Button
			borderRadius="20px"
			fontWeight="400"
			fontSize=".85rem"
			h="1.7rem"
			w="8rem"
			bg="brand.blue400"
			border="0.2px solid"
			borderColor="brand.blue100"
			color="brand.blue100"
			_focus={{
				boxShadow: "none",
			}}
		>
			{text}
		</Button>
	);
};

export { PermissionButton };
