import PersonIcon from "@/assets/icons/guliva_person.webp";
import SecurityIcon from "@/assets/icons/guliva_security.webp";
import RolesIcon from "@/assets/icons/guliva_roles.webp";
import { IDashboardProps } from "@/types/interfaces";

const MembersDetailsOne: IDashboardProps[] = [
	{
		title: "TOTAL NO. OF MEMBERS",
		value: "12",
		icon: PersonIcon,
	},
	{
		title: "NO. OF ROLES",
		value: "6",
		icon: RolesIcon,
	},
	{
		title: "NO. OF PERMISSIONS",
		value: "14",
		icon: SecurityIcon,
	},
];

export { MembersDetailsOne };
