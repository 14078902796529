import { Box, Stack, useToast } from "@chakra-ui/react";

import { AuthHeaderLayout } from "@/layouts/auth/AuthHeaderLayout";
import { AuthCard } from "@/components/card/AuthCard";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthButton } from "@/components/button/AuthButton";
import { useForgetPasswordMutation } from "@/services/requests/auth";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { forgetValues } from "@/types/formInputTypes";
import { EmailAddressInput } from "@/components/input/EmailAddressInput";
import { forgetPasswordInputs } from "@/constant/formInputs";

const ForgotPassword = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<forgetValues>();

	const toast = useToast();
	const navigate = useNavigate();

	const [setForgetPassword, { data, isLoading, isSuccess, isError }] =
		useForgetPasswordMutation();

	const onSubmit: SubmitHandler<forgetValues> = async (data) => {
		localStorage.setItem("guliva-email", data.email);
		await setForgetPassword(data);
	};

	// check if request is successful
	useEffect(() => {
		if (isSuccess) {
			toast({
				title: data.message,
				status: "success",
				isClosable: true,
				position: "top-right",
			});
			navigate("/auth/forget-password-link");
		}
	}, [isSuccess]);

	// check if request fails
	useEffect(() => {
		if (isError) {
			toast({
				title: "Member not found",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isError]);

	return (
		<Box>
			<Stack bg="brand.white300" spacing="0">
				<AuthHeaderLayout />

				<AuthCard
					title="Forgot Pasword?"
					description="Don’t worry! It happens. Please enter the address associated with your account."
					btnText="SUBMIT"
				>
					<Box as="form" onSubmit={handleSubmit(onSubmit)}>
						{forgetPasswordInputs.map(
							({ label, type, placeholder, name, rules }) => {
								return (
									<EmailAddressInput
										key={name}
										type={type}
										label={label}
										placeholder={placeholder}
										name={name}
										id={name}
										register={register}
										rules={rules}
										errors={errors}
									/>
								);
							}
						)}

						<AuthButton text="SUBMIT" loading={isLoading} />
					</Box>
				</AuthCard>
			</Stack>
		</Box>
	);
};

export default ForgotPassword;
