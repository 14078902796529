import { TableComponent } from "@/layouts/table/TableLayout";
import { IUserDataProps } from "@/types/interfaces";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useMemo } from "react";
import VehicleModal from "../modals/VehicleModal";

const Vehicles = ({ data }: IUserDataProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: "Name",
				accessor: "name",
			},
			{
				Header: "Model",
				accessor: "model",
			},
			{
				Header: "Color",
				accessor: "color",
			},
			{
				Header: "Year",
				accessor: "year",
			},
			{
				Header: "License plate",
				accessor: "lincensePlate",
			},
			{
				Header: "Distance Covered (km)",
				accessor: "distanceCovered",
			},
			{
				Header: "",
				accessor: "action",
				Cell: () => {
					return (
						<Box onClick={() => onOpen()}>
							<Button bg="none" color="brand.red100" fontWeight="500">
								View details
							</Button>
						</Box>
					);
				},
			},
		],
		[]
	);

	const tableData = useMemo(
		() =>
			data &&
			data?.data?.Vehicles?.map((item: IUserDataProps) => {
				return {
					name: item.name,
					model: item.model,
					color: item.color,
					year: item.year,
					lincensePlate: item.registrationNumber,
					distanceCovered: item.distanceCovered,
					action: item.id,
				};
			}),
		[data]
	);

	return (
		<Box>
			<VehicleModal
				data={data}
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
			/>
			<TableComponent columns={columns} data={tableData} />
		</Box>
	);
};

export default Vehicles;
