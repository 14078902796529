import { IDashboardProps } from "@/types/interfaces";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

const DashboardCard = ({ title, value, icon, mt }: IDashboardProps) => {
	return (
		<Flex
			key={title}
			border="0.5px solid #D9D9D9"
			bg="brand.white100"
			// w="22rem"
			w="100%"
			h="6.2rem"
			mt={mt ? mt : "1.2rem"}
			boxShadow="0px 2px 12px rgba(5, 57, 114, 0.06)"
			p="1rem 1.4rem"
			align="center"
			justify="space-between"
		>
			<Box>
				<Text color="brand.gray100" fontSize=".9rem">
					{title}
				</Text>
				<Text
					mt=".5rem"
					color="brand.blue200"
					fontWeight="700"
					fontSize="1.2rem"
				>
					{value}
				</Text>
			</Box>
			<Box>
				<Image src={icon} alt="Card Icon" />
			</Box>
		</Flex>
	);
};

export { DashboardCard };
