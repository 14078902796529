import { ComingSoonButton } from "@/components/button/ComingSoonButton";
import { Box, VStack, Text, Flex, Input, useToast } from "@chakra-ui/react";
import { ISubscribeUser } from "@/types/interfaces";
import { SubmitHandler, useForm } from "react-hook-form";
import { useSubscribeUserMutation } from "@/services/requests/auth";
import { useEffect } from "react";

const EarlyAccessBanner = () => {
	const toast = useToast();
	const {
		register,
		handleSubmit,
		reset,
		// formState: { errors },
	} = useForm<ISubscribeUser>();
	const [subscribeUser, { isLoading, data, isSuccess, isError }] =
		useSubscribeUserMutation();

	const onSubmit: SubmitHandler<ISubscribeUser> = async (
		data: ISubscribeUser
	) => {
		await subscribeUser(data);
		reset();
	};

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: data.message,
				status: "success",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			toast({
				title: "User already subscribed",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isError]);

	return (
		<Box bg="brand.blue500">
			<VStack
				justifyContent="center"
				maxW="1200px"
				p={["2rem 1rem", "2rem", "3rem", "3rem", "3rem"]}
				mx="auto"
			>
				<Box textAlign="center" id="sub">
					<Text
						fontFamily="love"
						fontWeight="400"
						fontSize="2.08rem"
						color="brand.dark200"
					>
						Get early access to Guliva insurance!
					</Text>
					<Text
						color="brand.gray100"
						fontWeight="400"
						fontSize="1rem"
						m=" 0.4rem 0 1.2rem"
						px={["2rem", "2rem", "0", "0", "0"]}
					>
						Enter your email address below to get notified first as soon as we
						launch!
					</Text>
				</Box>

				<Flex
					onSubmit={handleSubmit(onSubmit)}
					as="form"
					flexDir={["column", "column", "column", "row", "row"]}
					w={["100%", "100%", "64%", "64%", "64%"]}
					align="center"
					mx="auto"
					my="1.6rem"
				>
					<Input
						id="email"
						border="1px solid #D9D9D9"
						borderRadius="5px"
						bg="brand.white100"
						placeholder="Enter your email address here"
						cursor="pointer"
						type="email"
						py="1.48rem"
						w={["100%", "100%", "100%", "40%", "40%"]}
						{...register("email", {
							required: "Please enter your email address",
							minLength: {
								value: 4,
								message: "Please enter a valid email",
							},
						})}
						_placeholder={{
							fontWeight: 400,
							fontSize: ".9rem",
							color: "brand.blue600",
						}}
						_focus={{
							borderColor: "brand.blue100",
							boxShadow: "none",
						}}
						_hover={{
							borderColor: "none",
						}}
					/>
					<Input
						id="tel"
						border="1px solid #D9D9D9"
						borderRadius="5px"
						bg="brand.white100"
						placeholder="Enter your phone number here"
						cursor="pointer"
						type="tel"
						py="1.48rem"
						mx=".6rem"
						my={["1rem", "1rem", "1rem", "0rem", "0rem"]}
						w={["100%", "100%", "100%", "38%", "38%"]}
						{...register("phoneNo", {
							required: "Please enter phone number",
							minLength: {
								value: 6,
								message: "Please enter a valid phone number",
							},
						})}
						_placeholder={{
							fontWeight: 400,
							fontSize: ".9rem",
							color: "brand.blue600",
						}}
						_focus={{
							borderColor: "brand.blue100",
							boxShadow: "none",
						}}
						_hover={{
							borderColor: "none",
						}}
					/>
					<ComingSoonButton text="I’M INTERESTED" loading={isLoading} />
				</Flex>
			</VStack>
		</Box>
	);
};

export { EarlyAccessBanner };
