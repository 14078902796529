import { Box, Heading, SimpleGrid, Skeleton } from "@chakra-ui/react";
import { DashboardCard } from "@/components/card/DashboardCard";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { useDispatch } from "react-redux";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useEffect } from "react";
import { useGetDashboardQuery } from "@/services/requests/dashboard";

import PersonIcon from "@/assets/icons/guliva_person.webp";
import CarIcon from "@/assets/icons/guliva_car.webp";
import LocationIcon from "@/assets/icons/guliva_location.webp";
import SecurityIcon from "@/assets/icons/guliva_security.webp";
import RolesIcon from "@/assets/icons/guliva_roles.webp";
import ClaimIcon from "@/assets/icons/guliva_claim.webp";
import CheckmarkIcon from "@/assets/icons/guliva_checkmark.webp";
import TimeIcon from "@/assets/icons/guliva_time.webp";
import PaymentIcon from "@/assets/icons/guliva_payment.webp";
import RefreshIcon from "@/assets/icons/guliva_refresh.webp";
import PointerIcon from "@/assets/icons/guliva_pointer.webp";

const DashboardPage = () => {
	const dispatch = useDispatch();
	const { data, isLoading } = useGetDashboardQuery();

	useEffect(() => {
		dispatch(setHeaderText("Dashboard"));
	}, []);
	return (
		<Box>
			<BreadcrumbLayout
				BreadcrumbLinkOne="Home"
				BreadcrumbLinkTwo="Dashboard"
			/>

			<Box mt="2rem">
				<Heading color="brand.blue200" fontSize="1.2rem" fontWeight="500">
					App Usage
				</Heading>

				{isLoading ? (
					<SimpleGrid
						margin="1rem 0 0 0"
						columns={3}
						spacingX="2rem"
						spacingY="1rem"
					>
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
						<DashboardCard
							title="TOTAL NO. OF CUSTOMERS"
							icon={PersonIcon}
							value={data?.totalCustomers?.toString() || "0"}
						/>
						<DashboardCard
							title="NO. OF POLICY"
							icon={CarIcon}
							value={data?.totalPremiums?.toString() || "0"}
						/>
						<DashboardCard
							title="NO. OF TRIPS"
							icon={LocationIcon}
							value={data?.totalTrips?.toString() || "0"}
						/>
						<DashboardCard
							title="TOTAL DISTANCE COVERED (KM)"
							icon={CarIcon}
							value={data?.totalDistance?.toFixed(2).toString() || "0"}
						/>
					</SimpleGrid>
				)}
			</Box>

			<Box mt="2rem">
				<Heading color="brand.blue200" fontSize="1.2rem" fontWeight="500">
					Guliva Team
				</Heading>

				{isLoading ? (
					<SimpleGrid
						margin="1rem 0 0 0"
						columns={3}
						spacingX="2rem"
						spacingY="1rem"
					>
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
						<DashboardCard
							title="TOTAL NO. OF MEMBERS"
							icon={PersonIcon}
							value={data?.totalMembers?.toString() || "0"}
						/>
						<DashboardCard
							title="NO. OF PERMISSIONS"
							icon={SecurityIcon}
							value={data?.totalPermissions?.toString() || "0"}
						/>
						<DashboardCard
							title="NO. OF ROLES"
							icon={RolesIcon}
							value={data?.totalRoles?.toString() || "0"}
						/>
					</SimpleGrid>
				)}
			</Box>

			<Box mt="2rem">
				<Heading color="brand.blue200" fontSize="1.2rem" fontWeight="500">
					Claims
				</Heading>

				{isLoading ? (
					<SimpleGrid
						margin="1rem 0 0 0"
						columns={3}
						spacingX="2rem"
						spacingY="1rem"
					>
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
						<DashboardCard
							title="TOTAL NO. OF CLAIMS"
							icon={ClaimIcon}
							value={data?.totalClaims?.toString() || "0"}
						/>
						<DashboardCard
							title="APPROVED"
							icon={CheckmarkIcon}
							value={data?.approvedClaims?.toString() || "0"}
						/>
						<DashboardCard
							title="PENDING"
							icon={TimeIcon}
							value={data?.pendingClaims?.toString() || "0"}
						/>
					</SimpleGrid>
				)}
			</Box>

			<Box mt="2rem">
				<Heading color="brand.blue200" fontSize="1.2rem" fontWeight="500">
					Amounts
				</Heading>

				{isLoading ? (
					<SimpleGrid
						margin="1rem 0 0 0"
						columns={3}
						spacingX="2rem"
						spacingY="1rem"
					>
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
						<DashboardCard
							title="TOTAL PREMIUM PAID"
							icon={PaymentIcon}
							value={
								data?.totalPremiumPayed
									? `₦${data?.totalPremiumPayed?.toString()}`
									: "₦0"
							}
						/>
						<DashboardCard
							title="TOTAL CLAIMS PAYED OUT"
							icon={RefreshIcon}
							value={
								data?.totalClaimsPayedOut
									? `₦${data?.totalClaimsPayedOut?.toString()}`
									: "₦0"
							}
						/>
						<DashboardCard
							title="PER TRIP INSURANCE PAID"
							icon={PointerIcon}
							value={
								data?.perTRipInsurancePayed
									? `₦${data?.perTRipInsurancePayed?.toFixed(2).toString()}`
									: "₦0"
							}
						/>
						<DashboardCard
							title="THIRD PARTY INSURANCE PAID"
							icon={PointerIcon}
							value={
								data?.thirdPartyInsurance
									? `₦${data?.thirdPartyInsurance?.toString()}`
									: "₦0"
							}
						/>
					</SimpleGrid>
				)}
			</Box>
		</Box>
	);
};

export default DashboardPage;
