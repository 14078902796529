import { TableComponent } from "@/layouts/table/TableLayout";
import { IUserDataProps } from "@/types/interfaces";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import { useMemo } from "react";
import OdometerModal from "../modals/OdometerModal";

const Odometer = ({ data }: IUserDataProps) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: "Month",
				accessor: "month",
			},
			{
				Header: "Vehicle",
				accessor: "vehicle",
			},
			{
				Header: "Odometer reading (km)",
				accessor: "odometerReading",
			},
			{
				Header: "",
				accessor: "action",
				Cell: () => {
					return (
						<Box onClick={() => onOpen()}>
							<Button bg="none" color="brand.red100" fontWeight="500">
								View details
							</Button>
						</Box>
					);
				},
			},
		],
		[]
	);

	const tableData = useMemo(
		() =>
			data &&
			data?.data?.odometer?.map((item: IUserDataProps) => {
				return {
					month: item.month,
					vehicle: item.vehicle,
					odometerReading: item.odometerReading,
					action: item.id,
				};
			}),
		[data]
	);

	return (
		<Box>
			<OdometerModal
				data={data}
				isOpen={isOpen}
				onClose={onClose}
				onOpen={onOpen}
			/>
			<TableComponent columns={columns} data={tableData} />
		</Box>
	);
};

export default Odometer;
