import { Box, Flex, HStack, Icon, Image, Text } from "@chakra-ui/react";
import gulivaLogo from "@/assets/images/guliva-logo.webp";
import {
	GrFacebookOption,
	GrLinkedin,
	GrTwitter,
	GrInstagram,
} from "react-icons/gr";
import { Link } from "react-router-dom";

const MainFooter = () => {
	return (
		<Box bg="brand.white100" id="contact">
			<Box
				maxW="1200px"
				mx="auto"
				p={["2rem 1rem", "2rem", "3rem", "3rem", "3rem"]}
			>
				<HStack
					flexDir={["column", "column", "column", "row", "row"]}
					spacing={["0", "0", "0", "4rem", "4rem"]}
					align={[
						"flex-start",
						"flex-start",
						"flex-start",
						"flex-start",
						"flex-start",
					]}
				>
					<Flex
						flexDir={["column", "column", "column", "row", "row"]}
						w={["100%", "100%", "100%", "38%", "60%"]}
						justify="space-between"
					>
						<Box maxW="200px" mx="auto">
							<Image
								mx={["auto", "auto", "auto", "0%", "0%"]}
								src={gulivaLogo}
								alt="Footer Logo"
							/>
							<Text
								mt=".5rem"
								fontWeight="400"
								fontSize=".95rem"
								color="brand.gray100"
								textAlign={["center", "center", "center", "left", "left"]}
							>
								Nigeria’s first pay per trip mobile insurance platform
							</Text>
							<Flex
								mt=".5rem"
								w={["60%", "60%", "60%", "100%", "100%"]}
								mx="auto"
								color="brand.gray100"
								justify="space-between"
								display={["flex", "flex", "flex", "none", "none"]}
							>
								<a href="##">
									<Icon as={GrFacebookOption} />
								</a>
								<a href="##">
									<Icon as={GrLinkedin} />
								</a>
								<a href="##">
									<Icon as={GrTwitter} />
								</a>
								<a href="##">
									<Icon as={GrInstagram} />
								</a>
							</Flex>
						</Box>

						<Box mt={["4rem", "1rem", "1rem", "0", ".3rem"]}>
							<Text fontWeight="700" fontSize=".95rem" color="brand.dark200">
								Contact
							</Text>
							<Box
								mt=".5rem"
								fontWeight="400"
								fontSize=".95rem"
								color="brand.gray100"
							>
								<a href="mailto:hello@guliva.com">
									<Text>hello@guliva.com</Text>
								</a>
								<a href="tel:+234 903 885 5061">
									<Text>+234 903 885 5061</Text>
								</a>
							</Box>
						</Box>
					</Flex>
					<Flex>
						<Box mt={["1rem", "1rem", "1rem", "0", "0"]} w={"max-content"}>
							<Text fontWeight="700" fontSize=".95rem" color="brand.dark200">
								Links
							</Text>
							<Box
								mt=".5rem"
								fontWeight="400"
								fontSize=".95rem"
								color="brand.gray100"
							>
								<Link to="/PrivacyPolicy">
									<Text>Privacy Policy</Text>
								</Link>
								<Link to="/TermsAndConditions">
									<Text>Terms and Conditions</Text>
								</Link>
							</Box>
						</Box>
					</Flex>
					<Flex
						flexDir={["column", "column", "column", "row", "row"]}
						w="43%"
						justify="space-between"
					>
						<Box maxW="340px" mt={["1rem", "1rem", "1rem", "0", "0"]}>
							<Text fontWeight="700" fontSize=".95rem" color="brand.dark200">
								Address
							</Text>
							<Box
								mt=".5rem"
								fontWeight="400"
								fontSize=".95rem"
								color="brand.gray100"
							>
								<Text>5A, Akanbi Disu Street,</Text>
								<Text>Lekki Phase 1,</Text>
								<Text>Lagos, Nigeria</Text>
							</Box>
						</Box>

						<Box
							w="25%"
							mt={["1rem", "1rem", "1rem", "0", "0"]}
							display={["none", "none", "none", "block", "block"]}
						>
							<Text fontWeight="700" fontSize=".95rem" color="brand.dark200">
								Social
							</Text>
							<Flex
								mt=".5rem"
								w="100%"
								color="brand.gray100"
								justify="space-between"
							>
								<a href="##">
									<Icon as={GrFacebookOption} />
								</a>
								<a href="##">
									<Icon as={GrLinkedin} />
								</a>
								<a href="##">
									<Icon as={GrTwitter} />
								</a>
								<a href="##">
									<Icon as={GrInstagram} />
								</a>
							</Flex>
						</Box>
					</Flex>
				</HStack>

				<Box>
					<Text
						mt="3rem"
						fontWeight="400"
						fontSize=".95rem"
						color="brand.gray100"
						textAlign="center"
					>
						Copyright {new Date().getFullYear()} - Guliva. All rights reserved.
					</Text>
				</Box>
			</Box>
		</Box>
	);
};

export { MainFooter };
