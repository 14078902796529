export const Definitions = [
	{
		name: "Account",
		def: "means a unique account created for You to access our Service or parts of our Service. ",
	},
	{
		name: "Affiliate ",
		def: "means an entity that controls, is controlled by or is under common control with a party, where control means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority. ",
	},
	{
		name: "Application ",
		def: "refers to Guliva Insurance, the software program provided by the Company.",
	},
	{
		name: "Company ",
		def: ` (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to First Guaranty Risk Solution Insurance Brokers, 5A, Akanbi Disu Street, Lekki Phase 1, Lagos, Nigeria.
        `,
	},
	{
		name: "Cookies ",
		def: "are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.",
	},
	{
		name: "Country",
		def: " refers to: Nigeria",
	},
	{
		name: "Device ",
		def: "means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
	},
	{
		name: "Personal Data ",
		def: " is any information that relates to an identified or identifiable individual.",
	},
	{
		name: "Service ",
		def: "refers to the Application or the Website or both. ",
	},
	{
		name: "Service Provider ",
		def: "means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.  ",
	},
	{
		name: "Usage Data ",
		def: "refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit            ",
	},
	{
		name: "Website ",
		def: "refers to Guliva Insurance, accessible from ",
		link: "https://guliva.io",
	},
	{
		name: "You ",
		def: "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.",
	},
];
export const PersonalData = [
	"Email address ",
	"First name and last name",
	"Phone number",
	"Address, State, Province, ZIP/Postal code, City",
	"Usage Data",
];

export const TrackingTechnologies = [
	{
		name: "Cookies or Browser Cookies.",
		def: "A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.",
	},
	{
		name: "Web Beacons. ",
		def: "Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).",
	},
];

export const PersistentCookies = [
	{
		name: "Necessary / Essential Cookies.",
		type: "Session Cookies",
		administered: "Us",
		purspose:
			"These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.",
	},
	{
		name: "Cookies Policy / Notice Acceptance Cookies.",
		type: "Persistent Cookies",
		administered: "Us",
		purspose:
			" These Cookies identify if users have accepted the use of cookies on the Website.",
	},
	{
		name: "Functionality Cookies.",
		type: "Persistent Cookies",
		administered: "Us",
		purspose:
			" These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.",
	},
];
export const UseOfPersonalData = [
	{
		name: "To provide and maintain our Service,",
		def: "including to monitor the usage of our Service.",
	},
	{
		name: "To manage Your Account. ",
		def: " to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.",
	},
	{
		name: "For the performance of a contract: ",
		def: " the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.",
	},
	{
		name: "To contact You:",
		def: " To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
	},
	{
		name: "To provide You. ",
		def: " with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.",
	},
	{
		name: "To manage Your requests:",
		def: " To attend and manage Your requests to Us.",
	},
	{
		name: "For business transfers:",
		def: " We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.",
	},
	{
		name: "For other purposes:",
		def: "  We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.",
	},
];
export const sharePersonalData = [
	{
		name: "With Service Providers:",
		def: "We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.		",
	},
	{
		name: "For business transfers:",
		def: " We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.",
	},
	{
		name: "With Affiliates:",
		def: " We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.		",
	},
	{
		name: "With business partners:",
		def: "  We may share Your information with Our business partners to offer You certain products, services or promotions.		",
	},
	{
		name: "With other users:",
		def: " when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.",
	},
	{
		name: "With Your consent:",
		def: "  We may disclose Your personal information for any other purpose with Your consent.		",
	},
];

export const LegalRequirement = [
	"Comply with a legal obligation ",
	"Protect and defend the rights or property of the Company",
	"Prevent or investigate possible wrongdoing in connection with the Service	",
	"Protect the personal safety of Users of the Service or the public	",
	"Protect against legal liability	",
];
