import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
	const token = localStorage.getItem("guliva-token");

	// if token is not present, redirect to login page
	if (token) {
		return <Outlet />;
	}
	return <Navigate to="/auth/sign-in" />;
};

export default ProtectedRoute;
