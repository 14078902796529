import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const CustomersHomepage = () => {
	return (
		<Box>
			<Outlet />
		</Box>
	);
};

export default CustomersHomepage;
