import {
	Box,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { SearchInputProps } from "@/types/interfaces";
import { DashboardButton } from "../button/DashboardButton";

const SearchInput = ({
	placeholder,
	w,
	maxW,
	text,
	noPad,
	value,
	linkTo,
	onChange,
	onClick,
}: SearchInputProps) => {
	return (
		<Box
			bg="brand.white100"
			border={!noPad ? "1px solid #E4E4E4" : "none"}
			borderRadius="5px"
			p={!noPad ? "2rem 1.5rem" : "0"}
		>
			<Flex>
				<InputGroup>
					<InputLeftElement
						fontSize="1.2rem"
						color="brand.gray400"
						mt=".3rem"
						cursor="pointer"
					>
						<FiSearch />
					</InputLeftElement>
					<Input
						border="1px solid #E4E4E4"
						placeholder={placeholder}
						w={w}
						maxW={maxW}
						h="3rem"
						value={value}
						onChange={onChange}
						// cursor="pointer"
						_focus={{
							border: "1px solid",
							borderColor: "brand.blue100",
							boxShadow: "none",
						}}
					/>
				</InputGroup>

				{text && text !== "" ? (
					<DashboardButton onClick={onClick} text={text} linkTo={linkTo} />
				) : null}
			</Flex>
		</Box>
	);
};

export { SearchInput };
