import { useRoutes } from "react-router-dom";
import { DashboardLayout } from "@/layouts/dashboard/DashboardLayout";
import ClaimsPage from "./claims";
import PremiumsPage from "./premiums";
import CustomersPage from "./customers";
import CustomerProfile from "./customers/profile";
import CustomersHomepage from "./customers/Customer";
import DashboardPage from "./Dashboard";
import Team from "./team";
import MembersHomepage from "./team/members/Members";
import MemberPage from "./team/members";
import MemberProfile from "./team/members/profile";
import RolePage from "./team/roles";
import PermissionPage from "./team/permissions";
import ActivityPage from "./team/activity";
import Help from "./help";
import RolesHomePage from "./team/roles/RoleHomePage";
import EditRole from "./team/roles/EditRole";
import ClaimHomepage from "./claims/Claim";
import ClaimProfile from "./claims/profile";
import ApproveClaim from "./claims/approve";
import DeclineClaim from "./claims/decline";
import EditMember from "./team/members/profile/EditMember";
import CreateRole from "./team/roles/CreateRole";
import CreateMember from "./team/members/profile/CreateMember";
import ResetDashboardPassword from "./team/members/profile/ResetDashboardPassword";

const Dashboard = () => {
	const element = useRoutes([
		{
			path: "claims/*",
			element: <ClaimHomepage />,
			children: [
				{
					path: "",
					element: <ClaimsPage />,
				},
				{
					path: ":profile",
					element: <ClaimProfile />,
				},
				{
					path: ":profile/approve",
					element: <ApproveClaim />,
				},
				{
					path: ":profile/decline",
					element: <DeclineClaim />,
				},
			],
		},
		{
			path: "premiums",
			element: <PremiumsPage />,
		},
		{
			path: "team",
			element: <Team />,
			children: [
				{
					path: "members/*",
					element: <MembersHomepage />,
					children: [
						{ path: "", element: <MemberPage /> },
						{ path: ":profile", element: <MemberProfile /> },
						{ path: "create", element: <CreateMember /> },
						{ path: ":profile/edit", element: <EditMember /> },
						{
							path: ":profile/reset-password",
							element: <ResetDashboardPassword />,
						},
					],
				},
				{
					path: "roles/*",
					element: <RolesHomePage />,
					children: [
						{ path: "", element: <RolePage /> },
						{ path: "create", element: <CreateRole /> },
						{ path: ":role/edit", element: <EditRole /> },
					],
				},
				{ path: "permissions", element: <PermissionPage /> },
				{ path: "activity", element: <ActivityPage /> },
			],
		},
		{
			path: "customers/*",
			element: <CustomersHomepage />,
			children: [
				{
					path: "",
					element: <CustomersPage />,
				},
				{
					path: ":profile",
					element: <CustomerProfile />,
				},
			],
		},
		{
			path: "help",
			element: <Help />,
		},
		{
			path: "/",
			element: <DashboardPage />,
		},
	]);

	return <DashboardLayout>{element}</DashboardLayout>;
};

export default Dashboard;
