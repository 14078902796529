export const headerData = [
	{
		title: "Why Guilva?",
		url: "#why",
	},
	{
		title: "Features",
		url: "#features",
	},
	{
		title: "Contact Us",
		url: "#contact",
	},
	{
		title: "FAQs",
		url: "#faq",
	},
];
