import { IBreadcrumbProps } from "@/types/interfaces";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";

const BreadcrumbLayout = ({
	BreadcrumbLinkOne,
	BreadcrumbLinkTwo,
	BreadcrumbLinkThree,
	BreadcrumbLinkFour,
	isThird,
	isFourth,
}: IBreadcrumbProps) => {
	return (
		<Breadcrumb fontWeight="400" fontSize="1rem">
			<BreadcrumbItem>
				<BreadcrumbLink color="brand.red100" href="#">
					{BreadcrumbLinkOne}
				</BreadcrumbLink>
			</BreadcrumbItem>

			<BreadcrumbItem>
				<BreadcrumbLink color="brand.red100" href="#">
					{BreadcrumbLinkTwo}
				</BreadcrumbLink>
			</BreadcrumbItem>

			<BreadcrumbItem>
				<BreadcrumbLink
					color={isThird ? "brand.gray100" : "brand.red100"}
					href="#"
				>
					{BreadcrumbLinkThree}
				</BreadcrumbLink>
			</BreadcrumbItem>

			{isFourth ? (
				<BreadcrumbItem isCurrentPage>
					<BreadcrumbLink color="brand.gray100" href="#">
						{BreadcrumbLinkFour}
					</BreadcrumbLink>
				</BreadcrumbItem>
			) : null}
		</Breadcrumb>
	);
};

export { BreadcrumbLayout };
