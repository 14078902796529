/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Definitions, LegalRequirement } from "./privacyData";
import { PersonalData } from "./privacyData";
import { TrackingTechnologies } from "./privacyData";
import { PersistentCookies } from "./privacyData";
import { UseOfPersonalData } from "./privacyData";
import { sharePersonalData } from "./privacyData";
// import { LegalRequirement } from "./privacyData";
const Privacy = () => {
	return (
		<div>
			<Box bg="brand.gray200" id="why">
				<Box
					mt="2rem"
					maxW="1200px"
					p={["4rem 1rem 6rem", "4rem 2rem 6rem", "4rem 3rem 6rem"]}
					mx="auto"
				>
					<Text
						fontWeight="700"
						fontSize="3rem"
						textAlign="center"
						color="brand.gray100"
						mx={10}
					>
						Privacy Policy for Guliva Insurance Privacy Policy
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="center"
						color="brand.dark200"
						mx={10}
					>
						Last updated: November 22, 2023
					</Text>
					<Text
						fontWeight="400"
						fontSize="1.1rem"
						textAlign="left"
						color="brand.dark300"
						mt={10}
					>
						This Privacy Policy describes Our policies and procedures on the
						collection, use and disclosure of Your information when You use the
						Service and tells You about Your privacy rights and how the law
						protects You.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1.1rem"
						textAlign="left"
						color="brand.dark300"
						mt={5}
					>
						We use Your Personal data to provide and improve the Service. By
						using the Service, You agree to the collection and use of
						information in accordance with this Privacy Policy. This Privacy
						Policy has been created with the help of the Free Privacy Policy
						Generator.
					</Text>

					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Definitions
					</Text>
					<h1>For the purposes of this Privacy Policy:</h1>
					<div>
						<ul>
							{Definitions.map((def, i) => (
								<li key={i}>
									{" "}
									<Text
										fontWeight="700"
										fontSize="1.3rem"
										textAlign="left"
										color="black"
										mt={5}
									>
										{def.name}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										{def.def}
										<a href="/">{def?.link}</a>
									</Text>
								</li>
							))}
						</ul>
					</div>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Collecting and Using Your Personal Data
					</Text>
					<Text
						fontWeight="700"
						fontSize="1.5rem"
						textAlign="left"
						color="black"
						mt={5}
					>
						Types of Data Collected Personal Data
					</Text>

					<Text
						fontWeight="600"
						fontSize="1.3rem"
						textAlign="left"
						color="black"
						mt={4}
					>
						Personal Data
					</Text>
					<div>
						<ul>
							{PersonalData.map((data, i) => (
								<li key={i}>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										{data}
									</Text>
								</li>
							))}
						</ul>
					</div>
					<Text
						fontWeight="600"
						fontSize="1.3rem"
						textAlign="left"
						color="black"
						mt={4}
					>
						Usage Data
					</Text>

					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={0}
					>
						Usage Data is collected automatically when using the Service.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						Usage Data may include information such as Your Device&apos;s
						Internet Protocol address (e.g. IP address), browser type, browser
						version, the pages of our Service that You visit, the time and date
						of Your visit, the time spent on those pages, unique device
						identifiers and other diagnostic data.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						When You access the Service by or through a mobile device, We may
						collect certain information automatically, including, but not
						limited to, the type of mobile device You use, Your mobile device
						unique ID, the IP address of Your mobile device, Your mobile
						operating system, the type of mobile Internet browser You use,
						unique device identifiers and other diagnostic data.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						We may also collect information that Your browser sends whenever You
						visit our Service or when You access the Service by or through a
						mobile device.
					</Text>

					<Text
						fontWeight="600"
						fontSize="1.3rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						Information Collected while Using the Application
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						While using Our Application, in order to provide features of Our
						Application, We may collect, with Your prior permission:
					</Text>
					<div>
						<ul>
							<li>Information regarding your location</li>
							<li>
								Pictures and other information from your Device&apos;s camera
								and photo library
							</li>
						</ul>
					</div>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						We use this information to provide features of Our Service, to
						improve and customize Our Service. The information may be uploaded
						to the Company&apos;s servers and/or a Service Provider&apos;s
						server or it may be simply stored on Your device.
					</Text>
					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						Tracking Technologies and Cookies
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={3}
					>
						We use Cookies and similar tracking technologies to track the
						activity on Our Service and store certain information. Tracking
						technologies used are beacons, tags, and scripts to collect and
						track information and to improve and analyze Our Service. The
						technologies We use may include:
					</Text>
					<div>
						<ul>
							{TrackingTechnologies.map((def, i) => (
								<li key={i}>
									{" "}
									<Text
										fontWeight="700"
										fontSize="1.3rem"
										textAlign="left"
										color="black"
										mt={5}
									>
										{def.name}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										{def.def}
									</Text>
								</li>
							))}
						</ul>
					</div>
					<Text
						fontWeight="400"
						fontSize="1.1rem"
						textAlign="left"
						color="brand.dark300"
						mt={2}
					>
						Cookies can be Persistent or Session Cookies. Persistent Cookies
						remain on Your personal computer or mobile device when You go
						offline, while Session Cookies are deleted as soon as You close Your
						web browser. Learn more about cookies on the Free Privacy Policy
						website article.
					</Text>

					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						We use both Session and Persistent Cookies for the purposes set out
						below:
					</Text>
					<div>
						<ul>
							{PersistentCookies.map((def, i) => (
								<li key={i}>
									{" "}
									<Text
										fontWeight="700"
										fontSize="1.3rem"
										textAlign="left"
										color="black"
										mt={5}
									>
										{def.name}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										type: {def.type}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										administered: {def.administered}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										purpose: {def.purspose}
									</Text>
								</li>
							))}
						</ul>
					</div>
					<Text
						fontWeight="600"
						fontSize="1.3rem"
						textAlign="left"
						color="brand.dark300"
						mt={4}
					>
						We use both Session and Persistent Cookies for the purposes set out
						below:
					</Text>
					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						Use of Your Personal Data
					</Text>
					<Text
						fontWeight="600"
						fontSize="1.2rem"
						textAlign="left"
						color="brand.dark300"
						mt={4}
					>
						The Company may use Personal Data for the following purposes:
					</Text>
					<div>
						<ul>
							{UseOfPersonalData.map((personalData, i) => (
								<li key={i}>
									<Text
										fontWeight="700"
										fontSize="1.3rem"
										textAlign="left"
										color="black"
										mt={5}
									>
										{personalData.name}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										{personalData.def}
									</Text>
								</li>
							))}
						</ul>
					</div>
					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						We use both Session and Persistent Cookies for the purposes set out
						below:
					</Text>
					<div>
						<ul>
							{sharePersonalData.map((personalData, i) => (
								<li key={i}>
									<Text
										fontWeight="700"
										fontSize="1.3rem"
										textAlign="left"
										color="black"
										mt={5}
									>
										{personalData.name}
									</Text>
									<Text
										fontWeight="400"
										fontSize="1rem"
										textAlign="left"
										color="brand.dark300"
										mt={0}
									>
										{personalData.def}
									</Text>
								</li>
							))}
						</ul>
					</div>
					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						Retention of Your Personal Data
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={0}
					>
						The Company will retain Your Personal Data only for as long as is
						necessary for the purposes set out in this Privacy Policy. We will
						retain and use Your Personal Data to the extent necessary to comply
						with our legal obligations (for example, if we are required to
						retain your data to comply with applicable laws), resolve disputes,
						and enforce our legal agreements and policies.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={2}
					>
						The Company will also retain Usage Data for internal analysis
						purposes. Usage Data is generally retained for a shorter period of
						time, except when this data is used to strengthen the security or to
						improve the functionality of Our Service, or We are legally
						obligated to retain this data for longer time periods.
					</Text>
					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						Transfer of Your Personal Data
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Your information, including Personal Data, is processed at the
						Company&apos;s operating offices and in any other places where the
						parties involved in the processing are located. It means that this
						information may be transferred to — and maintained on — computers
						located outside of Your state, province, country or other
						governmental jurisdiction where the data protection laws may differ
						than those from Your jurisdiction.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Your consent to this Privacy Policy followed by Your submission of
						such information represents Your agreement to that transfer.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						The Company will take all steps reasonably necessary to ensure that
						Your data is treated securely and in accordance with this Privacy
						Policy and no transfer of Your Personal Data will take place to an
						organization or a country unless there are adequate controls in
						place including the security of Your data and other personal
						information.
					</Text>
					<Text
						fontWeight="600"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={4}
					>
						Delete Your Personal Data
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						You have the right to delete or request that We assist in deleting
						the Personal Data that We have collected about You.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Our Service may give You the ability to delete certain information
						about You from within the Service.
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						You may update, amend, or delete Your information at any time by
						signing in to Your Account, if you have one, and visiting the
						account settings section that allows you to manage Your personal
						information. You may also contact Us to request access to, correct,
						or delete any personal information that You have provided to Us.
					</Text>

					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Please note, however, that We may need to retain certain information
						when we have a legal obligation or lawful basis to do so.
					</Text>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Disclosure of Your Personal Data
					</Text>
					<Text
						fontWeight="700"
						fontSize="1.5rem"
						textAlign="left"
						color="black"
						mt={5}
					>
						Business Transactions{" "}
					</Text>

					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						If the Company is involved in a merger, acquisition or asset sale,
						Your Personal Data may be transferred. We will provide notice before
						Your Personal Data is transferred and becomes subject to a different
						Privacy Policy.
					</Text>
					<Text
						fontWeight="700"
						fontSize="1.5rem"
						textAlign="left"
						color="black"
						mt={5}
					>
						Law enforcement{" "}
					</Text>

					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Under certain circumstances, the Company may be required to disclose
						Your Personal Data if required to do so by law or in response to
						valid requests by public authorities (e.g. a court or a government
						agency).
					</Text>
					<Text
						fontWeight="700"
						fontSize="1.5rem"
						textAlign="left"
						color="black"
						mt={5}
					>
						Other legal requirements{" "}
					</Text>

					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						The Company may disclose Your Personal Data in the good faith belief
						that such action is necessary to:
					</Text>
					<ul>
						{LegalRequirement.map((legal, i) => (
							<li key={i}>{legal}</li>
						))}
					</ul>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Security of Your Personal Data
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						The security of Your Personal Data is important to Us, but remember
						that no method of transmission over the Internet, or method of
						electronic storage is 100% secure. While We strive to use
						commercially acceptable means to protect Your Personal Data, We
						cannot guarantee its absolute security.
					</Text>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Children&apos;s Privacy
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Our Service does not address anyone under the age of 13. We do not
						knowingly collect personally identifiable information from anyone
						under the age of 13. If You are a parent or guardian and You are
						aware that Your child has provided Us with Personal Data, please
						contact Us. If We become aware that We have collected Personal Data
						from anyone under the age of 13 without verification of parental
						consent, We take steps to remove that information from Our servers.{" "}
						<br />
						<br />
						If We need to rely on consent as a legal basis for processing Your
						information and Your country requires consent from a parent, We may
						require Your parent&apos;s consent before We collect and use that
						information.
					</Text>

					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Children&apos;s Privacy
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Our Service does not address anyone under the age of 13. We do not
						knowingly collect personally identifiable information from anyone
						under the age of 13. If You are a parent or guardian and You are
						aware that Your child has provided Us with Personal Data, please
						contact Us. If We become aware that We have collected Personal Data
						from anyone under the age of 13 without verification of parental
						consent, We take steps to remove that information from Our servers.{" "}
						<br />
						If We need to rely on consent as a legal basis for processing Your
						information and Your country requires consent from a parent, We may
						require Your parent&apos;s consent before We collect and use that
						information.
					</Text>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Links to Other Websites
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						Our Service may contain links to other websites that are not
						operated by Us. If You click on a third party link, You will be
						directed to that third party&apos;s site. We strongly advise You to
						review the Privacy Policy of every site You visit. .
						<br />
						We have no control over and assume no responsibility for the
						content, privacy policies or practices of any third party sites or
						services.
					</Text>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Changes to this Privacy Policy
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						We may update Our Privacy Policy from time to time. We will notify
						You of any changes by posting the new Privacy Policy on this page.
						<br />
						We will let You know via email and/or a prominent notice on Our
						Service, prior to the change becoming effective and update the Last
						updated date at the top of this Privacy Policy. <br />
						You are advised to review this Privacy Policy periodically for any
						changes. Changes to this Privacy Policy are effective when they are
						posted on this page.
					</Text>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Changes to this Privacy Policy
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						We may update Our Privacy Policy from time to time. We will notify
						You of any changes by posting the new Privacy Policy on this page.
						<br />
						We will let You know via email and/or a prominent notice on Our
						Service, prior to the change becoming effective and update the Last
						updated date at the top of this Privacy Policy. <br />
						You are advised to review this Privacy Policy periodically for any
						changes. Changes to this Privacy Policy are effective when they are
						posted on this page.
					</Text>
					<Text
						fontWeight="700"
						fontSize="2rem"
						textAlign="left"
						color="brand.red100"
						mt={5}
					>
						Contact Us
					</Text>
					<Text
						fontWeight="400"
						fontSize="1rem"
						textAlign="left"
						color="brand.dark300"
						mt={1}
					>
						If you have any questions about this Privacy Policy, You can contact
						us:
					</Text>
					<ul>
						<li>
							By email:{" "}
							<a className="gulired" href="mailto:hello@guliva.io">
								{" "}
								hello@guliva.io
							</a>{" "}
						</li>
						<li>
							By visiting this page on our website:{" "}
							<a
								className="gulired"
								href="https://guliva.io/#contact
"
							>
								https://guliva.io/#contact{" "}
							</a>{" "}
						</li>
						<li>
							By phone number:{" "}
							<a
								className="gulired"
								href="https://guliva.io/#contact
"
							>
								+234 903 885 5061
							</a>{" "}
						</li>
					</ul>
				</Box>
			</Box>
		</div>
	);
};

export default Privacy;
