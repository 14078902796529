import { Box, HStack, Image } from "@chakra-ui/react";
import { MenuLink } from "@/components/sidebar/MenuLinks";
import { DashboardNavigation } from "@/utils/DashboardRoutes";
import DashboardLogo from "@/assets/images/guliva_dashboard-logo-two.webp";
// import { useState } from "react";

const Sidebar = () => {
	// const [openTab, setOpenTab] = useState(DashboardNavigation[2].path);

	// const handleTabChange = (path: string) => {
	// 	setOpenTab(path);
	// 	console.log(path);
	// };

	return (
		<HStack>
			<Box h="100vh" w="17.5rem" bg="brand.gray300">
				<Box mt="2rem">
					<Image m="auto" src={DashboardLogo} alt="Dashboard Logo" />
				</Box>
				<Box mt="2rem">
					{DashboardNavigation.map((navigation, idx) => {
						return (
							<MenuLink
								key={idx}
								// openTab={openTab}
								// handleTabChange={(path: string) => handleTabChange(path)}
								{...navigation}
							/>
						);
					})}
				</Box>
			</Box>
		</HStack>
	);
};

export { Sidebar };
