import React from "react";
import { Hero } from "@/components/home/Hero";

import { TripInsurance } from "@/components/home/TripInsurance";

import { MainLayout } from "@/layouts/home/MainLayout";

import Privacy from "@/components/privacypolicy/privacy";

const PrivacyPolicy = () => {
	return (
		<div>
			<MainLayout>
				{/* <Hero />
				<TripInsurance /> */}
				<Privacy />
				{/* <Payless />
				<Claim />
				<Why />
				<Faq />
				<DownloadBanner /> */}
			</MainLayout>
		</div>
	);
};

export default PrivacyPolicy;
