/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
	Box,
	Button,
	HStack,
	SimpleGrid,
	Skeleton,
	useToast,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MembersDetailsOne } from "@/utils/Dashboard";
import { IDashboardProps, rolePageProps } from "@/types/interfaces";
import { DashboardCard } from "@/components/card/DashboardCard";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { TableComponent } from "@/layouts/table/TableLayout";
import { SearchInput } from "@/components/input/SearchInput";
import { PermissionButton } from "@/components/button/PermissionButton";
import { ActionModal } from "@/components/modal/Modal";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import {
	useDeleteRoleMutation,
	useGetAllRolesQuery,
} from "@/services/requests/dashboard";
import moment from "moment";
import PageLoader from "@/components/loader/PageLoader";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import useUserPermission from "@/hooks/useUserPermission";

const Rolepage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const toast = useToast();

	const permissionsAccess = useUserPermission();
	const [modal, setModal] = useState(false);
	const [deleteValue, setDeleteValue] = useState({ id: "", name: "" });
	const [searchTerm, setSearchTerm] = useState<string>("");

	const { data: rolesData, isLoading: rolesLoading } = useGetAllRolesQuery();
	const [
		deleteUser,
		{
			isSuccess: deleteSuccess,
			isLoading: deleteLoading,
			data: deleteData,
			isError: deleteError,
			error: deleteErrorMessage,
		},
	] = useDeleteRoleMutation();

	useEffect(() => {
		dispatch(setHeaderText("Roles"));
	}, []);

	const handleModal = (value: string, name: string) => {
		setDeleteValue({ id: value, name });
		setModal(true);
	};

	async function deleteRole() {
		await deleteUser({ id: deleteValue?.id || "" });
	}

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const filteredData = useMemo(() => {
		if (rolesData) {
			const sortedData = rolesData?.data?.filter((item: any) =>
				item.role.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, rolesData]);

	const columns = [
		{
			Header: "Role",
			accessor: "role",
		},
		{
			Header: "No. of Members",
			accessor: "membersNumber",
		},

		{
			Header: "Permissions",
			accessor: "permissions",

			Cell: ({ row }: rolePageProps) => {
				// Cell: ({ row, value }: Permission) => {
				return (
					<Box>
						<SimpleGrid columns={2} spacingY="1rem">
							{row.original.permissions.map(
								(permission: {
									id: number;
									name: string;
									createdBy: string;
									createdAt: string;
								}) => (
									<PermissionButton
										key={permission.id}
										text={permission.name}
									/>
								)
							)}
						</SimpleGrid>
					</Box>
				);
			},
		},
		{
			Header: "Date Added",
			accessor: "date",
		},
		{
			Header: "",
			accessor: "action",
			Cell: ({
				value,
				row,
			}: {
				value: string;
				row: Record<string, Record<string, string>>;
			}) => {
				return (
					<HStack>
						{permissionsAccess?.updateRole && (
							<Link to={`/dashboard/team/roles/${value}/edit`}>
								<Button
									bg="none"
									color="brand.red200"
									fontWeight="500"
									// onClick={() => alert(row.original.role)}
									_focus={{
										border: "none",
										boxShadow: "none",
									}}
									_hover={{
										bg: "transparent",
									}}
									_active={{
										bg: "transparent",
									}}
								>
									Edit
								</Button>
							</Link>
						)}

						{permissionsAccess?.deleteRole && (
							<Button
								bg="none"
								color="brand.red200"
								fontWeight="500"
								onClick={() => handleModal(`${value}`, `${row.original.role}`)}
								_focus={{
									border: "none",
									boxShadow: "none",
								}}
								_hover={{
									bg: "transparent",
								}}
								_active={{
									bg: "transparent",
								}}
							>
								Delete
							</Button>
						)}
					</HStack>
				);
			},
		},
	];

	const tableData = filteredData?.map((item) => ({
		role: item.role,
		permissions: item.permissions,
		membersNumber: item?.members?.length || 0,
		date: moment(item.createdAt).format("Do MMM YYYY"),
		action: item.id,
	}));

	useEffect(() => {
		if (deleteSuccess) {
			toast({
				title: deleteData.message || "Role Deleted Successfully",
				status: "success",
				duration: 3000,
				isClosable: true,
			});
			navigate("/dashboard/team/roles");
		}

		setModal(false);
	}, [deleteSuccess]);

	useEffect(() => {
		if (deleteError) {
			toast({
				// @ts-ignore
				title: deleteErrorMessage?.data?.message || "Role not Found.",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
		}
	}, [deleteError]);

	return (
		<Box>
			<BreadcrumbLayout BreadcrumbLinkOne="Home" BreadcrumbLinkTwo="Roles" />

			<Box mt="2rem">
				{rolesLoading ? (
					<SimpleGrid
						margin="1rem 0 0 0"
						columns={3}
						spacingX="2rem"
						spacingY="1rem"
					>
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
						<Skeleton height="6.2rem" width="100%" />
					</SimpleGrid>
				) : (
					<SimpleGrid columns={3} spacingX="1.5rem" spacingY=".4rem">
						{MembersDetailsOne.map(
							({ title, icon, value }: IDashboardProps) => {
								return (
									<DashboardCard
										key={title}
										title={title}
										icon={icon}
										value={value}
									/>
								);
							}
						)}
					</SimpleGrid>
				)}
			</Box>

			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onChange={handleChange}
					value={searchTerm}
					text={permissionsAccess.createRole ? "CREATE ROLE" : ""}
					placeholder="Search..."
					w="48%"
					linkTo="/dashboard/team/roles/create"
				/>

				{rolesLoading ? (
					<PageLoader py="6rem" />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>

			{modal ? (
				<ActionModal
					title="Delete Role"
					confirmText="Are you sure you want to delete role"
					user={`${deleteValue?.name}`}
					onClick={() => deleteRole()}
					setModal={setModal}
					loading={deleteLoading}
				/>
			) : null}
		</Box>
	);
};

export default Rolepage;
