import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ClaimsImg from "@/assets/images/claims.webp";
import CircleCheckmark from "@/assets/images/circle-checkmark.webp";

const Claim = () => {
	return (
		<Box mt="6rem" maxW="1200px" px={["1rem", "2rem", "3rem"]} mx="auto">
			<Box
				bgImage={`url(${ClaimsImg})`}
				bgPosition="center"
				bgRepeat="no-repeat"
				h="500px"
			>
				<Box
					bg="brand.blue100"
					color="#fff"
					borderTopLeftRadius="20px"
					borderBottomLeftRadius="20px"
					mt="4.7rem"
					p="4rem 2rem"
					// w="550px"
					float="right"
				>
					<Text fontSize="1.8rem" fontWeight="700">
						Get paid your claims in minutes
					</Text>
					<Text fontSize=".95rem" fontWeight="300" maxW="470px" mt=".9rem">
						You can file a claim in the app or online for a quick and easy
						experience. We&apos;ll walk you through every step of the way.
					</Text>
					<Box w="80%" mt="1rem">
						<Flex fontWeight="300">
							<Image mr="1.5rem" src={CircleCheckmark} alt="Checkmark" />
							<Text fontSize=".95rem">
								Select the odometer mode of insurance for your vehicle.
							</Text>
						</Flex>
						<Flex fontWeight="300" py="1rem">
							<Image mr="1.5rem" src={CircleCheckmark} alt="Checkmark" />
							<Text fontSize=".95rem">
								Take a picture of your odometer every month.
							</Text>
						</Flex>
						<Flex fontWeight="300">
							<Image mr="1.5rem" src={CircleCheckmark} alt="Checkmark" />
							<Text fontSize=".95rem">
								Your insurance will be calculated based on distance covered.
							</Text>
						</Flex>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export { Claim };
