import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IChats, Messages } from "@/types/interfaces";

interface InitialStateProps {
	allChats: IChats[];
	unassignedChats: IChats[];
	chatsAssignedToMe: IChats[];
	selectedChat: IChats | null;
	messages: Messages[];
}

const initialState: InitialStateProps = {
	allChats: [],
	unassignedChats: [],
	chatsAssignedToMe: [],
	selectedChat: null,
	messages: [],
};

export const chatSlice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		setAllChats: (state, action: PayloadAction<IChats[]>) => {
			state.allChats = action.payload;
		},
		setUnassignedChats: (state, action: PayloadAction<IChats[]>) => {
			state.unassignedChats = action.payload;
		},
		setChatsAssignedToMe: (state, action: PayloadAction<IChats[]>) => {
			state.chatsAssignedToMe = action.payload;
		},
		setSelectedChat: (state, action: PayloadAction<IChats>) => {
			state.selectedChat = action.payload;
		},
		setMessages: (state, action: PayloadAction<Messages[]>) => {
			state.messages = action.payload;
		},
		updateMessages: (state, action: PayloadAction<Messages>) => {
			state.messages = [...state.messages, action.payload];
		},
	},
});

export const {
	setAllChats,
	setSelectedChat,
	setUnassignedChats,
	setChatsAssignedToMe,
	setMessages,
	updateMessages,
} = chatSlice.actions;

export default chatSlice.reducer;
