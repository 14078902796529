import axios from "axios";

const fileDownload = (url: string, filename: string) => {
	axios({
		url: `${process.env.REACT_APP_BASE_URL}${url}`, //your url
		method: "GET",
		headers: {
			Authorization: `Bearer ${localStorage.getItem("guliva-token")}`,
		},
		responseType: "blob", // important
	}).then((response) => {
		// create file link in browser's memory
		const href = URL.createObjectURL(response.data);

		// create "a" HTLM element with href to file & click
		const link = document.createElement("a");
		link.href = href;
		link.setAttribute("download", filename); //or any other extension
		document.body.appendChild(link);
		link.click();

		// clean up "a" element & remove ObjectURL
		document.body.removeChild(link);
		URL.revokeObjectURL(href);
	});
};

export default fileDownload;
