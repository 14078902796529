/* eslint-disable @typescript-eslint/no-unused-vars */
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { DashboardButton } from "@/components/button/DashboardButton";
import {
	useCreateRoleMutation,
	useGetAllPermissionsQuery,
	useGetRoleQuery,
	useGetUserQuery,
	useUpdateRoleMutation,
} from "@/services/requests/dashboard";
import { editRoleInputTypes } from "@/types/formInputTypes";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Checkbox,
	CheckboxGroup,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	HStack,
	Input,
	SimpleGrid,
	Text,
	useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";

const CreateRole = () => {
	const router = useNavigate();
	const toast = useToast();

	const {
		reset,
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<editRoleInputTypes>();

	const { data } = useGetAllPermissionsQuery();

	const { data: user } = useGetUserQuery();

	const [createRole, { isLoading: isCreating, isSuccess, isError, error }] =
		useCreateRoleMutation();

	function onSubmit(submitData: editRoleInputTypes) {
		const updatedPermission: number[] = [];

		data?.data?.map((permission) =>
			submitData.permissions.map((name: string) => {
				if (name === permission.name) {
					updatedPermission.push(permission.id);
				}
			})
		);

		createRole({
			permission: updatedPermission,
			role: submitData?.role,
			createdBy: user?.firstName + " " + user?.lastName,
		});
	}

	useEffect(() => {
		if (isSuccess) {
			toast({
				title: "Role created successfully",
				status: "success",
				duration: 9000,
				isClosable: true,
			});

			router("/dashboard/team/roles");
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			toast({
				title: "An error occurred",
				status: "error",
				duration: 9000,
				isClosable: true,
			});
		}
	}, [isError]);

	return (
		<Box as="form" onSubmit={handleSubmit(onSubmit)}>
			<Flex align="center" justify="space-between">
				<BreadcrumbLayout BreadcrumbLinkOne="Home" BreadcrumbLinkTwo="Roles" />

				<HStack>
					<Button
						onClick={() => router("/dashboard/team/roles")}
						bg="none"
						color="brand.gray100"
					>
						Cancel
					</Button>
					<DashboardButton
						type="submit"
						text={isCreating ? "Loading..." : "SAVE CHANGES"}
					/>
				</HStack>
			</Flex>

			<Box
				w="100%"
				p="2rem"
				bg="brand.white100"
				border="1px solid #E4E4E4"
				borderRadius="5px"
				mt="2rem"
			>
				<FormControl w="100%" maxW="400px" m="1rem auto">
					<FormLabel htmlFor="name">Role</FormLabel>
					<Input
						id="role"
						placeholder=""
						{...register("role", {
							required: "Please enter role",
							minLength: {
								value: 2,
								message: "Minimum length should be 2",
							},
						})}
					/>
					<FormErrorMessage>
						{errors?.role && errors?.role?.message}
					</FormErrorMessage>
				</FormControl>
				<Box
					w="100%"
					maxW="400px"
					m="1rem auto"
					bg="brand.white300"
					borderRadius="5px"
					border="1px solid #E4E4E4"
					p="1.5rem 1.25rem"
				>
					<Accordion allowToggle>
						<AccordionItem>
							<h2>
								<AccordionButton>
									<Box flex="1" textAlign="left">
										Permissions
									</Box>
									<AccordionIcon />
								</AccordionButton>
							</h2>
							<AccordionPanel pb={4}>
								<HStack my="0.75rem" spacing={4}>
									<Text fontSize="xs">PERMISSIONS</Text>
									<Button
										onClick={() =>
											reset({
												permissions: data?.data?.map(({ name }) => name),
											})
										}
										color="brand.blue200"
										bg="none"
										size="sm"
									>
										Select All
									</Button>
									<Button
										onClick={() => reset({ permissions: [] })}
										color="brand.blue200"
										bg="none"
										size="sm"
									>
										Deselect All
									</Button>
								</HStack>

								<Controller
									name="permissions"
									control={control}
									render={({ field: { ref: _ref, ...field } }) => (
										<CheckboxGroup {...field}>
											<SimpleGrid mt="2rem" columns={2} gap="1rem">
												{data?.data?.map((item, index) => (
													<Checkbox key={index} value={item?.name}>
														{item?.name}
													</Checkbox>
												))}
											</SimpleGrid>
										</CheckboxGroup>
									)}
								/>
							</AccordionPanel>
						</AccordionItem>
					</Accordion>
				</Box>
			</Box>
		</Box>
	);
};

export default CreateRole;
