import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface InitialStateProps {
	headerText: string;
}

const initialState: InitialStateProps = {
	headerText: "Dashboard",
};

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState,
	reducers: {
		setHeaderText: (state, action: PayloadAction<string>) => {
			state.headerText = action.payload;
		},
	},
});

export const { setHeaderText } = dashboardSlice.actions;

export default dashboardSlice.reducer;
