import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ArrowRight from "@/assets/images/arrow-right.webp";
import { GridProps } from "@/types/interfaces";

const GridLayout = ({ isNotDetails, title, image, mt, bg, ml }: GridProps) => {
	return (
		<Box
			w={
				!isNotDetails
					? ["100%", "100%", "310px", "430px", "510px"]
					: ["100%", "100%", "100%", "365px", "465px"]
			}
			mt={mt}
			bg={bg}
			borderRadius="20px"
			p={!isNotDetails ? "2rem 2rem 0" : "0"}
		>
			{!isNotDetails ? (
				<>
					<Box>
						<Text fontWeight="700" fontSize="1.7rem">
							{title}
						</Text>
						<Text
							fontWeight="400"
							fontSize=".95rem"
							color="brand.gray100"
							mr="2rem"
							p=".5rem 0 1rem"
						>
							We ensure you buy insurance only when you need it. Snap a picture
							of your
						</Text>
					</Box>

					<Flex
						color="brand.red100"
						w={["40%", "25%"]}
						justify="space-between"
						alignItems="center"
					>
						<Text fontWeight="500" fontSize=".85rem">
							GET STARTED
						</Text>
						<Image h="11px" src={ArrowRight} alt="Arrow Right" />
					</Flex>
				</>
			) : null}

			<Box>
				<Image ml={ml} mt="2rem" src={image} alt="Grid Image" />
			</Box>
		</Box>
	);
};

export { GridLayout };
