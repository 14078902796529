import { IModalProps } from "@/types/interfaces";
import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { RiCloseLine } from "react-icons/ri";

const ActionModal = ({
	setModal,
	title,
	confirmText,
	user,
	onClick,
	loading,
}: IModalProps) => {
	return (
		<Box
			bg="rgba(0, 0, 0, 0.2)"
			h="100vh"
			w="100%"
			zIndex="2"
			pos="absolute"
			top="0"
			left="0"
		>
			<Flex h="100vh" justify="center" align="center">
				<Box bg="brand.white100" w="585px" h="200px" p="1.8rem" pos="relative">
					<Flex flexDir="column" align="center">
						<Text color="brand.dark300" fontWeight="500" fontSize="1.4rem">
							{title}
						</Text>
						<Text color="brand.dark400" fontWeight="400" my="1rem">
							{confirmText} -{" "}
							<Text as="span" fontWeight="500">
								{user}
							</Text>
						</Text>
					</Flex>

					<Icon
						onClick={() => setModal(false)}
						cursor="pointer"
						pos="absolute"
						top="2rem"
						right="2rem"
						fontSize="1.8rem"
						as={RiCloseLine}
					/>

					<Flex justify="center">
						<Button
							bg="brand.red300"
							color="brand.white100"
							borderRadius="5px"
							fontSize=".8rem"
							fontWeight="500"
							p="1.45rem 3rem"
							onClick={onClick}
							isLoading={loading}
							_hover={{
								bg: "brand.red200",
							}}
							_focus={{
								border: "none",
								boxShadow: "none",
							}}
						>
							DELETE
						</Button>
						<Button
							onClick={() => setModal(false)}
							bg="transparent"
							color="brand.dark400"
							borderRadius="5px"
							fontSize=".8rem"
							fontWeight="500"
							ml="1rem"
							_hover={{
								bg: "transparent",
							}}
							_focus={{
								border: "none",
								boxShadow: "none",
							}}
							_active={{
								bg: "transparent",
							}}
						>
							CANCEL
						</Button>
					</Flex>
				</Box>
			</Flex>
		</Box>
	);
};

export { ActionModal };
