import { EarlyAccessBanner } from "@/components/comingSoon/EarlyAccessBanner";
import { Hero } from "@/components/comingSoon/Hero";
import { Payless } from "@/components/comingSoon/Payless";
import { MainFooter } from "@/layouts/home/MainFooter";
import { Box } from "@chakra-ui/react";

const ComingSoon = () => {
	return (
		<Box>
			<Hero />
			<Payless />
			<EarlyAccessBanner />
			<MainFooter />
		</Box>
	);
};

export default ComingSoon;
