import {
	Box,
	Flex,
	HStack,
	Image,
	Link as ChakraLink,
	Text,
} from "@chakra-ui/react";
import Appstore from "@/assets/images/guliva_appstore.webp";
import Playstore from "@/assets/images/guliva_playstore.webp";
import gulivaLogo from "@/assets/images/guliva-logo.webp";
import { Fragment, useState } from "react";
import { MobileNavbar } from "@/components/modal/MobileNavbar";
import { headerData } from "@/utils/Header";
import { Link } from "react-router-dom";

const MainHeader = () => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [active, setActive] = useState<boolean>(false);

	const handleToggle = () => {
		setOpenModal((toggle) => !toggle);
		setActive((prev) => !prev);
	};

	return (
		<Box
			py="1rem"
			bg="brand.white100"
			pos="sticky"
			top="0"
			w="100%"
			zIndex="1"
			boxShadow="0px 4px 4px rgba(5, 57, 114, 0.05)"
		>
			<HStack
				maxW="1200px"
				mx="auto"
				px={["1rem", "2rem", "3rem"]}
				justify="space-between"
			>
				<Box cursor="pointer">
					<Link to="/">
						<Image w="70%" src={gulivaLogo} alt="Guliva Logo" />
					</Link>
				</Box>

				<Flex
					onClick={handleToggle}
					display={["block", "block", "block", "none"]}
					cursor="pointer"
				>
					<Box
						as="span"
						w="2rem"
						h=".25rem"
						bg="brand.dark100"
						transition="all 0.3s ease-in-out"
						borderRadius="12rem"
						display="block"
						_odd={{
							transform: active ? "translateY(0.375rem) rotate(45deg)" : "",
						}}
					/>
					<Box
						as="span"
						w="1.2rem"
						h=".25rem"
						bg="brand.dark100"
						borderRadius="12rem"
						my={active ? "0" : ".5rem"}
						ml="1.25rem"
						opacity={active ? 0 : 1}
						display="block"
					/>
					<Box
						as="span"
						w="2rem"
						h=".25rem"
						bg="brand.dark100"
						transition="all 0.3s ease-in-out"
						borderRadius="12rem"
						display="block"
						_odd={{
							transform: active ? "translateY(-0.2125rem) rotate(-45deg)" : "",
						}}
					/>
				</Flex>

				<Flex
					display={["none", "none", "none", "flex", "flex"]}
					justify="space-between"
					alignItems="center"
					w="66%"
				>
					{headerData.map((item, idx) => {
						return (
							<Fragment key={idx}>
								<ChakraLink href={item.url || ""}>
									<Flex align="center" justify="left" columnGap="2rem">
										<Text
											textAlign="left"
											color="brand.gray100"
											_hover={{
												color: "brand.gold200",
											}}
										>
											{item.title}
										</Text>
									</Flex>
								</ChakraLink>
							</Fragment>
						);
					})}

					<Flex>
						<a href="##">
							<Image w="100px" src={Appstore} alt="Download on Appstore" />
						</a>

						<a href="##">
							<Image
								w="113px"
								ml=".5rem"
								src={Playstore}
								alt="Download on Playstore"
							/>
						</a>
					</Flex>
				</Flex>
			</HStack>

			{openModal ? <MobileNavbar /> : null}
		</Box>
	);
};

export { MainHeader };
