import { Flex, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardButton } from "../button/DashboardButton";

interface ErrorBoundaryProps {
	children: React.ReactNode;
}
interface ErrorBoundaryState {
	hasError: boolean;
}

export default class ErrorBoundary extends Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<Flex
					w="100%"
					h="100vh"
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					overflowX="hidden"
				>
					<Text
						lineHeight="1.2"
						fontSize="5xl"
						fontFamily="sans-serif"
						textAlign="center"
						mb={4}
					>
						Something went wrong.
					</Text>
					<Text
						maxW="450px"
						fontSize="1rem"
						textAlign="center"
						fontFamily="sans-serif"
						lineHeight="1.5"
						mb="2rem"
					>
						Seems your network is down or something went wrong with the server,
						please check your network and click the button below to retry.
					</Text>

					<GoBackButton />
				</Flex>
			);
		}
		return this.props.children;
	}
}

const GoBackButton = () => {
	const navigate = useNavigate();
	return (
		<DashboardButton
			text="Go Back"
			onClick={() => {
				navigate(-1);
				window.location.reload();
			}}
		/>
	);
};
