import { Box, Text } from "@chakra-ui/react";

type FormProps = {
	title?: string;
	description?: string;
	btnText?: string;
	children?: React.ReactNode;
};

const AuthCard = ({ children, title, description }: FormProps) => {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			p={["1.5rem 2rem", "1.5rem 4rem"]}
			h="80vh"
		>
			<Box
				bg="brand.white100"
				boxShadow="0px 2px 12px rgba(5, 57, 114, 0.1)"
				borderRadius="10px"
				w="600px"
				p={["2rem 2rem", "2rem 4rem", "3rem 6rem"]}
				mt="4rem"
			>
				<Box>
					<Box>
						<Text
							color="brand.blue200"
							fontSize="1.6rem"
							fontWeight="500"
							textAlign="center"
						>
							{title}
						</Text>
						<Text textAlign="center" color="brand.gray100">
							{description}
						</Text>
					</Box>

					<Box>{children}</Box>
				</Box>
			</Box>
		</Box>
	);
};

export { AuthCard };
