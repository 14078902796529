import { Box, Grid, GridItem, Image, Text } from "@chakra-ui/react";
import Flexible from "@/assets/images/flexible-img.webp";
import Mileage from "@/assets/images/mileage-costs.webp";
import Helpful from "@/assets/images/helpful-reminders.webp";

const Why = () => {
	const data = [
		{
			icon: Flexible,
			title: "Flexible coverage",
			desc: "We ensure you buy insurance only when you need it. Snap a picture of your",
		},
		{
			icon: Mileage,
			title: "Lower mileage costs",
			desc: "We ensure you buy insurance only when you need it. Snap a picture of your",
		},
		{
			icon: Helpful,
			title: "Helpful reminders",
			desc: "We ensure you buy insurance only when you need it. Snap a picture of your",
		},
	];

	return (
		<Box bg="brand.gray200" id="why">
			<Box
				mt="6rem"
				maxW="1200px"
				p={["4rem 1rem 6rem", "4rem 2rem 6rem", "4rem 3rem 6rem"]}
				mx="auto"
			>
				<Text fontWeight="700" fontSize="1.2rem" color="brand.gray100">
					WHY GULIVA?
				</Text>
				<Grid
					templateColumns={[
						"repeat(1, 1fr)",
						"repeat(1, 1fr)",
						"repeat(2, 1fr)",
						"repeat(3, 1fr)",
					]}
					gap="6rem"
					mt="3rem"
				>
					{data.map((item, idx) => (
						<GridItem w="100%" key={idx}>
							<Image h="80px" src={item?.icon} alt="Image" />
							<Text
								mt="1rem"
								fontWeight="700"
								fontSize={["1.5rem", "1.5rem", "1.5rem", "1.7rem", "1.7rem"]}
								color="brand.dark200"
							>
								{item?.title}
							</Text>
							<Text
								mt=".5rem"
								fontWeight="400"
								fontSize=".95rem"
								color="brand.gray100"
							>
								{item?.desc}
							</Text>
						</GridItem>
					))}
				</Grid>
			</Box>
		</Box>
	);
};

export { Why };
