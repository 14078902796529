import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import PhoneInsurance from "@/assets/images/phone-insurance.webp";

const TripInsurance = () => {
	return (
		<Stack
			direction={["column", "column", "column", "row", "row"]}
			maxW="1200px"
			mx="auto"
			px={["1rem", "2rem", "3rem"]}
			mt="20rem"
		>
			<Box w="45%" display={["none", "none", "none", "flex", "flex"]}>
				<Image h="580px" src={PhoneInsurance} alt="Phone Insurance" />
			</Box>

			<Box maxW={["100%", "410px", "410px", "410px", "410px"]} mt="1rem">
				<Text fontWeight="700" fontSize="2.3rem" color="brand.dark200">
					How does pay per trip insurance work?
				</Text>

				<Box>
					<Flex justify="space-between" mt="2rem">
						<Box
							mt=".3rem"
							mr="3.5rem"
							pos="relative"
							_after={{
								content: "''",
								display: "block",
								width: "1px",
								height: "4rem",
								background: "brand.red100",
								color: "brand.red100",
								borderRadius: "5px",
								pos: "absolute",
								top: "2.3rem",
								left: "0.6rem",
							}}
						>
							<Text color="brand.red100" fontSize="1rem" fontWeight="500">
								01
							</Text>
						</Box>
						<Box>
							<Text fontWeight="700" fontSize="1.3rem" color="brand.dark200">
								Start a trip
							</Text>
							<Box fontWeight="400" fontSize=".95rem" color="brand.gray100">
								Join Nigeria’s first mobile insurance platform where you can buy
								insurance for both yourself.
							</Box>
						</Box>
					</Flex>

					<Flex justify="space-between" mt="2rem">
						<Box
							mt=".3rem"
							mr="3.5rem"
							pos="relative"
							_after={{
								content: "''",
								display: "block",
								width: "1px",
								height: "4rem",
								background: "brand.red100",
								color: "brand.red100",
								borderRadius: "5px",
								pos: "absolute",
								top: "2.3rem",
								left: "0.6rem",
							}}
						>
							<Text color="brand.red100" fontSize="1rem" fontWeight="500">
								02
							</Text>
						</Box>
						<Box>
							<Text fontWeight="700" fontSize="1.3rem" color="brand.dark200">
								Insure your vehicle
							</Text>
							<Box fontWeight="400" fontSize=".95rem" color="brand.gray100">
								Join Nigeria’s first mobile insurance platform where you can buy
								insurance for both yourself.
							</Box>
						</Box>
					</Flex>

					<Flex justify="space-between" mt="2rem">
						<Box mt=".3rem" mr="3.5rem">
							<Text color="brand.red100" fontSize="1rem" fontWeight="500">
								03
							</Text>
						</Box>
						<Box>
							<Text fontWeight="700" fontSize="1.3rem" color="brand.dark200">
								End trip
							</Text>
							<Box fontWeight="400" fontSize=".95rem" color="brand.gray100">
								Join Nigeria’s first mobile insurance platform where you can buy
								insurance for both yourself.
							</Box>
						</Box>
					</Flex>
				</Box>
			</Box>
		</Stack>
	);
};

export { TripInsurance };
