import Status from "@/components/tag/Status";
import { TableComponent } from "@/layouts/table/TableLayout";
import { IUserDataProps } from "@/types/interfaces";
import { Box, Text } from "@chakra-ui/react";
import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom";

const Transactions = ({ data }: IUserDataProps) => {
	const columns = useMemo(
		() => [
			{
				Header: "Date",
				accessor: "date",
			},
			{
				Header: "Time",
				accessor: "time",
			},
			{
				Header: "Reference No",
				accessor: "referenceNo",
			},
			{
				Header: "Amount",
				accessor: "amount",
			},
			{
				Header: "Type",
				accessor: "type",
			},
			{
				Header: "Status",
				accessor: "status",
				Cell: ({ value }: { value: string }) => {
					return (
						<Box>
							<Status text={value === "abandoned" ? "Abandoned" : "Approved"} />
						</Box>
					);
				},
			},
			{
				Header: "",
				accessor: "action",
				Cell: () => {
					return (
						<Box>
							<Link to="##">
								<Text color="brand.red100" fontWeight="500">
									View details
								</Text>
							</Link>
						</Box>
					);
				},
			},
		],
		[]
	);

	const tableData = useMemo(
		() =>
			data &&
			data?.data?.transactions?.map((item: IUserDataProps) => {
				return {
					date: moment(item.createdAt).format("Do MMM YYYY"),
					time: moment(item.createdAt).format("LT"),
					referenceNo: item.tx_ref,
					amount: item.amount,
					type: item.name,
					status: item.status,
					action: item.id,
				};
			}),
		[data]
	);

	return (
		<Box>
			<TableComponent columns={columns} data={tableData} />
		</Box>
	);
};

export default Transactions;
