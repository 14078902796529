import { Hero } from "@/components/home/Hero";
import { Payless } from "@/components/home/Payless";
import { TripInsurance } from "@/components/home/TripInsurance";
import { Claim } from "@/components/home/Claim";
import { Why } from "@/components/home/Why";
import { MainLayout } from "@/layouts/home/MainLayout";
import { Faq } from "@/components/home/Faq";
import { DownloadBanner } from "@/components/home/DownloadBanner";

const HomePage = () => {
	return (
		<MainLayout>
			<Hero />
			<TripInsurance />
			<Payless />
			<Claim />
			<Why />
			<Faq />
			<DownloadBanner />
		</MainLayout>
	);
};

export default HomePage;
