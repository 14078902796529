import { Box, Stack, useToast } from "@chakra-ui/react";

import { AuthCard } from "@/components/card/AuthCard";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { AuthButton } from "@/components/button/AuthButton";
import { useResetDashboardPasswordMutation } from "@/services/requests/auth";
import { PasswordInput } from "@/components/input/PasswordInput";
import { resetDashboardPasswordFormInputs } from "@/constant/formInputs";
import { passwordDashboardInputTypes } from "@/types/formInputTypes";

const ResetDashboardPassword = () => {
	const toast = useToast();
	const navigate = useNavigate();
	const { profile } = useParams();

	const [resetPassword, { isSuccess, isLoading, isError, data }] =
		useResetDashboardPasswordMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<passwordDashboardInputTypes>();

	// onsubmit function
	const onSubmit: SubmitHandler<passwordDashboardInputTypes> = async (data) => {
		if (data.newPassword !== data.oldPassword) {
			await resetPassword({
				oldPassword: data.oldPassword,
				newPassword: data.newPassword,
			});
		} else {
			toast({
				title: "New Password and Old Password are the same",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	};

	// check if password changed successfully
	useEffect(() => {
		if (isSuccess) {
			toast({
				title: data.message,
				status: "success",
				isClosable: true,
				position: "top-right",
			});
			navigate(`/dashboard/team/members/${profile}`);
		}
	}, [isSuccess]);

	// check if there is an error
	useEffect(() => {
		if (isError) {
			toast({
				title: "Oops! Something went wrong, please try again",
				status: "error",
				isClosable: true,
				position: "top-right",
			});
		}
	}, [isError]);

	return (
		<Box>
			<Stack spacing="0" bg="brand.white300">
				<AuthCard
					title="Reset Password"
					description="Change your default password to a desired password"
					// btnText="RESEND"
				>
					<Box as="form" onSubmit={handleSubmit(onSubmit)}>
						{resetDashboardPasswordFormInputs.map(
							({ label, type, placeholder, name, rules }) => (
								<PasswordInput
									key={name}
									type={type}
									label={label}
									placeholder={placeholder}
									name={name}
									id={name}
									register={register}
									rules={rules}
									errors={errors}
								/>
							)
						)}
						<AuthButton text="RESET PASSWORD" loading={isLoading} />
					</Box>
				</AuthCard>
			</Stack>
		</Box>
	);
};

export default ResetDashboardPassword;
