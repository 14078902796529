import { Box, Flex, Image, Img, Stack, Text } from "@chakra-ui/react";
import Appstore from "@/assets/images/guliva_appstore.webp";
import Playstore from "@/assets/images/guliva_playstore.webp";
import Underline from "@/assets/images/underline.webp";
import PhoneDouble from "@/assets/images/phone-double.webp";
import ArrowDown from "@/assets/images/arrow-down.webp";
import NewIcon from "@/assets/images/new-icon.webp";

const Hero = () => {
	return (
		<Stack
			direction={["column", "column", "column", "row", "row"]}
			maxW="1200px"
			px={["1rem", "2rem", "3rem"]}
			mx="auto"
			pos="relative"
		>
			<Box
				mt={["3rem", "6rem"]}
				w={["100%", "100%", "100%", "45%", "45%"]}
				pos="relative"
			>
				<Box>
					<Text
						fontSize={["2.3rem", "2.8rem"]}
						fontWeight="700"
						color="brand.dark200"
					>
						Save up to ₦50k/annum with pay per trip insurance
					</Text>
					<Image
						w="247px"
						top={["100px", "114px", "50px", "114px", "114px"]}
						left={["-3px", "40px", "230px", "40px", "40px"]}
						pos="absolute"
						src={Underline}
						alt="Underline"
					/>
					<Text
						fontSize=".95rem"
						fontWeight="400"
						color="brand.gray100"
						m=".5rem 0 1.5rem"
					>
						Join Nigeria’s first mobile insurance platform where you can buy
						insurance for both yourself and your vehicle only when you need it.
					</Text>
				</Box>

				<Flex>
					<a href="##">
						<Image w="170px" src={Appstore} alt="Download on Appstore" />
					</a>

					<a href="##">
						<Image
							w="188px"
							ml=".6rem"
							src={Playstore}
							alt="Download on Playstore"
						/>
					</a>
				</Flex>

				<Img
					pos="absolute"
					right={["5rem", "14rem", "16rem", "14rem", "14rem"]}
					top={["30rem", "23.5rem", "21.5rem", "30.5rem", "23.5rem"]}
					src={ArrowDown}
					alt=""
				/>
			</Box>

			<Box display={["none", "none", "none", "flex", "flex"]}>
				<Img
					pos="absolute"
					top="2.8rem"
					right="16.2rem"
					h="70px"
					src={NewIcon}
					alt="New Icon"
				/>
				<Img
					pos="absolute"
					top={["4.5rem", "4.5rem", "6.5rem", "6.5rem", "4.5rem"]}
					h={["300px", "300px", "300px", "550px", "680px"]}
					src={PhoneDouble}
					alt="Phone One"
				/>
			</Box>
		</Stack>
	);
};

export { Hero };
