import { IAuthButtonProps } from "@/types/interfaces";
import { Box, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const DashboardButton = ({ text, onClick, type, linkTo }: IAuthButtonProps) => {
	return (
		<Box>
			{linkTo ? (
				<Link to={linkTo || ""}>
					<Button
						bg="brand.blue100"
						color="brand.white100"
						borderRadius="5px"
						fontSize=".8rem"
						fontWeight="400"
						p="1.45rem 3.5rem"
						onClick={onClick}
						type={type}
						_hover={{
							bg: "brand.blue200",
						}}
						_focus={{
							border: "none",
							boxShadow: "none",
						}}
					>
						{text}
					</Button>
				</Link>
			) : (
				<Button
					bg="brand.blue100"
					color="brand.white100"
					borderRadius="5px"
					fontSize=".8rem"
					fontWeight="400"
					p="1.45rem 3.5rem"
					onClick={onClick}
					type={type}
					_hover={{
						bg: "brand.blue200",
					}}
					_focus={{
						border: "none",
						boxShadow: "none",
					}}
				>
					{text}
				</Button>
			)}
		</Box>
	);
};

export { DashboardButton };
