import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
	Avatar,
	Box,
	Flex,
	SimpleGrid,
	Text,
	Wrap,
	WrapItem,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { MembersDetailsOne } from "@/utils/Dashboard";
import { IDashboardProps } from "@/types/interfaces";
import { DashboardCard } from "@/components/card/DashboardCard";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { TableComponent } from "@/layouts/table/TableLayout";
import { SearchInput } from "@/components/input/SearchInput";
import { Link, Outlet } from "react-router-dom";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useGetAllMembersQuery } from "@/services/requests/dashboard";
import PageLoader from "@/components/loader/PageLoader";
import useUserPermission from "@/hooks/useUserPermission";

const MemberPage = () => {
	const { data, isLoading } = useGetAllMembersQuery();
	const dispatch = useDispatch();

	const [searchTerm, setSearchTerm] = useState<string>("");

	const permissionsAccess = useUserPermission();

	useEffect(() => {
		dispatch(setHeaderText("Members"));
	}, []);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const filteredData = useMemo(() => {
		if (data) {
			const sortedData = data.filter(
				(item: any) =>
					item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
					item.lastName.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, data]);

	const columns = [
		{
			Header: "Name of Member",
			accessor: "memberName",
			Cell: ({ value, row }: any) => (
				<Flex>
					<Wrap mr=".5rem">
						<WrapItem>
							<Avatar size="xs" name={value} src={row.image} />
						</WrapItem>
					</Wrap>{" "}
					{value}
				</Flex>
			),
		},
		{
			Header: "Role",
			accessor: "role",
		},
		{
			Header: "Date Added",
			accessor: "date",
		},
		{
			Header: "",
			accessor: "action",
			Cell: ({ value }: { value: string }) => {
				return (
					<Box>
						<Link to={`/dashboard/team/members/${value}`}>
							<Text color="brand.red100" fontWeight="500">
								View Details
							</Text>
						</Link>
					</Box>
				);
			},
		},
	];

	const tableData = useMemo(
		() =>
			filteredData?.map((item) => ({
				memberName: item.firstName + " " + item.lastName,
				role: item?.role?.role || "N/A",
				date: moment(item.createdAt).format("Do MMM YYYY"),
				action: item.id,
			})),
		[filteredData]
	);

	return (
		<Box>
			<BreadcrumbLayout BreadcrumbLinkOne="Home" BreadcrumbLinkTwo="Members" />

			<Box mt="2rem">
				<SimpleGrid columns={3} spacingX="1.5rem" spacingY=".4rem">
					{MembersDetailsOne.map(({ title, icon, value }: IDashboardProps) => {
						return (
							<DashboardCard
								key={title}
								title={title}
								icon={icon}
								value={value}
							/>
						);
					})}
				</SimpleGrid>
			</Box>

			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onChange={handleChange}
					text={permissionsAccess?.createMember ? "CREATE MEMBER" : ""}
					placeholder="Search..."
					w="48%"
					linkTo="/dashboard/team/members/create"
				/>

				{isLoading ? (
					<PageLoader />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>

			<Outlet />
		</Box>
	);
};

export default MemberPage;
