import {
	useGetAllPermissionsQuery,
	useGetUserQuery,
} from "@/services/requests/dashboard";
import camelize from "@/utils/camelizeString";
import { useMemo } from "react";

function useUserPermission() {
	const { data: permissions } = useGetAllPermissionsQuery();
	const { data: user } = useGetUserQuery();

	const permissionsAccess: any = useMemo(() => {
		const userPermission = user?.permissions?.map(
			(permission: any) => permission.name
		);

		const newPermissions: any = {};

		if (permissions) {
			permissions?.data?.forEach((permission: any) => {
				if (userPermission?.includes(permission.name)) {
					newPermissions[camelize(permission.name)] = true;
				} else {
					newPermissions[camelize(permission.name)] = false;
				}
			});
		}

		return newPermissions;
	}, [permissions]);

	return permissionsAccess;
}

export default useUserPermission;
