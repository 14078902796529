/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
	useExpanded,
	useSortBy,
	useTable,
	usePagination,
	useRowSelect,
} from "react-table";
import {
	Box,
	Text,
	IconButton,
	Flex,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Button,
	HStack,
	chakra,
} from "@chakra-ui/react";
import {
	TriangleDownIcon,
	TriangleUpIcon,
	ArrowRightIcon,
	ArrowLeftIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronDownIcon,
} from "@chakra-ui/icons";
import { TableCheckbox } from "@/components/table/TableCheckbox";

const TableComponent = (props: any) => {
	const data = React.useMemo(() => props.data, [props.data]);
	const columns = React.useMemo(() => props.columns, [props.columns]);
	const Table = chakra.table;
	const TableHead = chakra.thead;
	const TableRow = chakra.tr;
	const TableH = chakra.th;
	const TableBody = chakra.tbody;
	const TableD = chakra.td;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		state: { pageIndex, pageSize },
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		// selectedFlatRows,
	} = useTable(
		{ columns, data, initialState: { pageIndex: 0 } },
		useSortBy,
		useExpanded,
		usePagination,
		useRowSelect,
		(hooks: any) => {
			hooks.visibleColumns.push((columns: any) => {
				return [
					{
						id: "selection",
						Header: ({ getToggleAllRowsSelectedProps }: any) => (
							<TableCheckbox {...getToggleAllRowsSelectedProps()} />
						),
						Cell: ({ row }: any) => (
							<TableCheckbox {...row.getToggleRowSelectedProps()} />
						),
					},
					...columns,
				];
			});
		}
	);
	return (
		<>
			<Box>
				<Table
					w="100%"
					borderBottom="1px solid"
					borderColor="brand.gray500"
					{...getTableProps()}
				>
					{!props.hideHeaders && (
						<TableHead bg="brand.white300" color="brand.gray100">
							{headerGroups.map((headerGroup: any, idx: any) => (
								<TableRow
									key={idx}
									borderBottomWidth={1}
									{...headerGroup.getHeaderGroupProps()}
								>
									{headerGroup.headers.map((column: any, idx: any) => (
										<TableH
											key={idx}
											p={4}
											textAlign="left"
											color="brand.gray100"
											fontWeight="500"
											{...column.getHeaderProps(column.getSortByToggleProps())}
										>
											{column.render("Header")}
											<span>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<TriangleDownIcon ml={3} />
													) : (
														<TriangleUpIcon ml={3} />
													)
												) : (
													""
												)}
											</span>
										</TableH>
									))}
								</TableRow>
							))}
						</TableHead>
					)}
					<TableBody {...getTableBodyProps()}>
						{page.map((row: any, idx: any) => {
							prepareRow(row);
							return (
								<TableRow
									key={idx}
									bg="brand.white100"
									color="brand.blue200"
									fontWeight="400"
									textAlign="center"
									borderBottom="1px solid"
									borderColor="brand.gray500"
									_hover={{ bg: "brand.white300" }}
									{...row.getRowProps()}
								>
									{row.cells.map((cell: any, idx: any) => (
										<TableD
											key={idx}
											textAlign="left"
											p={4}
											{...cell.getCellProps()}
										>
											{cell.render("Cell")}
										</TableD>
									))}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
				{/* {JSON.stringify(
					{
						selectedFlatRows: selectedFlatRows.map((row: any) => row.original),
					},
					null,
					2
				)} */}
				<Flex mt="2rem" justify="space-between" align="center" p={4}>
					<HStack spacing="24px">
						<Menu>
							<MenuButton
								bg="brand.white100"
								as={Button}
								rightIcon={<ChevronDownIcon />}
								size="sm"
								color="brand.blue200"
								fontSize=".9rem"
								fontWeight="400"
								border="1px solid"
								borderColor="brand.gray500"
								borderRadius="5px"
								transition="all 0.2s"
								_hover={{ bg: "brand.white300", outline: 0 }}
								_expanded={{ bg: "brand.white300", outline: 0 }}
								_focus={{ outline: 0 }}
								_active={{ outline: 0 }}
							>
								{pageSize}
							</MenuButton>
							<MenuList>
								{[10, 20, 30, 40, 50].map((newPageSize) => (
									<MenuItem
										onClick={() => {
											setPageSize(newPageSize);
										}}
										key={newPageSize}
										value={pageSize}
									>
										{newPageSize}
									</MenuItem>
								))}
							</MenuList>
						</Menu>
						<Text fontSize=".9rem" color="brand.blue100">
							Showing {pageIndex === 0 ? "1" : `${pageSize * pageIndex + 1}`} -{" "}
							{pageSize * pageIndex + pageSize > data.length
								? data.length
								: pageSize * pageIndex + pageSize}{" "}
							of {data.length}
						</Text>
					</HStack>
					<HStack spacing="10px">
						<IconButton
							_hover={{ border: "none", outline: "none" }}
							_active={{ border: "none", outline: "none" }}
							_focus={{
								border: "none",
								outline: "none",
							}}
							aria-label="icon"
							bg="brand.blue100"
							borderRadius="5px"
							onClick={() => gotoPage(0)}
							disabled={!canPreviousPage}
							size="xs"
							icon={<ArrowLeftIcon color="brand.white100" boxSize={3} />}
						/>
						<IconButton
							_hover={{ border: "none", outline: "none" }}
							_active={{ border: "none", outline: "none" }}
							_focus={{
								border: "none",
								outline: "none",
							}}
							aria-label="icon"
							bg="brand.blue100"
							borderRadius="5px"
							onClick={() => previousPage()}
							disabled={!canPreviousPage}
							size="xs"
							icon={<ChevronLeftIcon color="brand.white100" boxSize={5} />}
						/>
						<Button
							_hover={{ border: "none", outline: "none" }}
							_active={{ border: "none", outline: "none" }}
							_focus={{
								border: "none",
								outline: "none",
							}}
							bg="brand.blue100"
							color="brand.white100"
							borderRadius="5px"
							size="sm"
							fontSize="1rem"
						>
							{pageIndex + 1}{" "}
						</Button>
						<IconButton
							_hover={{ border: "none", outline: "none" }}
							_active={{ border: "none", outline: "none" }}
							_focus={{
								border: "none",
								outline: "none",
							}}
							aria-label="icon"
							bg="brand.blue100"
							borderRadius="5px"
							onClick={() => nextPage()}
							disabled={!canNextPage}
							size="xs"
							icon={<ChevronRightIcon color="brand.white100" boxSize={5} />}
						/>
						<IconButton
							_hover={{ border: "none", outline: "none" }}
							_active={{ border: "none", outline: "none" }}
							_focus={{
								border: "none",
								outline: "none",
							}}
							aria-label="icon"
							bg="brand.blue100"
							borderRadius="5px"
							disabled={!canNextPage}
							size="xs"
							onClick={() => gotoPage(pageCount - 1)}
							icon={<ArrowRightIcon color="brand.white100" boxSize={3} />}
						/>
					</HStack>
				</Flex>
			</Box>
		</>
	);
};

export { TableComponent };
