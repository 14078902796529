import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";

const RolesHomePage = () => {
	return (
		<Box>
			<Outlet />
		</Box>
	);
};

export default RolesHomePage;
