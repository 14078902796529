import { Box, SimpleGrid, Skeleton, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DashboardCard } from "@/components/card/DashboardCard";
import { BreadcrumbLayout } from "@/components/breadcrumb/Breadcrumb";
import { TableComponent } from "@/layouts/table/TableLayout";
import { SearchInput } from "@/components/input/SearchInput";
import { setHeaderText } from "@/redux/features/dashboard/dashboardSlice";
import { useGetAllActivityQuery } from "@/services/requests/dashboard";
import PersonIcon from "@/assets/icons/guliva_person.webp";
import SecurityIcon from "@/assets/icons/guliva_security.webp";
import RolesIcon from "@/assets/icons/guliva_roles.webp";
import { IAllPremiumDataProps, TableDataRow } from "@/types/interfaces";
import PageLoader from "@/components/loader/PageLoader";
import moment from "moment";

const ActivityPage = () => {
	const { data, isLoading } = useGetAllActivityQuery();
	const dispatch = useDispatch();

	const [searchTerm, setSearchTerm] = useState<string>("");

	useEffect(() => {
		dispatch(setHeaderText("Activity"));
	}, []);

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value);
	}

	const filteredData = useMemo(() => {
		if (data) {
			const sortedData = data?.data?.filter((item: any) =>
				item.activity.toLowerCase().includes(searchTerm.toLowerCase())
			);

			return sortedData;
		}

		return [];
	}, [searchTerm, data]);

	const columns = [
		{
			Header: "Date",
			accessor: "date",
		},
		{
			Header: "Time",
			accessor: "time",
		},
		{
			Header: "Activity",
			accessor: "activity",
		},
		{
			Header: "By",
			accessor: "by",
		},
		{
			Header: "",
			accessor: "action",
			Cell: () => {
				return (
					<Box>
						<Text color="brand.dark300" fontWeight="500">
							Super Admin
						</Text>
					</Box>
				);
			},
		},
	];

	const tableData: TableDataRow[] = useMemo(
		() =>
			filteredData
				?.map?.((item: IAllPremiumDataProps) => {
					return {
						date: moment(item.createdAt).format("Do MMM YYYY"),
						time: moment(item.createdAt).format("LT"),
						activity: item.activity,
						by: item.memberFirstName + " " + item.memberLastName,
						action: item.id,
					};
				})
				.sort((a: TableDataRow, b: TableDataRow) =>
					moment(b.date, "Do MMM YYYY").diff(moment(a.date, "Do MMM YYYY"))
				),
		[filteredData]
	);

	return (
		<Box>
			<BreadcrumbLayout
				BreadcrumbLinkOne="Home"
				BreadcrumbLinkTwo="Activity Log"
			/>

			{isLoading ? (
				<SimpleGrid
					margin="1rem 0 0 0"
					columns={3}
					spacingX="2rem"
					spacingY="1rem"
				>
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
					<Skeleton height="6.2rem" width="100%" />
				</SimpleGrid>
			) : (
				<SimpleGrid columns={3} spacingX="2rem" spacingY=".4rem">
					<DashboardCard
						title="TOTAL NO. OF MEMBERS"
						icon={PersonIcon}
						value={data?.totalMembers?.toString() || "0"}
					/>
					<DashboardCard
						title="NO. OF ROLES"
						icon={RolesIcon}
						value={data?.totalRoles?.toString() || "0"}
					/>
					<DashboardCard
						title="NO. OF PERMISSIONS"
						icon={SecurityIcon}
						value={data?.totalPermissions?.toString() || "0"}
					/>
				</SimpleGrid>
			)}

			<Box
				border="1px solid"
				borderColor="brand.gray500"
				bg="brand.white100"
				borderRadius="5px"
				mt="1.8rem"
			>
				<SearchInput
					onChange={handleChange}
					value={searchTerm}
					placeholder="Search..."
					w="48%"
				/>

				{isLoading ? (
					<PageLoader py="6rem" />
				) : (
					<TableComponent columns={columns} data={tableData} />
				)}
			</Box>
		</Box>
	);
};

export default ActivityPage;
