import * as React from "react";
import { createRoot } from "react-dom/client";
import { App } from "@/App";
import { Provider } from "react-redux";
import { store } from "./redux/store/configureStore";
import ErrorBoundary from "./components/error/ErrorBoundary";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/global";
import { BrowserRouter as Router } from "react-router-dom";

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<Router>
					<ErrorBoundary>
						<App />
					</ErrorBoundary>
				</Router>
			</ChakraProvider>
		</Provider>
	</React.StrictMode>
);
