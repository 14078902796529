import { Box } from "@chakra-ui/react";
import Routes from "@/routes/Routes";
import "@/styles/global.css";

export const App = () => {
	return (
		<Box>
			<Routes />
		</Box>
	);
};
