import { IModal, IUserDataProps } from "@/types/interfaces";
import {
	Box,
	Image,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	Text,
} from "@chakra-ui/react";

const OdometerModal = ({ data, isOpen, onClose }: IModal) => {
	return (
		<Modal size="xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Odometer reading</ModalHeader>
				<ModalCloseButton />

				{data?.data?.odometer.map((item: IUserDataProps) => {
					return (
						<ModalBody key={item.id}>
							<Image
								width="100%"
								height="auto"
								src={item.image}
								alt="odometer details"
							/>

							<SimpleGrid my={8} columns={2} spacingX={4} spacingY={4}>
								<>
									<Box>
										<Text color="brand.gray100">
											Total Distance Covered (km)
										</Text>
										<Text color="brand.dark100" fontSize="md"></Text>
									</Box>
									<Box>
										<Text color="brand.gray100">Amount Billed</Text>
										<Text color="brand.dark100" fontSize="md">
											{item.value}
										</Text>
									</Box>
								</>
							</SimpleGrid>
						</ModalBody>
					);
				})}
			</ModalContent>
		</Modal>
	);
};

export default OdometerModal;
