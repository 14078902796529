import { Box, Image, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Logo from "@/assets/images/guliva-logo.webp";

const AuthHeaderLayout = () => {
	return (
		<Box
			p="0"
			bg="brand.white100"
			pos="sticky"
			top="0"
			w="100%"
			zIndex="1"
			boxShadow="0px 2px 12px rgba(5, 57, 114, 0.06)"
		>
			<Stack
				maxW="1280px"
				mx="auto"
				direction="row"
				justify="space-between"
				align="center"
				p="1.2rem 4rem"
			>
				<Box m="0 auto">
					<Link to="/">
						<Image src={Logo} alt="Header Logo" />
					</Link>
				</Box>
			</Stack>
		</Box>
	);
};

export { AuthHeaderLayout };
